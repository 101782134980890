import React, { useState, useEffect } from 'react';
import { Button, Box, CircularProgress, Typography, Divider, Slider } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { LISTENING_SIDE } from '../../utils/patient.constants';
import { disconnectRicDevice, readBatteryLevel, readEqualizer, readMode, readNoiseReduction } from '../../store/actions/ricFittingActions';
import { useDispatch } from 'react-redux';
import { SNACK_BAR_VARIETNS } from '../../utils/constants';
import { callSnackBar } from '../../store/actions/snackbarAction';

const hexStringToUint8Array = (hexString) => {
  const bytes = hexString.trim().split(' ').map(byte => parseInt(byte, 16));
  return new Uint8Array(bytes);
};

const writeDataToCharacteristic = async (characteristic, data) => {
  try {
    const value = hexStringToUint8Array(data);
    console.log('Converted HEX data to Uint8Array:', value);

    if (characteristic.properties.writeWithoutResponse) {
      await characteristic.writeValueWithoutResponse(value);
      console.log('Data written to characteristic (without response):', data);
    } else if (characteristic.properties.write) {
      await characteristic.writeValue(value);
      console.log('Data written to characteristic (with response):', data);
    } else {
      console.error('Characteristic does not support write or write without response');
    }
  } catch (error) {
    console.error('Failed to write data to characteristic:', error);
  }
};

const RicConnectDevice = ({ side, onConnectWithDevice, Component, onLoadingChange, onEnableChange, onWriteFunctionEnabled, onDisconnect = () => {}, fitting, fetchingData }) => {
  const [leftVolume, setLeftVolume] = useState(40);
  const [rightVolume, setRightVolume] = useState(40);
  const [leftDeviceConnected, setLeftDeviceConnected] = useState(false);
  const [rightDeviceConnected, setRightDeviceConnected] = useState(false);
  const [leftDeviceCharacteristic, setLeftDeviceCharacteristic] = useState(null);
  const [rightDeviceCharacteristic, setRightDeviceCharacteristic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Checking Browser Support");
  const [deviceInfo, setDeviceInfo] = useState({ name: "", id: "" });
  const [deviceObj, setDeviceObj] = useState(null);
  const [leftMode, setLeftMode] = useState(null);
  const [rightMode, setRightMode] = useState(null);
  const [leftNoiseReductionEnabled, setLeftNoiseReductionEnabled] = useState(false);
  const [rightNoiseReductionEnabled, setRightNoiseReductionEnabled] = useState(false);
  const [leftFeedBackCancellationEnabled, setLeftFeedBackCancellationEnabled] = useState(false);
  const [rightFeedBackCancellationEnabled, setRightFeedBackCancellationEnabled] = useState(false);
  const [data, setData] = useState([]);
  const [connected, setConnected] = useState();
  const [enabled, setEnabled] = useState(false);
  const dispatch = useDispatch();

  const connectDevice = async () => {

    console.log("side", side)
    try {
      setLoadingMessage("Connecting Device...");
      setLoading(true);

      const serviceUuid = "0000fff0-0000-1000-8000-00805f9b34fb";
      const characteristicUuidReadNotify = "0000fff1-0000-1000-8000-00805f9b34fb";
      const characteristicUuidReadWrite = "0000fff2-0000-1000-8000-00805f9b34fb";
      
      console.log("Requesting Bluetooth device...");
      const device = await navigator.bluetooth
      .requestDevice({
        filters: [
          { manufacturerData: [{ companyIdentifier: parseInt("0x6C42") }] },
        ],
        optionalServices: [serviceUuid],
      })

      
        .catch((e) => {
          console.error("Failed to request device:", e);
          setLoadingMessage("Failed to connect: " + (e.message ?? "User cancelled the request"));
          setLoading(false);
          return null;
        });

      if (!device) {
        setLoadingMessage("No device selected.");
        setLoading(false);
        return;
      }
      if ((side === "Left" && !device.name.endsWith("L")) || (side === "Right" && !device.name.endsWith("R"))) {
        setLoadingMessage(`Please connect the correct device for the ${side} side.`)
        dispatch(
                    callSnackBar(
                      (`Please connect the ${side} side device `),
                      SNACK_BAR_VARIETNS.error
                    )
                  );;
        setLoading(false);
        return;
      }

      console.log('Device found:', device.name);
      const server = await device.gatt.connect();
      console.log('GATT server connected:', server);

      console.log('Getting service and characteristics...');
      const service = await server.getPrimaryService(serviceUuid);
      const characteristicReadNotify = await service.getCharacteristic(characteristicUuidReadNotify);
      const characteristicReadWrite = await service.getCharacteristic(characteristicUuidReadWrite);
      console.log('Connected to characteristics:', characteristicReadNotify, characteristicReadWrite);

      // Log characteristic properties
      console.log('Characteristic Read/Notify properties:', characteristicReadNotify.properties);
      console.log('Characteristic Read/Write properties:', characteristicReadWrite.properties);

      // Update the connected state
      if (side === "Left") {
        setLeftDeviceConnected(true);
        setLeftDeviceCharacteristic(characteristicReadWrite);
        setConnected(true);
      } else {
        setRightDeviceConnected(true);
        setRightDeviceCharacteristic(characteristicReadWrite);
        setConnected(true);
      }
      setLoadingMessage("Device connected successfully");
      setLoading(false);

    

      // Set device info and handle disconnection
      setDeviceInfo({
        name: device.name,
        id: device.id,
      });

      device.ongattserverdisconnected = () => {
        console.log('Device disconnected');
        if (side === "Left") {
          setLeftDeviceConnected(false);
          dispatch(disconnectRicDevice(LISTENING_SIDE.LEFT));
        } else {
          setRightDeviceConnected(false);
          dispatch(disconnectRicDevice(LISTENING_SIDE.RIGHT));
        }
        setLoadingMessage("Device disconnected");
      };
      setDeviceObj(device);
      
      onConnectWithDevice(data, deviceInfo, device, () => disconnect(side)); // Pass disconnect function

     

      // Subscribe to notifications for reading the mode, volume, noise reduction, and feedback cancellation
      // characteristicReadNotify.addEventListener('characteristicvaluechanged', (event) => {
      //   const value = event.target.value;
      //   const decodedValue = Array.from(new Uint8Array(value.buffer))
      //     .map(byte => byte.toString(16).padStart(2, '0'))
      //     .join(' ');
      //   console.log(`Received notification from ${side} device:`, decodedValue);

        // Set the mode based on the read data
        // if (decodedValue.startsWith('84 03 01 00') || decodedValue.startsWith('84 03 02 00')) {
        //   const mode = parseInt(decodedValue.slice(-1), 16);
        //   setMode(side, mode);
        // }

        // // Set the volume based on the read data
       

        // // Set the noise reduction based on the read data
        // if (decodedValue.startsWith('86 03 01') || decodedValue.startsWith('86 03 02')) {
        //   const noiseReductionEnabled = decodedValue.endsWith('03');
        //   if (side === "Left") {
        //     setLeftNoiseReductionEnabled(noiseReductionEnabled);
        //   } else {
        //     setRightNoiseReductionEnabled(noiseReductionEnabled);
        //   }
        // }

        // Set the feedback cancellation based on the read data
      //   if (decodedValue.startsWith('88 03 01') || decodedValue.startsWith('88 03 02')) {
      //     const feedbackCancellationEnabled = decodedValue.endsWith('03');
      //     if (side === "Left") {
      //       setLeftFeedBackCancellationEnabled(feedbackCancellationEnabled);
      //     } else {
      //       setRightFeedBackCancellationEnabled(feedbackCancellationEnabled);
      //     }
      //   }
      // });

      // await characteristicReadNotify.startNotifications();
      // console.log('Notifications started for characteristicReadNotify');

      // Send the code to request the current mode
      // let readModeData;
      // if (side === "Left") {
      //   readModeData = '84 02 01 00';
      // } else {
      //   readModeData = '84 02 02 00';
      // }
      // await writeDataToCharacteristic(characteristicReadWrite, readModeData);
      // console.log(`Sent code to request current mode: ${readModeData}`);


      // // Send the code to request the current noise reduction status
      // let readNoiseReductionData;
      // if (side === "Left") {
      //   readNoiseReductionData = '86 02 01 00';
      // } else {
      //   readNoiseReductionData = '86 02 02 00';
      // }
      // await writeDataToCharacteristic(characteristicReadWrite, readNoiseReductionData);
      // console.log(`Sent code to request current noise reduction status: ${readNoiseReductionData}`);

      // // Send the code to request the current feedback cancellation status
      // let readFeedbackCancellationData;
      // if (side === "Left") {
      //   readFeedbackCancellationData = '88 02 01 00';
      // } else {
      //   readFeedbackCancellationData = '88 02 02 00';
      // }
      // await writeDataToCharacteristic(characteristicReadWrite, readFeedbackCancellationData);
      // console.log(`Sent code to request current feedback cancellation status: ${readFeedbackCancellationData}`);




      // dispatch(readNoiseReduction(LISTENING_SIDE.RIGHT, deviceObj));
   
      // Set the full volume level
      let setFullVolumeData;
      if (side === "Left") {
        setFullVolumeData = '83 03 01 00 00';
      } else {
        setFullVolumeData = '83 03 02 00 00';
      }
      await writeDataToCharacteristic(characteristicReadWrite, setFullVolumeData);
      console.log(`Sent code to set full volume level: ${setFullVolumeData}`);

      let setModeData;
      if (side === "Left") {
        setModeData = '85 03 01 00 01';
      } else {
        setModeData = '85 03 02 00 01';
      }
      await writeDataToCharacteristic(characteristicReadWrite, setModeData);

      
      // let setNoiseReductionOn;
      // if (side === "Left") {
      //   setNoiseReductionOn = '87 03 01 00 01';
      // } else {
      //   setNoiseReductionOn = '87 03 02 00 01';
      // }
      // await writeDataToCharacteristic(characteristicReadWrite, setNoiseReductionOn);

      // console.log(`Sent code to set noise reduction on: ${setNoiseReductionOn}`);

    } catch (error) {
      console.error('Error:', error);
      setLoadingMessage("Failed to connect: " + error.message);
      setLoading(false);
      if (side === "Left") {
        setLeftDeviceConnected(false);
      } else {
        setRightDeviceConnected(false);
      }
    }
  };



// useEffect(() => {
//   if (deviceObj) {
//     dispatch(readNoiseReduction(LISTENING_SIDE.LEFT, deviceObj));
//     dispatch(readNoiseReduction(LISTENING_SIDE.RIGHT, deviceObj));
//   }
// }, [deviceObj]); // Only run when deviceObj changes


// ...existing code...
const disconnect = (side) => {
  console.log("disconnected");
  if (deviceObj?.gatt?.ongattserverdisconnected()) {
    console.log("hard disconnect");
    deviceObj.gatt.disconnect();
  } else {
    setLoadingMessage("already Disconnected..");
  }
  if (side === "Left") {
    setLeftDeviceConnected(false);
  } else {
    setRightDeviceConnected(false);
  }
  setConnected(false); // Ensure connected state is updated
  setDeviceInfo({});
  setDeviceObj(null);
  setLoadingMessage("");
  onDisconnect(); // Call the onDisconnect callback to update the parent component
};

  useEffect(() => {
    if (window.navigator && window.navigator.bluetooth) {
      setLoading(false);
      setLoadingMessage("");
      setEnabled(true);
    }
  }, []);

  useEffect(() => {
    // if (!loading)
    onEnableChange(enabled);
  }, [enabled]);


  const handleLeftVolumeChange = async (event, newValue) => {
    setLeftVolume(newValue);
    if (leftDeviceCharacteristic) {
      const volumeHex = newValue.toString(16).padStart(2, '0');
      const data = `83 03 01 ${volumeHex} 03`;
      await writeDataToCharacteristic(leftDeviceCharacteristic, data);
    }
  };

  const handleRightVolumeChange = async (event, newValue) => {
    setRightVolume(newValue);
    if (rightDeviceCharacteristic) {
      const volumeHex = newValue.toString(16).padStart(2, '0');
      const data = `83 03 02 ${volumeHex} 03`;
      await writeDataToCharacteristic(rightDeviceCharacteristic, data);
    }
  };
  useEffect(() => {
    if (connected && deviceInfo.id != "")
      onConnectWithDevice(data, deviceInfo,deviceObj ,() => disconnect(true));
  }, [connected]);

  useEffect(() => {
    if (fitting.device_side === side && fitting.disconnect) {
      disconnect(side);
    }
  }, [fitting.disconnect]);

  return (
      <Component
        loading={loading}
        connected={side === "Left" ? leftDeviceConnected : rightDeviceConnected}
        onClick={connectDevice}
        disconnect={() => disconnect(side)}
        deviceSide={fitting?.device_side}
        fetchingData={fetchingData}
      />
    );
};

export default RicConnectDevice ;