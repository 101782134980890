import {
  PermIdentityOutlined,
  ArrowDownward,
  DriveFileRenameOutlineOutlined,
  Earbuds,
  Edit,
  Fullscreen,
  FullscreenExit,
  Info,
  Maximize,
  Minimize,
  Person,
  QrCode,
  Share,
  Tune,
  TuneRounded,
  Visibility,
  WhatsApp,
  ManageAccounts,
  ManageAccountsOutlined,
} from "@mui/icons-material";
import NoDataComponent from "../../components/layouts/NoDataComponent";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";

import mcl_left from "../../assets/images/mcl_left.svg";
import mcl_right from "../../assets/images/mcl_right.svg";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { calculateAge, findObjectKeyByValue } from "../../utils/helper";
import { GENDER } from "../../utils/constants";
import {
  DEVICE_COLORS,
  DEVICES,
  DEVICES_NAME,
  DEVICES_TUBE,
  DEVICES_TUBE_NAME,
  DOM_TYPE,
  DOM_TYPE_NAME,
  IMPAIREMENT_TYPE,
  LEVEL_OF_HEARING_LOSS,
  LISTENING_SIDE,
  TYPE_OF_HEARING_LOSS,
  TYPE_OF_HEARING_LOSS_NAMES,
} from "../../utils/patient.constants";
import ModuleContainer from "../../components/layouts/common/ModuleContainer";
import AudioGram from "./AudioGram";
import HearingAidIcon from "../../assets/icons/HearingAidIcon";
import { Fragment, memo, useEffect, useState } from "react";
import moment from "moment";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import OpenAudioGramHistoryButton from "../audiogramhistory/OpenAudioGramHistoryButton";
import DowanloadReportButton from "./DowanloadReportButton";
import { useDispatch, useSelector } from "react-redux";
import DeletePatientButton from "./DeletePatientButton";
import { QrCodeButton, QrCodeModal } from "./PatientQrCode";
import { BreadCrumbAsTitle } from "../../components/layouts/common/BreadCrumbComponent";
import { FlexedColumnBox } from "../../components/layouts/common/boxes";
import SubmitButton from "../../components/button/SubmitButton";
import TuneIcon from "../../assets/icons/TuneIcon";
import { closeModal, openModal } from "../../store/actions/modalAction";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { Line } from "react-chartjs-2";
import { Scrollbars } from "react-custom-scrollbars";
import LEFTIMG from "../../assets/images/Left (1).png";
import RIGHTIMG from "../../assets/images/Left (2).png";
import METALIC_LEFT_RIC_OPTIMA from "../../assets/images/metalic_left_ric.svg";
import METALIC_RIGHT_RIC_OPTIMA from "../../assets/images/metalic_right_ric.svg";
import BLACK_LEFT_RIC_OPTIMA from "../../assets/images/black_left_ric.svg";
import BLACK_RIGHT_RIC_OPTIMA from "../../assets/images/black_right_ric.svg";
import BEIGE_LEFT_RIC_OPTIMA from "../../assets/images/beige_left_ric.svg";
import BEIGE_RIGHT_RIC_OPTIMA from "../../assets/images/beige_right_ric.svg";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LEFTARROWICON from "../../assets/images/leftIcon.svg";
import RIGHTARROWICON from "../../assets/images/rightarrow.svg";
import LetterAIcon from "../../assets/images/letter-a.svg";
import ucl_left from "../../assets/images/ucl_left.svg";
import ucl_right from "../../assets/images/ucl_right.svg";
import ReassignComponent from "./ReassignComponent";
import { getPatientByIdApi } from "../../apis/patient.api";
import connectIcon1 from "../../assets/images/connectIcon(1).svg";
import connectIcon2 from "../../assets/images/connectIcon(2).svg";
import connectIcon3 from "../../assets/images/connectIcon(3).svg";
import connectIcon4 from "../../assets/images/connectIcon(4).svg";
import { callApiAction } from "../../store/actions/commonAction";
import CoonectDeviceModule from "../../components/bluetooth/CoonectDeviceModule";
import {
  applyForRemoteFitting,
  changeDeviceCompatiblity,
  changeLoadinfMessages,
  connectDevice,
  disconnectDevice,
  onWriteFunctionChange,
} from "../../store/actions/fittingAction";
import RicConnectDevice from "../../components/bluetooth/RicConnectDeviceModule";
import {
  disconnectRicDevice,

} from "../../store/actions/ricFittingActions";
import { PatientInformationViewController } from "./PatientInformationViewController";

const CustomScrollBar = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1200));

  const scrollbarStyles = {
    container: {
      height: "100%",
      width: "100%",
      overflowY:
        !isMobile &&
        (children[0]?.props?.className || children[2]?.props?.className)
          ? "hidden"
          : "scroll",
    },
    scrollbar: {
      /* Webkit-based browsers */
      "::-webkit-scrollbar": {
        width: "10px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f0f0f0",
        borderRadius: "6px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "red",
        borderRadius: "6px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#red",
      },
      /* Firefox */
      scrollbarWidth: "thin",
      scrollbarColor: "red",
    },
  };
  return (
    <Box style={scrollbarStyles.container}>
      <Box style={scrollbarStyles.scrollbar}>{children}</Box>
    </Box>
  );
};

const OuterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  overflow: "hidden",
  borderTop: "1px solid #DDDDDD",
  background: theme.palette.light.main,
}));

const TitleBox = styled(Box)(({ theme }) => ({
  padding:
    theme.spacing(3) +
    " " +
    theme.spacing(5) +
    " " +
    theme.spacing(3) +
    " " +
    theme.spacing(7),
  borderBottom: "1px solid " + theme.palette.primary.main,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: theme.palette.primary.main,
  "*": {
    fontSize: theme.typography.h3.fontSize + " !important",
    color: theme.palette.primary.main,
  },
}));

const ContentContainer = styled(Box)(
  ({ theme, overflow = "auto", noPadding }) => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: noPadding ? 0 : theme.spacing(2) + " " + theme.spacing(3),
    width: "100%",
    height: "78%",
    overflowY: overflow,
    borderRadius: theme.shape.borderRadius,
  })
);

const DetailsBox = memo(({ title, data = [{ title: "", value: "" }] }) => {
  return (
    <Box>
      <Typography variant="h4" color="primary" mb={5} fontWeight={500}>
        {title}
      </Typography>
      {title == "Personal Details" ? (
        <Grid container spacing={2} flexDirection="row" ml={1}>
          {data.map((item) => (
            <Fragment key={item.title}>
              <Grid container xs={6}>
                <Grid xs={3}>
                  <Typography variant="h6" color="grey" fontWeight={500}>
                    {item.title}
                  </Typography>
                </Grid>
                {/* <Grid xs={1}>
                                :
                            </Grid> */}
                <Grid xs={3}>
                  <Typography variant="h6" color="dark" fontWeight={500}>
                    {item.value}
                  </Typography>
                </Grid>
                {/* <Typography variant="h6" color="primary" fontWeight={500}></Typography> */}
              </Grid>
              {/* <Grid  item xs={4} >

                    </Grid>
                    <Grid  item xs={4} >
                        <Typography variant="h6" color="primary" fontWeight={500}>{item.values}</Typography>

                    </Grid> */}
            </Fragment>
          ))}
        </Grid>
      ) : (
        <Grid container xs={12} spacing={2} ml={1}>
          {data.map((item) => (
            <Grid xs={4}>
              <Typography variant="h5" color="grey" fontWeight={500}>
                {item.title}{" "}
              </Typography>
              <Typography variant="h5" color="dark" fontWeight={500}>
                {item.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
});

const PatientDeviceSelectionComponent = memo(({}) => {});
const PatientSelectedDeviceComponent = memo(
  ({ selectedView, onViewSelectionCancel }) => {
    const theme = useTheme();
    return (
      <>
        <TitleBox sx={{ borderTop: 1 }}>
          <Typography variant="h3" fontWeight={600}>
            Selected Device
          </Typography>

          <Box sx={{ display: "flex" }}>
            <IconButton onClick={(e) => {}} sx={{ padding: 0 }}>
              <DriveFileRenameOutlineOutlined
                fontSize="inherit"
                color="primary"
              />
            </IconButton>
            {selectedView && (
              <>
                {selectedView == "device" ? (
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={onViewSelectionCancel}
                  >
                    <FullscreenExit fontSize="inherit" color="primary" />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={onViewSelectionCancel}
                  >
                    <Fullscreen fontSize="inherit" color="primary" />
                  </IconButton>
                )}
              </>
            )}
          </Box>
        </TitleBox>
        {
          <Collapse in={!selectedView || selectedView == "device"}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                position: "relative",
              }}
              p={6}
              pr={2}
            >
              <Box sx={{ display: "flex", flex: 1, maxWidth: "145px" }}>
                <Box
                  sx={{
                    width: "100%",
                    paddingTop: "126%",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      height: "100%",
                      width: "100%",
                      border: "1px solid red",
                    }}
                  ></Box>
                </Box>
              </Box>
              <Box sx={{ flex: 1.8 }} ml={5}>
                <Typography variant="h3" color="primary">
                  HearNU BTE Prime
                </Typography>
                <Box mt={3} sx={{ display: "flex", alignItems: "center" }}>
                  <Box mr={3} sx={{}}>
                    <Typography variant="h3" fontWeight={500} color="primary">
                      <TuneIcon />{" "}
                    </Typography>
                  </Box>
                  <Typography variant="h4" fontWeight={500} color="primary">
                    8 Channels
                  </Typography>
                </Box>
                <Box mt={3} sx={{ display: "flex", alignItems: "center" }}>
                  <Paper
                    component={Box}
                    variant="bordered"
                    sx={{
                      height: "67px",
                      width: "67px",
                      borderColor: theme.palette.primary.main,
                      border: 1,
                      background: theme.palette.secondary.light,
                    }}
                  ></Paper>
                  <CenteredBox
                    sx={{ height: "67px", width: "67px" }}
                    p={2}
                    pl={5}
                  >
                    <Typography variant="h3" fontWeight={500} color="primary">
                      Closed Dome
                    </Typography>
                  </CenteredBox>
                </Box>
              </Box>
            </Box>
          </Collapse>
        }
      </>
    );
  }
);

const HistoryUpdate = ({ historyData, data }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [second, setSecond] = useState(false);
  const [secondId, setSecondId] = useState(null);

  const reassign = () => {
    dispatch(
      openModal(<ReassignComponent id={data?._id} />, "sm", false, "reassign")
    );
  };

  const { fitting } = useSelector((state) => state);
  const { fittingLeft } = useSelector((state) => state);
  console.log("history data ",)

  return (
    <>
      <CustomDialog
        id={"updateHistory"}
        closeText="Close"
        title={"Select device for configuration"}
        // onEdit={() => {
        //   let route = "/patient-details/" + params.id + "/update";
        //   dispatch(closeModal("updateHistory"));
        //   navigate(route);
        // }}
      >
        {/* <FlexedColumnBox sx={{ overflowY: "auto" }} p={3} pt={4} pl={7}>
          <DetailsBox
          title="Personal Details" data=
          {[
            {
                title: "Patient Id",
                value: data?.patient_uid
            },
            {
              title: "Full Name",
              value:
                data?.first_name +
                " " +
                (data?.middle_name ?? "") +
                " " +
                data?.last_name,
            },
            {
              title: "Contact",
              value: data?.phone ?? "NA",
            },
            {
              title: "Email",
              value: data?.email ?? "NA",
            },
            {
              title: "Gender",
              value: findObjectKeyByValue(data?.gender, GENDER) ?? "NA",
            },
            {
              title: "Date of Birth",
              value: moment(data?.dob).format("DD / MM / YYYY") ?? "NA",
            },
            {
              title: "Age",
              value: calculateAge(data?.dob) ?? "0",
            },

            // {
            //     title: "Address",
            //     value: data?.address ?? "NA"
            // },
          ]}
          />
          <Grid container xs={12} pl={1}>
            <Grid xs={1.5}>
              <Typography variant="h6" color={"grey"} fontWeight={500}>
                Address{" "}
              </Typography>
            </Grid>
            <Grid xs={10.5}>
              <Typography variant="h6" color="dark" fontWeight={500}>
                {data.address}
              </Typography>
            </Grid>
          </Grid>
          <Box mt={4}>
            <DetailsBox
              title="Hearing  Details"
              data={[
                {
                  title: "Level Of Hearing Loss",
                  value: findObjectKeyByValue(
                    data?.level_of_loss,
                    LEVEL_OF_HEARING_LOSS
                  ),
                },
                {
                  title: "Type Of Hearing Loss",
                  value: TYPE_OF_HEARING_LOSS_NAMES[data.type_of_loss] ?? "NA",
                },
                {
                  title: "Impairment Type",
                  value:
                    findObjectKeyByValue(
                      data?.impairment_type,
                      IMPAIREMENT_TYPE
                    ) ?? "NA",
                },
              ]}
            />
          </Box>
          <Grid container columns={12}>
            <Grid mt={6} xs={4}>
              <Typography variant="h4" color="primary" fontWeight={500}>
                Application QR Code
              </Typography>
              <QrCodeModal id={data._id} />
            </Grid>

            <Grid mt={6} xs={4}>
              <Typography variant="h4" color="primary" fontWeight={500}>
                Created By{" "}
              </Typography>
              <Box>
                <Typography variant="h6" color="grey" mb={5} fontWeight={500}>
                  {[data.created_by ? data.created_by.name : "NA"]}
                </Typography>
              </Box>
            </Grid>
            <Grid mt={6} xs={4}>
              <Typography variant="h4" color="primary" fontWeight={500}>
                Audiologist{" "}
              </Typography>
              {data?.assigned?.name || (
                <IconButton
                  sx={{ marginTop: "-12px", color: "red" }}
                  onClick={() => {
                    reassign();
                  }}
                >
                  <ManageAccounts />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Grid container columns={12}>
            <Grid container mt={2} xs={6}>
              <QrCodeModal id={data._id} />
            </Grid>
          </Grid>
          <Divider />
          <Box mt={5}></Box>
          <DeletePatientButton
            id={data?._id}
            name={data?.first_name + " " + data?.last_name}
          />
        </FlexedColumnBox> */}
        {second && (
          <Box
            mt={3}
            mb={3}
            sx={{ border: "1px #E8E8E8 solid", borderRadius: "5px" }}
            width={"100%"}
            height={"50vh"}
          >
            <Grid
              p={1}
              container
              sx={{
                backgroundColor: "#E8E8E8",
              }}
            >
              <Grid item xs={4.5}>
                <Typography ml={3} variant="h6" sx={{ color: "black" }}>
                  Date and Time
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h6" sx={{ color: "black" }}>
                  Device Name
                </Typography>
              </Grid>
            </Grid>

            <ContentContainer sx={{ height: "90%" }} noPadding overflow="auto">
              {data?.profiles?.filter(
                (item) => item.device_side != historyData.side
              ).length != 0 ? (
                <FormControl sx={{ marginLeft: "5px" }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    {data?.profiles
                      ?.filter((item) => item.device_side != historyData.side)
                      .map((item, index) => (
                        <FormControlLabel
                          onChange={(e) => {
                            setSecondId(e.target.value);
                          }}
                          sx={{ borderBottom: "1px solid #E8E8E8" }}
                          value={item._id}
                          control={<Radio />}
                          label={
                            <Box width={"30vw"}>
                              <HistoryBox
                                isUpdate={true}
                                key={item._id}
                                patientData={data}
                                even={index % 2 === 0}
                                {...item}
                              />
                            </Box>
                          }
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              ) : (
                <NoDataComponent message={"No Profile Found"} Icon={Earbuds} />
              )}
              {/* <HistoryBox /> */}
            </ContentContainer>
          </Box>
        )}

        {!second && (
          <Box
            sx={{
              display: "flex",
              height: "50vh",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              color={"black"}
              variant="h6"
              fontSize={"30px"}
              gutterBottom
            >
              Do you also want to edit the configuration of the
            </Typography>
            <Box display={"flex"} flexDirection={"row"} gap={"3px"}>
              <Typography
                color={
                  historyData.side != LISTENING_SIDE.LEFT
                    ? "#1265A1"
                    : "#C24747"
                }
                variant="h6"
                fontSize={"30px"}
              >
                {historyData.side == LISTENING_SIDE.LEFT ? "RIGHT " : "LEFT "}
              </Typography>
              <Typography color={"black"} variant="h6" fontSize={"30px"}>
                hearing Aid ?
              </Typography>
            </Box>
          </Box>
        )}
        <Box mt={3}>
        <Button
 onClick={(e) => {
  e.preventDefault();
  navigate(
    `${params.id}/${historyData._id}/${
      historyData.device_type === DEVICES.RIC_OPTIMA
        ? "ric-fitting"
        : "fitting"
    }`
  );
  dispatch(closeModal("updateHistory"));
}}
disabled={
  (fittingLeft.connected && fittingLeft.device_type === DEVICES.RIC_OPTIMA) &&
  (fitting.connected && fitting.device_type === DEVICES.RIC_OPTIMA)
}
  sx={{ width: "48%", marginRight: "2%" }}
  variant="outlined"
>
  No
</Button>

          <Button
            disabled={second && !secondId}
            onClick={(e) => {
              if (second) {
                e.preventDefault();
                navigate(
                  `${params.id}/${historyData._id}/${secondId}/${
                    historyData.device_type === DEVICES.RIC_OPTIMA
                      ? "ric-fitting"
                      : "fitting"
                  }`
                );
                dispatch(closeModal("updateHistory"));
              } else {
                setSecond(true);
              }
            }}
            sx={{ width: "48%", marginLeft: "2%" }}
            variant="contained"
          >
            {second ? "Configure" : "YES"}
          </Button>
        </Box>
      </CustomDialog>
    </>
  );
};

const PatientInformationView = ({
  loading,
  data,
  onNewFitting,
  title,
  setData,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const reassign = () => {
    dispatch(
      openModal(<ReassignComponent id={data?._id} />, "sm", false, "reassign")
    );
  };

  const { fitting } = useSelector((state) => state);
  const { fittingLeft } = useSelector((state) => state);

  return (
    <>
      <CustomDialog
        id={"information"}
        closeText="Close"
        title={"Patient Information"}
        onEdit={() => {
          let route = "/patient-details/" + params.id + "/update";
          dispatch(closeModal("information"));
          navigate(route);
        }}
      >
        {/* <TitleBox>
                <Typography variant="h3" fontWeight={600} >
                    Information
                </Typography>
                <Box>
                    <IconButton onClick={onEdit} sx={{ padding: 0, ml: 2 }} >
                        <DriveFileRenameOutlineOutlined fontSize="inherit" color="primary" />
                    </IconButton>
                </Box>
            </TitleBox> */}

        <FlexedColumnBox sx={{ overflowY: "auto" }} p={3} pt={4} pl={7}>
          <DetailsBox
            title="Personal Details"
            data={[
              // {
              //     title: "Patient Id",
              //     value: data?.patient_uid
              // },
              {
                title: "Full Name",
                value:
                  data?.first_name +
                  " " +
                  (data?.middle_name ?? "") +
                  " " +
                  data?.last_name,
              },
              {
                title: "Contact",
                value: data?.phone ?? "NA",
              },
              {
                title: "Email",
                value: data?.email ?? "NA",
              },
              {
                title: "Gender",
                value: findObjectKeyByValue(data?.gender, GENDER) ?? "NA",
              },
              {
                title: "Date of Birth",
                value: moment(data?.dob).format("DD / MM / YYYY") ?? "NA",
              },
              {
                title: "Age",
                value: calculateAge(data?.dob) ?? "0",
              },

              // {
              //     title: "Address",
              //     value: data?.address ?? "NA"
              // },
            ]}
          />

          <Grid container xs={12} pl={1}>
            <Grid xs={1.5}>
              <Typography variant="h6" color={"grey"} fontWeight={500}>
                Address{" "}
              </Typography>
            </Grid>
            <Grid xs={10.5}>
              <Typography variant="h6" color="dark" fontWeight={500}>
                {data.address}
              </Typography>
            </Grid>
          </Grid>
          <Box mt={4}>
            <DetailsBox
              title="Hearing  Details"
              data={[
                {
                  title: "Level Of Hearing Loss",
                  value: findObjectKeyByValue(
                    data?.level_of_loss,
                    LEVEL_OF_HEARING_LOSS
                  ),
                },
                {
                  title: "Type Of Hearing Loss",
                  value: TYPE_OF_HEARING_LOSS_NAMES[data.type_of_loss] ?? "NA",
                },
                {
                  title: "Impairment Type",
                  value:
                    findObjectKeyByValue(
                      data?.impairment_type,
                      IMPAIREMENT_TYPE
                    ) ?? "NA",
                },
              ]}
            />
          </Box>
          <Grid container columns={12}>
            <Grid mt={6} xs={4}>
              <Typography variant="h4" color="primary" fontWeight={500}>
                Application QR Code
              </Typography>
              <QrCodeModal id={data._id} />
            </Grid>

            <Grid mt={6} xs={4}>
              <Typography variant="h4" color="primary" fontWeight={500}>
                Created By{" "}
              </Typography>
              <Box>
                <Typography variant="h6" color="grey" mb={5} fontWeight={500}>
                  {[data.created_by ? data.created_by.name : "NA"]}
                </Typography>
              </Box>
            </Grid>
            <Grid mt={6} xs={4}>
              <Typography variant="h4" color="primary" fontWeight={500}>
                Audiologist{" "}
              </Typography>
              {data?.assigned?.name || (
                <IconButton
                  sx={{ marginTop: "-12px", color: "red" }}
                  onClick={() => {
                    reassign();
                  }}
                >
                  <ManageAccounts />
                </IconButton>
              )}
            </Grid>
          </Grid>

          <Grid container columns={12}>
            <Grid container mt={2} xs={6}>
              {/* <QrCodeModal id={data._id} /> */}
            </Grid>
          </Grid>

          <Divider />
          <Box mt={5}></Box>
          <DeletePatientButton
            id={data?._id}
            name={data?.first_name + " " + data?.last_name}
          />
        </FlexedColumnBox>
      </CustomDialog>
    </>
  );
};

const ChartComponent = ({ rightEar, dataB, dataA, dataC, dataD }) => {
  const boneImg = new Image();
  if (rightEar) {
    boneImg.src = RIGHTARROWICON;
  } else {
    boneImg.src = LEFTARROWICON;
  }
  boneImg.height = 8;
  boneImg.width = 4;
  const aidedImg = new Image();
  aidedImg.src = LetterAIcon;

  const UclImg = new Image();
  if (rightEar) {
    UclImg.src = ucl_right;
  } else {
    UclImg.src = ucl_left;
  }
  UclImg.height = 16;
  UclImg.width = 16;

  const MclImg = new Image();
  if (rightEar) {
    MclImg.src = mcl_right;
  } else {
    MclImg.src = mcl_left;
  }
  MclImg.height = 16;
  MclImg.width = 16;

  const data = {
    labels: ["", "250", "500", "1k", "2k", "4k", "8k"],
    datasets: [
      {
        label: "Fitting Range AC",
        data: dataA?.map((item) => item),
        borderColor: "#C24747",
        pointBackgroundColor: "white",
        pointStyle: rightEar ? "circle" : "crossRot",
        hoverBorderColor: rightEar ? "#E62323" : "#1265A1",
        borderColor: rightEar ? "#E62323" : "#1265A1",
        radius: rightEar ? 6 : 7,
      },
      {
        label: "Fitting Target BC",
        data: dataB?.map((item) => item),
        pointBackgroundColor: "white",
        pointStyle: boneImg,
        hoverBorderColor: rightEar ? "#E62323" : "#1265A1",
        borderColor: rightEar ? "#E62323" : "#1265A1",
        radius: rightEar ? 4 : 7,
        hoverRadius: rightEar ? 4 : 7,
        borderWidth: rightEar ? 1 : 1,
        hoverBorderWidth: rightEar ? 1 : 1,
      },
      {
        label: "Fitting Target UCL",
        data: dataC?.map((item) => item),
        pointBackgroundColor: "white",
        pointStyle: UclImg,
        hoverBorderColor: rightEar ? "#E62323" : "#1265A1",
        borderColor: rightEar ? "#E62323" : "#1265A1",
        radius: rightEar ? 4 : 7,
        hoverRadius: rightEar ? 4 : 7,
        borderWidth: rightEar ? 1 : 1,
        hoverBorderWidth: rightEar ? 1 : 1,
      },
      {
        label: "Fitting Target MCL",
        data: dataD?.map((item) => item),
        pointBackgroundColor: "white",
        pointStyle: MclImg,
        hoverBorderColor: rightEar ? "#E62323" : "#1265A1",
        borderColor: rightEar ? "#E62323" : "#1265A1",
        radius: rightEar ? 4 : 7,
        hoverRadius: rightEar ? 4 : 7,
        borderWidth: rightEar ? 1 : 1,
        hoverBorderWidth: rightEar ? 1 : 1,
      },
      {
        label: "Grey Area Upper Bound",
        data: [70, 70, 70, 75, 85, 85, 85, 85],
        borderColor: "rgba(0, 0, 0, 0)",
        backgroundColor: "rgba(200, 200, 200, 0.5)",
        pointRadius: 0,
        fill: "+1",
      },
      {
        label: "Grey Area Lower Bound",
        data: [10, 10, 10, 20, 30, 30, 30, 30],
        borderColor: "rgba(0, 0, 0, 0)",
        backgroundColor: "rgba(200, 200, 200, 0)",
        pointRadius: 0,
        fill: "origin",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 10,
            color: "#666666", // Font size for x-axis labels
          },
          // Padding to move the labels outside the box
        },
      },
      y: {
        min: -10,
        max: 120,
        reverse: true,
        ticks: {
          callback: function (value) {
            return value;
          },
          stepSize: 10,
          autoSkip: false,
          font: {
            size: 8,
          },
        },
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    elements: {
      line: {
        borderWidth: 1,
        // tension: 0.4,
      },
    },
  };

  return (
    <Box sx={{ minHeight: "100%", maxWidth: "100%" }}>
      <Line data={data} options={options} />
    </Box>
  );
};

const HistoryBox = ({ even, patientData, isUpdate, ...data }) => {
  const { user } = useSelector((state) => state);
  const { fitting, fittingLeft } = useSelector((state) => state);

  const dispatch = useDispatch();
  const message = `Please Open Wehear App,

And Add following details in remote fitting section:
Patient Id: ${patientData?.patient_uid}
Profile Id: ${data?._id}

Message By,
${user.data.name}
    `;

  return (
    <Box
      sx={{
        flexDirection: "column",
        justifyContent: "flex-start",
        textAlign: "start",
        alignItems: "flex-start",
        alignContent: "flex-start",
        borderBottomColor: "divider",
        background: isUpdate ? "light" : even ? "light" : "#F5F5F5",
      }}
    >
      <Box pt={2} pl={3} pr={3}>
        <Grid container xs={12} spacing={0}>
          <Grid item xs={4}>
            <Stack direction={"row"} spacing={2}>
              <Typography variant="h6" fontWeight={500} color={"#4D4D4D"}>
                {moment(data.updatedAt).format("DD/MM/YYYY")}
              </Typography>
              <Typography fontWeight={400} color={"#4D4D4D"} variant="h6">
                {moment(data.updatedAt).format("HH:mm")}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction={"row"} spacing={2}>
              <Typography
                variant="h6"
                mb={1}
                fontWeight={500}
                color={"#4D4D4D"}
              >
                {DEVICES_NAME[data.device_type]}
              </Typography>
              <Typography variant="h6" fontWeight={500} color={"#808080"}>
                {data.device_name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            {!isUpdate && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  borderRadius: "100px",
                }}
                mt={2}
                mb={1}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  p={2}
                  pt={0}
                  pb={0}
                >
                  <IconButton
                    // disabled={
                    //   data.device_side == LISTENING_SIDE.LEFT
                    //     ? fittingLeft.connected
                    //       ? false
                    //       : true
                    //     : fitting.connected
                    //     ? false
                    //     : true
                    // }

                    onClick={() => {
                      dispatch(
                        openModal(
                          <HistoryUpdate
                            historyData={{
                              _id: data._id,
                              side: data.device_side,
                              device_type: data.device_type,
                            }}
                            data={patientData}
                          />,
                          "sm",
                          false,
                          "updateHistory"
                        )
                      );
                    }}
                    // sx={{ padding: 0 }}
                    // LinkComponent={Link}
                    // to={data._id + "/fitting"}
                  >
                    <Edit sx={{ color: "#2D3B67" }} color="info" />
                  </IconButton>
                  <IconButton
                    sx={{ padding: 0, ml: 2 }}
                    LinkComponent={Link}
                    to={`${data._id}${
                      data.device_type === DEVICES.RIC_OPTIMA
                        ? '/ric-fitting'
                        : '/fitting'
                    }/view`}
                  >
                    <Visibility sx={{ color: "#2D3B67" }} color="info" />
                  </IconButton>

                  {/* https://wa.me/+91${patientData?.phone}?text=${encodeURI(message)} */}
                  {/* <IconButton sx={{ padding: 0, ml: 2 }} LinkComponent={'a'} target="_blank" href={https://web.whatsapp.com/send?phone=${patientData?.country_code??"+91"}${patientData?.phone}&text=${encodeURI(message)}} >
                        <WhatsApp color="success" />
                    </IconButton> */}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h6" fontWeight={500} color={"#4D4D4D"}>
            Remarks:
          </Typography>
          <Typography
            ml={1}
            sx={{ display: "flex", flex: 1 }}
            variant="h6"
            fontWeight={500}
            color={"#808080"}
          >
            {data.remarks && data.remarks != "" ? data.remarks : "NA"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const PatientAudioGramView = ({ data, onEdit, fetchingData }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { fitting } = useSelector((state) => state);
  const { fittingLeft } = useSelector((state) => state);
  const { ricRightFitting } = useSelector((state) => state);
  const { ricLeftFitting } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [leftImageLoaded, setLeftImageLoaded] = useState(false);
  const [rightImageLoaded, setRightImageLoaded] = useState(false);
  const LEFT_EAR_IMAGES = {
    [DEVICE_COLORS.METALIC]: METALIC_LEFT_RIC_OPTIMA,
    [DEVICE_COLORS.BLACK]: BLACK_LEFT_RIC_OPTIMA,
    [DEVICE_COLORS.BEIGE]: BEIGE_LEFT_RIC_OPTIMA,
  };

  const RIGHT_EAR_IMAGES = {
    [DEVICE_COLORS.METALIC]: METALIC_RIGHT_RIC_OPTIMA,
    [DEVICE_COLORS.BLACK]: BLACK_RIGHT_RIC_OPTIMA,
    [DEVICE_COLORS.BEIGE]: BEIGE_RIGHT_RIC_OPTIMA,
  };

  const getLeftEarImage = () =>
    fittingLeft.device_type === DEVICES.RIC_OPTIMA
      ? LEFT_EAR_IMAGES[
          data?.left_fitted_device?.device_color || DEVICE_COLORS.METALIC
        ]
      : LEFTIMG;

  const getRightEarImage = () =>
    fitting.device_type === DEVICES.RIC_OPTIMA
      ? RIGHT_EAR_IMAGES[
          data?.right_fitted_device?.device_color || DEVICE_COLORS.METALIC
        ]
      : RIGHTIMG;

  // const ConnectButton = ({
  //   loading,
  //   connected,
  //   onClick,
  //   disconnect,
  //   deviceSide,
  //   fetchingData,
  // }) => {
  //   const isRicOptima =
  //     deviceSide === LISTENING_SIDE.LEFT
  //       ? fittingLeft.device_type === DEVICES.RIC_OPTIMA
  //       : fitting.device_type === DEVICES.RIC_OPTIMA;

  //   if (!connected) {
  //     return (
  //       <Button
  //         onClick={onClick}
  //         loading={loading}
  //         disabled={isRicOptima && fetchingData}
  //         sx={{
  //           height: "60px",
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           zIndex: 1,
  //           border: "2px solid",
  //           borderColor: "#DDDDDD",
  //           borderRadius: "8px",
  //           width: "80%",
  //           marginLeft: deviceSide == LISTENING_SIDE.LEFT ? "20%" : "",

  //           marginTop: "5px",
  //         }}
  //       >
  //         {!loading ? (
  //           <>
  //             <img src={connectIcon1} alt="ConnectIcon(1)" />
  //             <Typography
  //               variant="h5"
  //               sx={{
  //                 fontFamily: "League spartan",
  //                 padding: "5px",
  //               }}
  //             >
  //               CONNECT
  //             </Typography>
  //           </>
  //         ) : (
  //           <CircularProgress size={25} />
  //         )}
  //       </Button>
  //     );
  //   } else {
  //     return (
  //       <Button
  //         onClick={() => {
  //           if (isRicOptima) {
  //             dispatch(disconnectRicDevice(deviceSide));
  //           } else {
  //             dispatch(disconnectDevice(deviceSide));
  //           }
  //         }}
  //         loading={loading}
  //         sx={{
  //           height: "60px",
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           border: "2px solid",
  //           borderColor:
  //             deviceSide == LISTENING_SIDE.LEFT ? "#2D3B67" : "#C24747",
  //           borderRadius: "8px",
  //           width: "80%",
  //           marginLeft: deviceSide == LISTENING_SIDE.LEFT ? "20%" : "",
  //           backgroundColor:
  //             deviceSide == LISTENING_SIDE.LEFT ? "#EDF0F7" : "#FCF7F7",
  //           marginTop: "5px",
  //         }}
  //       >
  //         {" "}
  //         <>
  //           {" "}
  //           <img src={connectIcon4} alt="ConnectIcon(3)" />
  //           <Typography
  //             variant="h5"
  //             sx={{
  //               fontFamily: "League spartan",
  //               padding: "5px",
  //               color: "#2D3B67",
  //             }}
  //           >
  //             CONNECTED
  //           </Typography>
  //         </>
  //       </Button>
  //     );
  //   }
  // };
  const ConnectButton = ({
    loading,
    connected,
    onClick,
    disconnect,
    deviceSide,
    fetchingData,
  }) => {
    const { fitting, fittingLeft } = useSelector((state) => state);
  
    const isConnected =
      deviceSide === LISTENING_SIDE.LEFT
        ? fittingLeft.connected
        : fitting.connected;
  
    const isRicOptima =
      deviceSide === LISTENING_SIDE.LEFT
        ? fittingLeft.device_type === DEVICES.RIC_OPTIMA
        : fitting.device_type === DEVICES.RIC_OPTIMA;
  
    if (!isConnected) {
      return (
        <Button
          onClick={onClick}
          loading={loading}
          disabled={isRicOptima && fetchingData}
          sx={{
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
            border: "2px solid",
            borderColor: "#DDDDDD",
            borderRadius: "8px",
            width: "80%",
            marginLeft: deviceSide == LISTENING_SIDE.LEFT ? "20%" : "",
            marginTop: "5px",
          }}
        >
          {!loading ? (
            <>
              <img src={connectIcon1} alt="ConnectIcon(1)" />
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "League spartan",
                  padding: "5px",
                }}
              >
                CONNECT
              </Typography>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            if (isRicOptima) {
              dispatch(disconnectRicDevice(deviceSide));
            } else {
              dispatch(disconnectDevice(deviceSide));
            }
          }}
          loading={loading}
          sx={{
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "2px solid",
            borderColor:
              deviceSide == LISTENING_SIDE.LEFT ? "#2D3B67" : "#C24747",
            borderRadius: "8px",
            width: "80%",
            marginLeft: deviceSide == LISTENING_SIDE.LEFT ? "20%" : "",
            backgroundColor:
              deviceSide == LISTENING_SIDE.LEFT ? "#EDF0F7" : "#FCF7F7",
            marginTop: "5px",
          }}
        >
          <>
            <img src={connectIcon4} alt="ConnectIcon(3)" />
            <Typography
              variant="h5"
              sx={{
                fontFamily: "League spartan",
                padding: "5px",
                color: "#2D3B67",
              }}
            >
              CONNECTED
            </Typography>
          </>
        </Button>
      );
    }
  };
  return (
    <>
      <Grid md={12} sm={12} container display={"flex"}>
        <Grid
          container
          md={6}
          sm={6}
          mt={3}
          flexDirection="columns"
          sx={{
            display: "flex",
            maxHeight: "40%",
            maxWidth: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Grid sm={12} md={12} container>
            {!data?.right_fitted_device?.selected ? (
              <Grid item md={4} sm={4} display="flex">
                <Avatar
                  sx={{
                    bgcolor: "#C24747",
                    top: "-15px",
                    width: 24,
                    height: 24,
                    radius: 51,
                    padding: 2,
                    fontSize: 8,
                    m: 1,
                  }}
                >
                  R
                </Avatar>
                <Box height="100%" width="100%">
                  <Button
                    onClick={() => {
                      navigate(
                        `/patient-details/${params.id}/device-selection`,
                        { state: LISTENING_SIDE.RIGHT }
                      );
                    }}
                    sx={{
                      // maxHeight: "330px",
                      // minHeight: "330px",
                      height: "50vh",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "2px dashed",
                      borderColor: "#C24747",
                      width: "80%",
                      backgroundColor: "#FCF7F7",
                    }}
                  >
                    <IconButton>
                      <AddIcon />
                    </IconButton>
                    <Typography sx={{ color: "#4D4D4D", fontSize: "20px" }}>
                      Select Device
                    </Typography>
                  </Button>

                  <Button
                    disabled={true}
                    sx={{
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                      border: "2px solid",
                      borderColor: "#DDDDDD",
                      borderRadius: "8px",
                      width: "80%",
                      // backgroundColor: "#F6FBFE",
                      marginTop: "5px",
                    }}
                  >
                    <img src={connectIcon2} alt="ConnectIcon(2)" />
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "League spartan", padding: "5px" }}
                    >
                      CONNECT
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Grid item md={4} sm={4} display={"flex"}>
                <Avatar
                  sx={{
                    bgcolor: "#C24747",
                    // left: '0px',
                    top: "-15px",
                    width: 24, // Set the desired width
                    height: 24,
                    radius: 51,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 2,
                    fontSize: 8,
                    m: 1,
                  }}
                >
                  R
                </Avatar>
                <Box
                  height={"100%"}
                  width={"100%"}
                  sx={{
                    overflow: "auto",
                    scrollbarWidth: "none",
                  }}
                >
                  <Paper
                    // variant="outlined"
                    sx={{
                      height: "50vh",
                      display: "flex",
                      zIndex: 1,
                      // flexDirection: 'column',
                      // alignItems: 'center',
                      // justifyContent: 'center',
                      border: "2px solid",
                      borderColor: "#C24747",
                      borderRadius: "8px",
                      // maxWidth: "20vw",
                      width: "80%",
                      backgroundColor: "#FCF7F7",
                    }}
                    // sm={{height:"200px"}}
                  >
                    {/* <IconButton >
                                        <AddIcon />
                                    </IconButton>
                                    <Typography sx={{ color: '#4D4D4D', fontSize: '20px',alignItems: 'center',justifyContent: 'center',  }}>Select Device</Typography> */}
                    <Grid xs={12} display={"flex"} flexDirection={"column"}>
                      <Grid
                        // xs={3}
                        mt={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "League spartan",
                            fontWeight: "bold",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#C24747",
                          }}
                        >
                          {data?.right_fitted_device?.device_type ===
                          DEVICES.BTE_OPTIMA
                            ? "BTE OPTIMA"
                            : data?.right_fitted_device?.device_type ===
                              DEVICES.BTE_PRIME
                            ? "BTE PRIME"
                            : data?.right_fitted_device?.device_type ===
                              DEVICES.RIC_OPTIMA
                            ? "RIC OPTIMA"
                            : ""}
                        </Typography>
                      </Grid>
                 {!(data?.right_fitted_device?.device_type ===
                              DEVICES.RIC_OPTIMA) && (     <Grid
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontFamily: "League spartan",
                            color: "#808080",
                          }}
                        >
                          02567(Serial Number)
                        </Typography>
                      </Grid>)}
                      {/* <Grid
                        mt={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={getRightEarImage()}
                          alt="RightEar"
                          style={{
                            display: "flex",
                            minWidth: "8vw",
                            maxHeight: "150px",
                          }}
                        />
                      </Grid> */}
                    <Grid
  mt={1}
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  {!rightImageLoaded && <CircularProgress />}
  <img
    src={getRightEarImage()}
    alt="RightEar"
    style={{
      display: rightImageLoaded ? "flex" : "none",
      minWidth: "8vw",
      maxHeight: "150px",
    }}
    onLoad={() => setRightImageLoaded(true)}
  />
</Grid>

                      <Box overflow={"auto"} sx={{ scrollbarWidth: "none" }}>
                        <Grid
                          container
                          p={1}
                          mt={3}
                          className={"main"}
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          {data?.right_fitted_device?.device_type !==
                            DEVICES.RIC_OPTIMA && (
                            <Grid sx={{ color: "#808080" }}>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  fontFamily: "League spartan",
                                }}
                              >
                                Tube
                              </Typography>
                            </Grid>
                          )}
                          <Grid ml={2} sx={{ color: "#808080" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                fontFamily: "League spartan",
                              }}
                            >
                              Dome
                            </Typography>
                          </Grid>
                          <Grid sx={{ color: "#808080" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                fontFamily: "League spartan",
                              }}
                            >
                              Channel
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          p={1}
                          mt={-3}
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          {data?.right_fitted_device?.device_type !==
                            DEVICES.RIC_OPTIMA && (
                            <Grid sx={{ color: "#4D4D4D" }}>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "League spartan",
                                }}
                              >
                                {
                                  DEVICES_TUBE_NAME[
                                    data?.right_fitted_device?.device_tube
                                  ]
                                }
                              </Typography>
                            </Grid>
                          )}
                          <Grid ml={1} sx={{ color: "#4D4D4D" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "League spartan",
                              }}
                            >
                              {
                                DOM_TYPE_NAME[
                                  data?.right_fitted_device?.dom_type
                                ]
                              }
                            </Typography>
                          </Grid>
                          <Grid mr={6} sx={{ color: "#4D4D4D" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "League spartan",
                              }}
                            >
                              {data?.right_fitted_device?.device_channel}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          mt={1}
                          xs={12}
                          sm={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              navigate(
                                `/patient-details/${params.id}/device-selection`,
                                { state: LISTENING_SIDE.RIGHT }
                              );
                            }}
                            sx={{
                              fontSize: "20px",
                              textDecoration: "underline",
                            }}
                          >
                            Change
                          </Button>
                        </Grid>
                      </Box>
                      {/* </CustomScrollBar> */}
                    </Grid>
                  </Paper>

                  {/* {!fitting.connected && (
                    <Button
                      sx={{
                        height: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1,
                        border: "2px solid",
                        borderColor: "#DDDDDD",
                        borderRadius: "8px",
                        width: "80%",
                        // backgroundColor: "#F6FBFE",
                        marginTop: "5px",
                      }}
                    >
                      <img src={connectIcon1} alt="ConnectIcon(1)" />
                      <Typography
                        variant="h5"
                        sx={{ fontFamily: "League spartan", padding: "5px" }}
                      >
                        CONNECT
                      </Typography>
                    </Button>
                  )}

                  {fitting.connected && (
                    <Button
                      sx={{
                        height: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // zIndex: 1,
                        border: "2px solid",
                        borderColor: "#C24747",
                        borderRadius: "8px",
                        width: "80%",
                        backgroundColor: "#FCF7F7",
                        marginTop: "5px",
                      }}
                    >
                      <img src={connectIcon4} alt="ConnectIcon(3)" />
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "League spartan",
                          padding: "5px",
                          color: "primary",
                        }}
                      >
                        CONNECTED
                      </Typography>
                    </Button>
                  )} */}
                  {fitting.device_type === DEVICES.RIC_OPTIMA ? (
                    <RicConnectDevice
                      side={"Right"}
                      onConnectWithDevice={(
                        data,
                        deviceInfo,
                        deviceObj,
                        disconnectFun
                      ) => {
                        console.log("dfdfdfdfds");
                        dispatch(
                          connectDevice(
                            data,
                            deviceInfo,
                            deviceObj,
                            disconnectFun,
                            ricRightFitting.device_side,
                            ricRightFitting.device_type
                          )
                        );
                      }}
                      Component={ConnectButton}
                      onLoadingChange={(loader, message) => {
                        dispatch(
                          changeLoadinfMessages(
                            loader,
                            message,
                            ricRightFitting.device_side
                          )
                        );
                      }}
                      onEnableChange={(val) =>
                        dispatch(
                          changeDeviceCompatiblity(
                            val,
                            ricRightFitting.device_side
                          )
                        )
                      }
                      onDisconnect={() => {
                        dispatch(
                          disconnectRicDevice(ricRightFitting.device_side)
                        );
                      }}
                      fitting={ricRightFitting}
                      fetchingData={fetchingData}
                    />
                  ) : (
                    <CoonectDeviceModule
                      onConnectWithDevice={(
                        data,
                        deviceInfo,
                        deviceObj,
                        disconnectFun
                      ) => {
                        dispatch(
                          connectDevice(
                            data,
                            deviceInfo,
                            deviceObj,
                            disconnectFun,
                            fitting.device_side,
                            fitting.device_type
                          )
                        );
                      }}
                      Component={ConnectButton}
                      onLoadingChange={(loader, message) => {
                        dispatch(
                          changeLoadinfMessages(
                            loader,
                            message,
                            fitting.device_side
                          )
                        );
                      }}
                      onEnableChange={(val) =>
                        dispatch(
                          changeDeviceCompatiblity(val, fitting.device_side)
                        )
                      }
                      onWriteFunctionEnabled={(fun) =>
                        dispatch(
                          onWriteFunctionChange(fun, fitting.device_side)
                        )
                      }
                      onDisconnect={() =>
                        dispatch(disconnectDevice(fitting.device_side))
                      }
                      fitting={fitting}
                    />
                  )}
                </Box>
              </Grid>
            )}
            <Grid
              md={8}
              sm={8}
              sx={{
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#808080",
                  fontSize: "16px",
                }}
              >
                Audiogram{" "}
                <IconButton
                  onClick={(e) => {
                    onEdit(e, true);
                  }}
                  sx={{ padding: 0, ml: 2 }}
                >
                  <DriveFileRenameOutlineOutlined
                    fontSize="inherit"
                    color="#DDDDDD"
                  />
                </IconButton>
              </Typography>
              <Box height={"55vh"}>
                <ChartComponent
                  rightEar
                  dataA={[
                    data?.rf1,
                    data?.rf2,
                    data?.rf3,
                    data?.rf4,
                    data?.rf5,
                    data?.rf6,
                    data?.rf7,
                    data?.rf8,
                  ]}
                  dataB={[
                    data?.brf1,
                    data?.brf2,
                    data?.brf3,
                    data?.brf4,
                    data?.brf5,
                    data?.brf6,
                    data?.brf7,
                    data?.brf8,
                  ]}
                  dataC={[
                    data?.urf1,
                    data?.urf2,
                    data?.urf3,
                    data?.urf4,
                    data?.urf5,
                    data?.urf6,
                    data?.urf7,
                    data?.urf8,
                  ]}
                  dataD={[
                    data?.mrf1,
                    data?.mrf2,
                    data?.mrf3,
                    data?.mrf4,
                    data?.mrf5,
                    data?.mrf6,
                    data?.mrf7,
                    data?.mrf8,
                  ]}
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            ml={1}
            sx={{
              height: "26vh",
              flex: 1,
            }}
          >
            <Typography variant="subtitle1" m={3} sx={{ color: "black" }}>
              Fitting History
            </Typography>
            <Box
              sx={{
                borderRadius: "4px",
                border: "1px solid #DDDDDD",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Grid
                p={1}
                container
                sx={{
                  backgroundColor: "#E8E8E8",
                }}
              >
                <Grid item xs={4.5}>
                  <Typography ml={3} variant="h6" sx={{ color: "black" }}>
                    Date and Time
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h6" sx={{ color: "black" }}>
                    Device Name
                  </Typography>
                </Grid>
              </Grid>
              {/* <Box overflow="auto"> */}
              <ContentContainer noPadding overflow="auto">
                {data?.profiles?.filter(
                  (item) => item.device_side === LISTENING_SIDE.RIGHT
                ).length != 0 ? (
                  data?.profiles
                    ?.filter(
                      (item) => item.device_side === LISTENING_SIDE.RIGHT
                    )
                    .map((item, index) => (
                      <HistoryBox
                        key={item._id}
                        patientData={data}
                        even={index % 2 === 0}
                        {...item}
                      />
                    ))
                ) : (
                  <NoDataComponent
                    message={"No Profile Found"}
                    Icon={Earbuds}
                  />
                )}
                {/* {data?.profiles?.map((item, index) => (
                  <HistoryBox
                    key={item._id}
                    patientData={data}
                    even={index % 2 === 0}
                    {...item.right}
                  />
                ))} */}
              </ContentContainer>
              {/* </Box> */}
            </Box>
          </Box>
        </Grid>
        <Grid
          container
          md={6}
          sm={6}
          mt={3}
          flexDirection="columns"
          sx={{
            display: "flex",
            maxHeight: "40%",
            maxWidth: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Grid md={12} sm={12} container>
            <Grid
              md={8}
              sm={8}
              sx={{
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#808080",
                  fontSize: "16px",
                }}
              >
                Audiogram{" "}
                <IconButton
                  onClick={(e) => {
                    onEdit(e, true);
                  }}
                  sx={{ padding: 0, ml: 2 }}
                >
                  <DriveFileRenameOutlineOutlined
                    fontSize="inherit"
                    color="#DDDDDD"
                  />
                </IconButton>
              </Typography>
              <Box height={"55vh"}>
                <ChartComponent
                  dataA={[
                    data?.lf1,
                    data?.lf2,
                    data?.lf3,
                    data?.lf4,
                    data?.lf5,
                    data?.lf6,
                    data?.lf7,
                    data?.lf8,
                  ]}
                  dataB={[
                    data?.blf1,
                    data?.blf2,
                    data?.blf3,
                    data?.blf4,
                    data?.blf5,
                    data?.blf6,
                    data?.blf7,
                    data?.blf8,
                  ]}
                  dataC={[
                    data?.ulf1,
                    data?.ulf2,
                    data?.ulf3,
                    data?.ulf4,
                    data?.ulf5,
                    data?.ulf6,
                    data?.ulf7,
                    data?.ulf8,
                  ]}
                  dataD={[
                    data?.mlf1,
                    data?.mlf2,
                    data?.mlf3,
                    data?.mlf4,
                    data?.mlf5,
                    data?.mlf6,
                    data?.mlf7,
                    data?.mlf8,
                  ]}
                />
              </Box>
            </Grid>

            {!data?.left_fitted_device?.selected ? (
              <Grid item md={4} sm={4} display="flex">
                <Box height="100%" width="100%">
                  <Button
                    onClick={() => {
                      navigate(
                        `/patient-details/${params.id}/device-selection`,
                        { state: LISTENING_SIDE.LEFT }
                      );
                    }}
                    sx={{
                      // maxHeight: "330px",
                      // minHeight: "330px",
                      height: "50vh",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "2px dashed",
                      borderColor: "#1265A1",
                      marginLeft: "20%",
                      width: "80%",
                      backgroundColor: "#F6FBFE",
                    }}
                  >
                    <IconButton>
                      <AddIcon />
                    </IconButton>
                    <Typography sx={{ color: "#4D4D4D", fontSize: "20px" }}>
                      Select Device
                    </Typography>
                  </Button>

                  <Button
                    disabled={true}
                    sx={{
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1,
                      border: "2px solid",
                      borderColor: "#DDDDDD",
                      borderRadius: "8px",
                      width: "80%",
                      marginLeft: "20%",
                      // backgroundColor: "#F6FBFE",
                      marginTop: "5px",
                    }}
                  >
                    <img src={connectIcon2} alt="ConnectIcon(2)" />
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "League spartan", padding: "5px" }}
                    >
                      CONNECT
                    </Typography>
                  </Button>
                </Box>
                <Avatar
                  sx={{
                    bgcolor: "#1265A1",
                    top: "-15px",
                    width: 24,
                    height: 24,
                    radius: 51,
                    padding: 2,
                    fontSize: 8,
                    m: 1,
                  }}
                >
                  L
                </Avatar>
              </Grid>
            ) : (
              <Grid item md={4} sm={4} display={"flex"}>
                <Box
                  height={"100%"}
                  width={"100%"}
                  sx={{
                    overflow: "auto",
                    scrollbarWidth: "none",
                  }}
                >
                  <Paper
                    // variant="outlined"
                    sx={{
                      height: "50vh",
                      display: "flex",
                      zIndex: 1,
                      // flexDirection: 'column',
                      // alignItems: 'center',
                      // justifyContent: 'center',
                      border: "2px solid",
                      borderColor: "#1265A1",
                      borderRadius: "8px",
                      marginLeft: "20%",
                      width: "80%",
                      backgroundColor: "#F6FBFE",
                    }}
                    // sm={{height:"200px"}}
                  >
                    <Grid xs={12} display={"flex"} flexDirection={"column"}>
                      <Grid
                        // xs={3}
                        mt={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "League spartan",
                            fontWeight: "bold",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#1265A1",
                          }}
                        >
                          {data?.left_fitted_device?.device_type ===
                          DEVICES.BTE_OPTIMA
                            ? "BTE OPTIMA"
                            : data?.left_fitted_device?.device_type ===
                              DEVICES.BTE_PRIME
                            ? "BTE PRIME"
                            : data?.left_fitted_device?.device_type ===
                              DEVICES.RIC_OPTIMA
                            ? "RIC OPTIMA"
                            : ""}
                        </Typography>
                      </Grid>
                     { !(data?.right_fitted_device?.device_type ===
                              DEVICES.RIC_OPTIMA) &&( <Grid
                        mt={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "10px",
                            fontFamily: "League spartan",
                            fontSize: "10px",
                            color: "#808080",
                          }}
                        >
                          02567(Serial Number)
                        </Typography>
                      </Grid>)}
                      {/* <Grid
                        mt={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={getLeftEarImage()}
                          alt="RightEar"
                          style={{
                            display: "flex",
                            minWidth: "8vw",
                            maxHeight: "150px",
                          }}
                        />
                      </Grid> */}
                      <Grid
  mt={1}
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  {!leftImageLoaded && <CircularProgress />}
  <img
    src={getLeftEarImage()}
    alt="LeftEar"
    style={{
      display: leftImageLoaded ? "flex" : "none",
      minWidth: "8vw",
      maxHeight: "150px",
    }}
    onLoad={() => setLeftImageLoaded(true)}
  />
</Grid>

                      <Box overflow={"auto"} sx={{ scrollbarWidth: "none" }}>
                        <Grid
                          container
                          p={1}
                          mt={3}
                          className={"main"}
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          {data?.left_fitted_device?.device_type !==
                            DEVICES.RIC_OPTIMA && (
                            <Grid sx={{ color: "#808080" }}>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  fontFamily: "League spartan",
                                }}
                              >
                                Tube
                              </Typography>
                            </Grid>
                          )}
                          <Grid ml={2} sx={{ color: "#808080" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                fontFamily: "League spartan",
                              }}
                            >
                              Dome
                            </Typography>
                          </Grid>
                          <Grid sx={{ color: "#808080" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                fontFamily: "League spartan",
                              }}
                            >
                              Channel
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          p={1}
                          mt={-3}
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          {data?.left_fitted_device?.device_type !==
                            DEVICES.RIC_OPTIMA && (
                            <Grid sx={{ color: "#4D4D4D" }}>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontFamily: "League spartan",
                                }}
                              >
                                {
                                  DEVICES_TUBE_NAME[
                                    data?.left_fitted_device?.device_tube
                                  ]
                                }
                              </Typography>
                            </Grid>
                          )}
                          <Grid ml={1} sx={{ color: "#4D4D4D" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "League spartan",
                              }}
                            >
                              {
                                DOM_TYPE_NAME[
                                  data?.left_fitted_device?.dom_type
                                ]
                              }
                            </Typography>
                          </Grid>
                          <Grid mr={6} sx={{ color: "#4D4D4D" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontFamily: "League spartan",
                              }}
                            >
                              {data?.left_fitted_device?.device_channel}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          mt={1}
                          xs={12}
                          sm={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              navigate(
                                `/patient-details/${params.id}/device-selection`,
                                { state: LISTENING_SIDE.LEFT }
                              );
                            }}
                            sx={{
                              fontSize: "20px",
                              textDecoration: "underline",
                            }}
                          >
                            Change
                          </Button>
                        </Grid>
                      </Box>
                      {/* </CustomScrollBar> */}
                    </Grid>
                  </Paper>
                  {fittingLeft.device_type === DEVICES.RIC_OPTIMA ? (
                    <RicConnectDevice
                      side={"Left"}
                      onConnectWithDevice={(
                        data,
                        deviceInfo,
                        deviceObj,
                        disconnectFun
                      ) => {
                        console.log("fitting LEftttt");
                        dispatch(
                          connectDevice(
                            data,
                            deviceInfo,
                            deviceObj,

                            disconnectFun,
                            ricLeftFitting.device_side,
                            ricLeftFitting.device_type
                          )
                        );
                      }}
                      Component={ConnectButton}
                      onLoadingChange={(loader, message) => {
                        dispatch(
                          changeLoadinfMessages(
                            loader,
                            message,
                            ricLeftFitting.device_side
                          )
                        );
                      }}
                      onEnableChange={(val) =>
                        dispatch(
                          changeDeviceCompatiblity(
                            val,
                            ricLeftFitting.device_side
                          )
                        )
                      }
                      onWriteFunctionEnabled={(fun) =>
                        dispatch(
                          onWriteFunctionChange(fun, ricLeftFitting.device_side)
                        )
                      }
                      onDisconnect={() =>
                        dispatch(
                          disconnectRicDevice(ricLeftFitting.device_side)
                        )
                      }
                      fitting={ricLeftFitting}
                      deviceType={ricLeftFitting.device_type}
                      fetchingData={fetchingData}
                    />
                  ) : (
                    <CoonectDeviceModule
                      onConnectWithDevice={(
                        data,
                        deviceInfo,
                        deviceObj,
                        disconnectFun
                      ) => {
                        dispatch(
                          connectDevice(
                            data,
                            deviceInfo,
                            deviceObj,
                            disconnectFun,
                            fittingLeft.device_side,
                            fittingLeft.device_type
                          )
                        );
                      }}
                      Component={ConnectButton}
                      onLoadingChange={(loader, message) => {
                        dispatch(
                          changeLoadinfMessages(
                            loader,
                            message,
                            fittingLeft.device_side
                          )
                        );
                      }}
                      onEnableChange={(val) =>
                        dispatch(
                          changeDeviceCompatiblity(val, fittingLeft.device_side)
                        )
                      }
                      onWriteFunctionEnabled={(fun) =>
                        dispatch(
                          onWriteFunctionChange(fun, fittingLeft.device_side)
                        )
                      }
                      onDisconnect={() =>
                        dispatch(disconnectDevice(fittingLeft.device_side))
                      }
                      fitting={fittingLeft}
                      deviceType={fittingLeft.device_type}
                    />
                  )}
                  {/* {!fittingLeft.connected && (
                    <Button
                      sx={{
                        height: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1,
                        border: "2px solid",
                        borderColor: "#DDDDDD",
                        borderRadius: "8px",
                        width: "80%",
                        marginLeft: "20%",
                        // backgroundColor: "#F6FBFE",
                        marginTop: "5px",
                      }}
                    >
                      <img src={connectIcon1} alt="ConnectIcon(1)" />
                      <Typography
                        variant="h5"
                        sx={{ fontFamily: "League spartan", padding: "5px" }}
                      >
                        CONNECT
                      </Typography>
                    </Button>
                  )} */}
                  {/* {fittingLeft.connected && (
                    <Button
                      sx={{
                        height: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // zIndex: 1,
                        border: "2px solid",
                        borderColor: "#2D3B67",
                        borderRadius: "8px",
                        width: "80%",
                        marginLeft: "20%",
                        backgroundColor: "#EDF0F7",
                        marginTop: "5px",
                      }}
                    >
                      <img src={connectIcon4} alt="ConnectIcon(3)" />
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "League spartan",
                          padding: "5px",
                          color: "primary",
                        }}
                      >
                        CONNECTED
                      </Typography>
                    </Button>
                  )} */}
                </Box>
                <Avatar
                  sx={{
                    bgcolor: "#1265A1",
                    top: "-15px",
                    width: 24,
                    height: 24,
                    radius: 51,
                    padding: 2,
                    fontSize: 8,
                    m: 1,
                  }}
                >
                  L
                </Avatar>
              </Grid>
            )}
          </Grid>
          <Box
            ml={1}
            sx={{
              height: "26vh",
              flex: 1,
            }}
          >
            <Typography variant="subtitle1" m={3} sx={{ color: "black" }}>
              Fitting History
            </Typography>
            <Box
              sx={{
                borderRadius: "4px",
                border: "1px solid #DDDDDD",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Grid
                p={1}
                container
                sx={{
                  backgroundColor: "#E8E8E8",
                }}
              >
                <Grid item xs={4.5}>
                  <Typography ml={3} variant="h6" sx={{ color: "black" }}>
                    Date and Time
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h6" sx={{ color: "black" }}>
                    Device Name
                  </Typography>
                </Grid>
              </Grid>
              {/* <Box overflow="auto"> */}
              <ContentContainer noPadding overflow="auto">
                {data?.profiles?.filter(
                  (item) => item.device_side === LISTENING_SIDE.LEFT
                ).length != 0 ? (
                  data?.profiles
                    ?.filter((item) => item.device_side === LISTENING_SIDE.LEFT)
                    .map((item, index) => (
                      <HistoryBox
                        key={item._id}
                        patientData={data}
                        even={index % 2 === 0}
                        {...item}
                      />
                    ))
                ) : (
                  <NoDataComponent
                    message={"No Profile Found"}
                    Icon={Earbuds}
                  />
                )}
                {/* {data?.profiles?.map((item, index) => (
                  <HistoryBox
                    key={item._id}
                    patientData={data}
                    even={index % 2 === 0}
                    {...item.left}
                  />
                ))} */}
              </ContentContainer>
              {/* </Box> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const PatientDetailedViewCommonUI = ({
  loading,
  data,
  onEdit,
  isLoading,
  onNewFitting,
  fetchingData,
  onRicNewFitting,
  title,
  setData,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { fitting } = useSelector((state) => state);
  const { fittingLeft } = useSelector((state) => state);
  const { ricRightFitting } = useSelector((state) => state);
  const { ricLeftFitting } = useSelector((state) => state);
  
  // console.log("fittinggg", ricRightFitting);
  // console.log("fittinggg LEFttttttt", ricLeftFitting);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  // const { ricLeftFitting, ricRightFitting } = useSelector((state) => state);
// console.log("UI Redux State:", { ricLeftFitting, ricRightFitting });

  useEffect(() => {
    console.log("Re-rendering PatientDetailedViewCommonUI - Redux State Updated:", {
      fitting,
      fittingLeft,
    });
  }, [fitting, fittingLeft]);
  
  // const tryy = () => {
  //   dispatch(
  //     openModal(
  //       <PatientInformationView
  //         onNewFitting={onNewFitting}
  //         onRicNewFitting={onRicNewFitting}
  //         title={title}
  //         loading={loading}
  //         data={data}
  //         setData={setData}
  //       />,
  //       "lg",
  //       false,
  //       "information"
  //     )
  //   );
  // };
  const detailsView = () => {
    dispatch(
      openModal(
        <PatientInformationViewController data={data} />,
        "lg",
        false,
        "information"
      )
    );
  };

  const filter = useLocation()?.state?.filters ?? undefined;
  
  const letter = (name) => {
    let newName = name?.charAt(0).toUpperCase();
    let flag = false;
    for (let i = 1; i < name?.length; i++) {
      if (name?.charAt(i) == " ") {
        flag = true;
      } else {
        if (flag) {
          newName = newName + " " + name?.charAt(i).toUpperCase();
          flag = false;
        } else {
          newName = newName + name?.charAt(i).toLowerCase();
        }
      }
    }
    return newName;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  return (
    <>
      <FlexedColumnBox sx={{}}>
      {fetchingData && (fitting.device_type === DEVICES.RIC_OPTIMA || fittingLeft.device_type === DEVICES.RIC_OPTIMA) && (
        <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center" }}>
        
          <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
           <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CircularProgress color="inherit" />
          <Typography> Please wait while data is getting fetched.</Typography>
       </Box>
        </Backdrop>
        </Box>
      )}
        {!loading && (
          <>
            {" "}
            <Stack
              direction={"row"}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Stack direction={"row"}>
                {/* <IconButton
                  sx={{ marginRight: "10px" }}
                  onClick={() => {
                    navigate("/patient-details");
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#2D3B67" }} fontSize="medium" />
                </IconButton> */}
                <IconButton
  sx={{ marginRight: "10px" }}
  onClick={() => {
    if (fitting.device_type === DEVICES.RIC_OPTIMA || fittingLeft.device_type === DEVICES.RIC_OPTIMA) {
      if (fitting.connected) {
        dispatch(disconnectRicDevice(fitting.device_side));
        dispatch(disconnectDevice(fitting.device_side));
      }
      if (fittingLeft.connected) {
        dispatch(disconnectRicDevice(fittingLeft.device_side));
        dispatch(disconnectDevice(fittingLeft.device_side));
      }
    } else {
      if (fitting.connected) {
        dispatch(disconnectDevice(fitting.device_side));
      }
      if (fittingLeft.connected) {
        dispatch(disconnectDevice(fittingLeft.device_side));
      }
    }
    navigate("/patient-details", { state: {filter} });

  }}
>
  <ArrowBackIcon sx={{ color: "#2D3B67" }} fontSize="medium" />
</IconButton>
                <Typography
                  sx={{ textDecoration: "underline" }}
                  color={"#2D3B67"}
                  variant="h3"
                  alignSelf="center"
                >
                  {letter(data?.first_name)}
                </Typography>
                <IconButton onClick={detailsView}>
                  <PermIdentityOutlined
                    sx={{ color: "#2D3B67" }}
                    fontSize="small"
                  />
                </IconButton>
              </Stack>
              <Box mr={3} p={2}>
                {fitting.connected || fittingLeft.connected ? (
                  fitting.device_type === DEVICES.RIC_OPTIMA ? (
                    <Button
                      onClick={onRicNewFitting}
                      variant="contained"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                      disabled={isLoading}
                    >
                      
                        <>
                          <Typography variant="h4" lineHeight="10%">
                            <HearingAidIcon />
                          </Typography>
                          &nbsp;
                          <Typography fontWeight={500} variant="h5">
                          In-Person Fitting
                          </Typography>
                        </>
                      
                    </Button>
                  ) : (
                    <Button
                      onClick={onNewFitting}
                      variant="contained"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography variant="h4" lineHeight="10%">
                        <HearingAidIcon />
                      </Typography>
                      &nbsp;
                      <Typography fontWeight={500} variant="h5">
                        In-Person Fitting
                      </Typography>
                    </Button>
                  )
                ) : (
                  // Default to Remote Fitting
                  <Button
                    // onClick={() => {
                    //   dispatch(applyForRemoteFitting(LISTENING_SIDE.BOTH));
                    //   onNewFitting();
                    // }}
                    onClick={() => {
                      dispatch(applyForRemoteFitting(LISTENING_SIDE.BOTH));
                      fitting.device_type === DEVICES.RIC_OPTIMA ? onRicNewFitting() : onNewFitting();
                    }}
                    variant="contained"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h4" lineHeight="0%">
                      <SettingsRemoteIcon />
                    </Typography>
                    &nbsp;
                    <Typography fontWeight={500} lineHeight="100%" variant="h5">
                      Remote Fitting
                    </Typography>
                  </Button>
                )}

                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  disabled={
                    !(
                      data?.left_fitted_device?.selected ||
                      data?.right_fitted_device?.selected
                    )
                  }
                  style={{
                    backgroundColor:
                      data?.left_fitted_device?.selected ||
                      data?.right_fitted_device?.selected
                        ? "#2B3D69"
                        : "#CCCCCC",
                    color: "#FFFFFF",
                  }}
                  startIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M3 8L15 8M15 8C15 9.65686 16.3431 11 18 11C19.6569 11 21 9.65685 21 8C21 6.34315 19.6569 5 18 5C16.3431 5 15 6.34315 15 8ZM9 16L21 16M9 16C9 17.6569 7.65685 19 6 19C4.34315 19 3 17.6569 3 16C3 14.3431 4.34315 13 6 13C7.65685 13 9 14.3431 9 16Z"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                  endIcon={<KeyboardArrowDownIcon />}
                  sx={{
                    width: "210px",
                    fontFamily: "League Spartan",
                    fontSize: "20px",
                  }}
                >
                  New Fitting
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <Button
                      onClick={onNewFitting}
                      variant="outlined"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography variant="h4" lineHeight="10%">
                        <HearingAidIcon />
                      </Typography>
                      &nbsp;
                      <Typography
                        fontWeight={500}
                        // lineHeight="50%"
                        variant="h5"
                      >
                        In-Person Fitting
                      </Typography>
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      onClick={() => {
                        // dispatch(applyForRemoteFitting());
                        // onNewFitting();
                      }}
                      variant="outlined"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography variant="h4" lineHeight="0%">
                        <SettingsRemoteIcon />
                      </Typography>
                      &nbsp;
                      <Typography
                        fontWeight={500}
                        lineHeight="100%"
                        variant="h5"
                      >
                        Remote Fitting
                      </Typography>
                    </Button>
                  </MenuItem>
                </Menu> */}
              </Box>
            </Stack>
          </>
        )}
        <OuterContainer sx={{}}>
          {loading && (
            <CenteredBox>
              <CircularProgress />
            </CenteredBox>
          )}
          {!loading && (!data || !data["_id"]) && (
            <NoDataComponent message={title + " not found"} Icon={Person} />
          )}

          {!loading && data && data["_id"] && (
            <>
              <FlexedColumnBox sx={{ flex: 1 }}>
                <PatientAudioGramView
                  onEdit={onEdit}
                  data={data}
                  fetchingData={fetchingData}
                />
              </FlexedColumnBox>
            </>
          )}
        </OuterContainer>
      </FlexedColumnBox>
      {/* </Box> */}
    </>
  );
};
export default PatientDetailedViewCommonUI;
