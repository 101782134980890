import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import LeftImg from "../../../assets/images/Left (1).png";
import RightImg from "../../../assets/images/Left (2).png";
import METALIC_LEFT_RIC_OPTIMA from "../../../assets/images/metalic_left_ric.svg";
import METALIC_RIGHT_RIC_OPTIMA from "../../../assets/images/metalic_right_ric.svg";
import BLACK_LEFT_RIC_OPTIMA from "../../../assets/images/black_left_ric.svg";
import BLACK_RIGHT_RIC_OPTIMA from "../../../assets/images/black_right_ric.svg";
import BEIGE_LEFT_RIC_OPTIMA from "../../../assets/images/beige_left_ric.svg";
import BEIGE_RIGHT_RIC_OPTIMA from "../../../assets/images/beige_right_ric.svg";

import {
  DEVICE_COLORS,
  DEVICES,
  LISTENING_SIDE,
} from "../../../utils/patient.constants";
import { useDispatch, useSelector } from "react-redux";
import { setRemarks } from "../../../store/actions/ricFittingActions";
import { useNavigate, useParams } from "react-router-dom";

const RicCompleteSessionController = ({
  id,
  onCompleteSession,
  onCancel,
  isBackButton,
}) => {
  const { ricLeftFitting, ricRightFitting } = useSelector((state) => state);
  const LEFT_EAR_IMAGES = {
    [DEVICE_COLORS.METALIC]: METALIC_LEFT_RIC_OPTIMA,
    [DEVICE_COLORS.BLACK]: BLACK_LEFT_RIC_OPTIMA,
    [DEVICE_COLORS.BEIGE]: BEIGE_LEFT_RIC_OPTIMA,
  };

  const RIGHT_EAR_IMAGES = {
    [DEVICE_COLORS.METALIC]: METALIC_RIGHT_RIC_OPTIMA,
    [DEVICE_COLORS.BLACK]: BLACK_RIGHT_RIC_OPTIMA,
    [DEVICE_COLORS.BEIGE]: BEIGE_RIGHT_RIC_OPTIMA,
  };

  const getLeftEarImage = () => {
    return (
      LEFT_EAR_IMAGES[ricLeftFitting?.device_color] || METALIC_LEFT_RIC_OPTIMA
    );
  };

  const getRightEarImage = () => {
    return (
      RIGHT_EAR_IMAGES[ricRightFitting?.device_color] ||
      METALIC_RIGHT_RIC_OPTIMA
    );
  };

  const dispatch = useDispatch();
  console.log("Component rendering, remarks:", ricRightFitting.remarks);
  const forceUpdate = useState()[1].bind(null, {}); // Create a forced update function
  const navigate = useNavigate();
  const params = useParams();

  return (
    <CustomDialog
      id="complete"
      onSubmit={(e) => {
        onCompleteSession(e, ricRightFitting, ricLeftFitting);
      }}
      onClose={
        isBackButton
          ? () => {
              navigate(`/patient-details/${params.id}`);
            }
          : onCancel
      }
      title={"Complete Session"}
      closeText={isBackButton ? "No" : "Cancel"}
      confirmText={isBackButton ? "Yes" : "Save"}
    >
      <Box mt={3}>
        {isBackButton ? (
          <Typography color={"#4D4D4D"}>
            Do you want to save the device configuration?
          </Typography>
        ) : (
          <>
            <Typography color={"#4D4D4D"}>
              The device configuration will be saved as per current settings.
            </Typography>
            <Typography color={"#4D4D4D"}>
              Would you like to complete the session?
            </Typography>
          </>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {ricRightFitting?.connected && (
              <Box
                m={7}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img height={"100px"} src={getRightEarImage()} />
                <Typography variant="h6" sx={{ color: "#C24747" }}>
                  {ricRightFitting.device_type === DEVICES.RIC_OPTIMA
                    ? "RIC OPTIMA"
                    : ricRightFitting.device_type === DEVICES.BTE_OPTIMA
                    ? "BTE OPTIMA"
                    : "BTE PRIME"}
                </Typography>
              </Box>
            )}
            {ricLeftFitting?.connected && (
              <Box
                m={7}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img height={"100px"} src={getLeftEarImage()} />
                <Typography variant="h6" sx={{ color: "#1265A1" }}>
                  {ricLeftFitting.device_type === DEVICES.RIC_OPTIMA
                    ? "RIC OPTIMA"
                    : ricLeftFitting.device_type === DEVICES.BTE_OPTIMA
                    ? "BTE OPTIMA"
                    : "BTE PRIME"}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Typography variant="h4" fontWeight={500} color="#808080">
            Remarks
          </Typography>
          <TextField
            multiline
            sx={{ color: "#808080" }}
            maxRows={2}
            variant="standard"
            fullWidth
            value={ricRightFitting.remarks}
            onChange={(e) => {
              console.log("📝 Dispatching remarks:", e.target.value);
              dispatch(setRemarks(e.target.value, LISTENING_SIDE.RIGHT));
              dispatch(setRemarks(e.target.value, LISTENING_SIDE.LEFT));
            }}
          />
        </Box>
      </Box>
    </CustomDialog>
  );
};

export default RicCompleteSessionController;
