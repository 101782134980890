import React, { Fragment, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReassignComponent from "./ReassignComponent";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { calculateAge, findObjectKeyByValue } from "../../utils/helper";
import { GENDER } from "../../utils/constants";
import {
  IMPAIREMENT_TYPE,
  LEVEL_OF_HEARING_LOSS,
  TYPE_OF_HEARING_LOSS_NAMES,
} from "../../utils/patient.constants";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { FlexedColumnBox } from "../../components/layouts/common/boxes";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import moment from "moment";
import { QrCodeModal } from "./PatientQrCode";
import DeletePatientButton from "./DeletePatientButton";
import { Edit, ManageAccounts } from "@mui/icons-material";
import SubmitButton from "../../components/button/SubmitButton";
import { useTheme } from "@emotion/react";

const DetailsBox = memo(({ title, data = [{ title: "", value: "" }] }) => {
  return (
    <Box>
      {title == "Personal Details" ? (
        <Grid container spacing={2} flexDirection="row" ml={1}>
          {data.map((item) => (
            <Fragment key={item.title}>
              <Grid container xs={6}>
                <Grid xs={3}>
                  <Typography variant="h6" color="dark" fontWeight={500}>
                    {item.title} :
                  </Typography>
                </Grid>

                <Grid xs={3}>
                  <Typography variant="h6" color="grey" fontWeight={500}>
                    {item.value}
                  </Typography>
                </Grid>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      ) : (
        <Grid container xs={12} spacing={2} ml={1}>
          {data.map((item) => (
            <Grid xs={4}>
              <Typography variant="h5" color="dark" fontWeight={500}>
                {item.title} :
              </Typography>
              <Typography variant="h5" color="grey" fontWeight={500}>
                {item.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
});

export const PatientInformationView = ({ data }) => {
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();

  const reassign = () => {
    dispatch(
      openModal(<ReassignComponent id={data?._id} />, "sm", false, "reassign")
    );
  };

  return (
    <>
      <FlexedColumnBox sx={{ overflowY: "auto" }}>
        <Box
          sx={{
            border: "1px solid",
            borderRadius: "4px",
            marginTop: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid",
              borderTopRightRadius: "4px",
              borderTopLeftRadius: "4px",
              p: 2,
              gap: 2,
              backgroundColor: "#EDF0F7",
            }}
          >
            <Typography color={"black"} variant="h4">
              Personal Details
            </Typography>
            <Edit
              onClick={() => {
                let route = "/patient-details/" + params.id + "/update";
                dispatch(closeModal("information"));
                navigate(route, { state: { tabData: 1 } });
              }}
              sx={{ cursor: "pointer" }}
              fontSize={theme.typography.h5.fontSize}
              color="black"
            />
          </Box>
          <Box p={3}>
            <DetailsBox
              title="Personal Details"
              data={[
                {
                  title: "Full Name",
                  value:
                    data?.first_name +
                    " " +
                    (data?.middle_name ?? "") +
                    " " +
                    data?.last_name,
                },
                {
                  title: "Contact",
                  value: data?.phone ?? "NA",
                },
                {
                  title: "Email",
                  value: data?.email ?? "NA",
                },
                {
                  title: "Gender",
                  value: findObjectKeyByValue(data?.gender, GENDER) ?? "NA",
                },
                {
                  title: "Date of Birth",
                  value: moment(data?.dob).format("DD / MM / YYYY") ?? "NA",
                },
                {
                  title: "Age",
                  value: calculateAge(data?.dob) ?? "0",
                },
              ]}
            />

            <Grid container xs={12} pl={1}>
              <Grid xs={1.5}>
                <Typography variant="h6" color={"dark"} fontWeight={500}>
                  Address :
                </Typography>
              </Grid>
              <Grid xs={10.5}>
                <Typography variant="h6" color="grey" fontWeight={500}>
                  {data.address}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box
          sx={{
            border: "1px solid",
            borderRadius: "4px",
            marginTop: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid",
              borderTopRightRadius: "4px",
              borderTopLeftRadius: "4px",
              p: 2,
              gap: 2,
              backgroundColor: "#EDF0F7",
            }}
          >
            <Typography color={"black"} variant="h4">
              Hearing Details
            </Typography>
          </Box>
          <Box p={3} mt={3}>
            <DetailsBox
              title="Hearing  Details"
              data={[
                {
                  title: "Level Of Hearing Loss",
                  value: findObjectKeyByValue(
                    data?.level_of_loss,
                    LEVEL_OF_HEARING_LOSS
                  ),
                },
                {
                  title: "Type Of Hearing Loss",
                  value: TYPE_OF_HEARING_LOSS_NAMES[data.type_of_loss] ?? "NA",
                },
                {
                  title: "Impairment Type",
                  value:
                    findObjectKeyByValue(
                      data?.impairment_type,
                      IMPAIREMENT_TYPE
                    ) ?? "NA",
                },
              ]}
            />

            <Grid container columns={12}>
              <Grid mt={6} xs={4}>
                <Typography variant="h4" color="primary">
                  Application QR Code
                </Typography>
                <Typography
                  variant="body"
                  color="grey"
                  fontWeight={500}
                  sx={{ width: "100px" }}
                >
                  Scan QR code to login in the app
                </Typography>
                <QrCodeModal id={data._id} />
              </Grid>

              <Grid mt={6} xs={4}>
                <Typography variant="h4" color="primary" fontWeight={500}>
                  Created By{" "}
                </Typography>
                <Box>
                  <Typography variant="h6" color="grey" mb={5} fontWeight={500}>
                    {[data.created_by ? data.created_by.name : "NA"]}
                  </Typography>
                </Box>
              </Grid>
              <Grid mt={6} xs={4}>
                <Typography variant="h4" color="primary" fontWeight={500}>
                  Audiologist{" "}
                </Typography>
                {data?.assigned?.name || (
                  <IconButton
                    sx={{ marginTop: "-12px", color: "red" }}
                    onClick={() => {
                      reassign();
                    }}
                  >
                    <ManageAccounts />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box mt={1}>
          <DeletePatientButton
            id={data?._id}
            name={data?.first_name + " " + data?.last_name}
          />
        </Box>
      </FlexedColumnBox>
    </>
  );
};