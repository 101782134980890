import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import mode1 from "../../../assets/images/mode1.svg";
import mode2 from "../../../assets/images/mode2.svg";
import mode3 from "../../../assets/images/mode3.svg";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import mode4 from "../../../assets/images/mode4.svg";
import NOISE_MODE from "../../../assets/images/noise_mode.svg";
import OUTDOOR_MODE from "../../../assets/images/outdoor_mode.svg";
import QUITE_MODE from "../../../assets/images/quite_mode.svg";
import React, { useEffect, useState } from "react";
import {
  LISTENING_SIDE,
  MANAGE_PROGRAM,
  MANAGE_PROGRAM_LIST,
  MANAGE_PROGRAM_LIST_Array,
  MANAGE_PROGRAM_NAME,
} from "../../../utils/patient.constants";
import { useDispatch, useSelector } from "react-redux";
import { changeMode } from "../../../store/actions/fittingAction";



const RicManageProgramController = ({ onSaveFitting }) => {


  return (
    <Box mt={4} ml={6}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        <Box>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: "#808080",
                fontWeight: "500",
                fontFamily: "League Spartan",
              }}
            >
              M1
            </Typography>
          </Box>
          
            <Box
              sx={{
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                width: "19vw",
                height: "300px",
              }}
            >
              <Box
                p={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderBottom: "1px solid #DDDDDD",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2D3B67",
                    fontWeight: "600",
                    fontFamily: "League Spartan",
                    padding:'5px',
                  }}
                >
                  Quite Mode
                </Typography>
               
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "213px",
                }}
              >
                <img src={QUITE_MODE} />
              </Box>
            </Box>
          
        </Box>
        <Box>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: "#808080",
                fontWeight: "500",
                fontFamily: "League Spartan",
              }}
            >
              M2
            </Typography>
          </Box>
          
            <Box
              sx={{
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                width: "19vw",
                height: "300px",
              }}
            >
              <Box
                p={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderBottom: "1px solid #DDDDDD",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2D3B67",
                    fontWeight: "600",
                    fontFamily: "League Spartan",
                    padding:'5px',
                  }}
                >
                  Noise Mode
                </Typography>
              
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "213px",
                }}
              >
                <img src={NOISE_MODE} />
              </Box>
            </Box>
        
         
        </Box>
        <Box>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: "#808080",
                fontWeight: "500",
                fontFamily: "League Spartan",
              }}
            >
              M3
            </Typography>
          </Box>
          
            <Box
              sx={{
                border: "1px solid #DDDDDD",
                borderRadius: "5px",
                width: "19vw",
                height: "300px",
              }}
            >
              <Box
                p={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  borderBottom: "1px solid #DDDDDD",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#2D3B67",
                    fontWeight: "600",
                    fontFamily: "League Spartan",
                    padding:'5px',
                  }}
                >
                  Outdoor Mode
                </Typography>
                
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "213px",
                }}
              >
                <img src={OUTDOOR_MODE} />
              </Box>
            </Box>
          
          
        </Box>
        
      </Box>
    </Box>
  );
};

export default RicManageProgramController;
