import { memo, useEffect, useRef, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { calculateAge, findObjectKeyByValue } from "../../utils/helper";
import { Box, IconButton } from "@mui/material";
import { useMemo } from "react";
import moment from "moment";
import { getPatientApi } from "../../apis/patient.api";
import { GENDER, USER_ROLES } from "../../utils/constants";

import {
  LEVEL_OF_HEARING_LOSS,
  TYPE_OF_HEARING_LOSS_NAMES,
} from "../../utils/patient.constants";
import { useLocation, useNavigate } from "react-router-dom";
import { QrCodeButton } from "./PatientQrCode";
import { Delete, KeyboardArrowRight } from "@mui/icons-material";
import { closeModal } from "../../store/actions/modalAction";
import DeletePatientButton from "./DeletePatientButton";

const ActionComponent = memo(
  ({ params, setParams, deleteApi, callBack, filters }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state);
    const onViewMore = () => {
      navigate(`${params.patient_uid}`, { state: { filters } });
    };

    return (
      <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        {/* <QrCodeButton params={params} setParams={setParams} /> */}
        {user.data.role == USER_ROLES.ADMIN && (
          <DeletePatientButton
            callBack={callBack}
            name={params.first_name + " " + params.last_name}
            id={params._id}
            isDashboard={true}
          />
        )}
        <IconButton disabled={loading} size="inherit" onClick={onViewMore}>
          <KeyboardArrowRight fontSize="inherit" />
        </IconButton>
      </Box>
    );
  }
);

const ListController = () => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(closeModal("information"));
  }, []);

  const preFilter = useLocation()?.state?.filter ?? undefined;

  useEffect(() => {
    console.log("preFilter", preFilter);
    if (preFilter) setFilters({ ...filters, ...preFilter });
  }, [preFilter]);

  const navigate = useNavigate();

  const title = "Patients";
  const fetchApi = getPatientApi;

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["first_name", "last_name", "phone"],
    role: "",
    sort: "createdAt",
    sortDirection: -1,
  });

  const filtersRef = useRef(filters);

  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  const columns = useMemo(() => {
    const arr = [
      {
        id: 0,
        minWidth: "50px",
        fieldName: "patient_uid",
        label: "Patient ID",
        align: "left",
      },
      {
        id: 1,
        minWidth: "50px",
        fieldName: "createdAt",
        label: "Created Date",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =>
          moment(params?.createdAt).format("DD/MM/YYYY"),
      },
      {
        id: 2,
        minWidth: "50px",
        fieldName: "updatedAt",
        label: "Fitting Date",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =>
          moment(params?.updatedAt).format("DD/MM/YYYY"),
      },

      {
        id: 3,
        minWidth: "50px",
        fieldName: "first_name",
        label: "Full Name",
        align: "left",
        sort: true,
        renderValue: (params) => params.first_name + " " + params.last_name,
      },
      {
        id: 4,
        minWidth: "50px",
        fieldName: "age",
        label: "Age(yrs.)",
        align: "left",
        renderValue: (params) => calculateAge(params.dob),
      },
      {
        id: 8,
        minWidth: "50px",
        fieldName: "gender",
        label: "Gender",
        align: "left",
        renderValue: (params) =>
          findObjectKeyByValue(parseInt(params.gender), GENDER),
      },
    ];

    if (user.data.role == USER_ROLES.ADMIN || user.data.children?.length > 0) {
      arr.push({
        id: 6,
        fieldName: "created_by",
        label: "Created By",
        renderValue: (params, setParams) => params?.created_by?.name,
      });
    }

    // arr.push({
    //   id: 4,
    //   minWidth: "50px",
    //   fieldName: "type_of_loss",
    //   label: "Hearing Loss Type",
    //   align: "left",
    //   renderValue: (params) => TYPE_OF_HEARING_LOSS_NAMES[params.type_of_loss],
    // });
    // arr.push({
    //   id: 5,
    //   minWidth: "50px",
    //   fieldName: "level_of_loss",
    //   label: "Hearing Loss Level",
    //   align: "left",
    //   renderValue: (params) =>
    //     findObjectKeyByValue(
    //       parseInt(params.level_of_loss),
    //       LEVEL_OF_HEARING_LOSS
    //     ),
    // });

    //         arr.push({
    //             id: 8,
    //             fieldName: '_id',
    //             label: 'Patient Qr',
    // minWidth:"50px",
    //             align: "left",
    //             renderValue: (params, setParams) => ,
    //         })

    arr.push({
      id: 7,
      fieldName: "",
      label: "",
      align: "right",
      renderValue: (params, setParams) => {
        return (
          <ActionComponent
            callBack={fetchList}
            params={params}
            setParams={setParams}
            filters={filtersRef.current}
          />
        );
      },
    });

    return arr;
  }, [dispatch, user.data, filtersRef.current]);

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  // const onCreateBtnClick = () => {
  //   navigate("new-patient");
  // };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        // onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
