import {
    Alert,
    Box,
    Button,
    IconButton,
    Typography,
    useTheme,
  } from "@mui/material";
  import { useDispatch, useSelector } from "react-redux";
  import MODULES from "../../utils/module.constant";
  import SubmitButton from "../../components/button/SubmitButton";
  import { closeModal, openModal } from "../../store/actions/modalAction";
  import MessageDilog from "../../components/MessageDilog";
  import { useEffect, useState } from "react";
  import { callApiAction } from "../../store/actions/commonAction";
  import { deletePatientApi } from "../../apis/patient.api";
  import { callSnackBar } from "../../store/actions/snackbarAction";
  import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants";
  import { CenteredBox } from "../../components/layouts/common/boxes";
  import { Delete, DeleteOutline, Warning } from "@mui/icons-material";
  import { useNavigate } from "react-router-dom";
  
  const DeletePatientButton = ({
    id,
    name,
    isDashboard,
    callBack = () => {},
  }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state);
    const [loading, setLoading] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const onSubmit = (e) => {
      // e.preventDefault()
      setLoading(true);
      dispatch(closeModal("patient-dlt"));
      // navigate("/patient-details")
      dispatch(
        callApiAction(
          async () => await deletePatientApi({ id }),
          (response) => {
            setLoading(false);
            setDeleted(true);
            callBack && callBack();
            dispatch(
              callSnackBar(
                name + " Deleted Successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
          },
          (err) => {
            setLoading(false);
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
          }
        )
      );
    };
    const onClick = () => {
      dispatch(
        openModal(
          <MessageDilog
            confirmText="Delete"
            onSubmit={onSubmit}
            modalId="patient-dlt"
            title="Alert!"
            message={
              <CenteredBox flexDirection="column">
                <Typography variant="h1">
                  <Warning color="error" fontSize="inherit" />
                </Typography>
                <Typography color="error">{`Are you sure you want to delete ${name}?`}</Typography>
              </CenteredBox>
            }
          />,
          "sm",
          true,
          "patient-dlt"
        )
      );
    };
  
    useEffect(() => {
      if (deleted) navigate("/patient-details");
    }, [deleted]);
    if (!user?.data?.modules?.includes(MODULES.ADMIN_AREA)) return <></>;
    return (
      <Box>
        {isDashboard && user.data.role == USER_ROLES.ADMIN ? (
          <IconButton onClick={onClick} aria-label="delete">
            <Delete color="error" />
          </IconButton>
        ) : (
          <SubmitButton
            fullWidth={false}
            loading={loading}
            title="Remove patient"
            sx={{
              fontSize: theme.typography.h6.fontSize,
              padding: 2,
              width: "unset !important",
            }}
            onClick={onClick}
            icon={
              <Delete fontSize={theme.typography.h4.fontSize} sx={{ mr: 2 }} />
            }
            variant="outlined"
            color="error"
          ></SubmitButton>
        )}
      </Box>
    );
  };
  export default DeletePatientButton;