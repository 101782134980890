// import { getAudiometryApi } from "../../apis/audiometry.api";
import { getPatientApi, getPatientByIdApi } from "../../apis/patient.api";
import { SNACK_BAR_VARIETNS, actions } from "../../utils/constants";
import { calculateAge, unMapValues } from "../../utils/helper";
import { calculateIGI } from "../../utils/j10.helper";
import { Calculation } from "../../utils/Fig6Component";
import { DEVICES, LISTENING_SIDE } from "../../utils/patient.constants";
import { callApiAction } from "./commonAction";
import { callSnackBar } from "./snackbarAction";
import { dslv5 } from "../../utils/DSLv";
import WriteRicDataToDevice from "../../pages/fitting/RicFitting/WriteRicDataToDevice";
import ReadRicDataFromDevice from "../../pages/fitting/RicFitting/ReadRicDataToDevice";
import { getPatientProfileByIdApi } from "../../apis/patientprofile.api";

const AUTO_FIT_DATA = [
  { threshold: 0, values: ["15", "15", "15", "15", "15", "15", "15", "15"] },
  { threshold: 5, values: ["15", "15", "15", "15", "15", "15", "15", "15"] },
  { threshold: 10, values: ["15", "15", "15", "15", "15", "15", "15", "15"] },
  { threshold: 15, values: ["15", "15", "15", "15", "15", "15", "15", "15"] },
  { threshold: 20, values: ["15", "15", "15", "15", "15", "15", "15", "15"] },
  { threshold: 25, values: ["15", "15", "15", "15", "15", "15", "15", "15"] },
  { threshold: 30, values: ["15", "15", "15", "15", "15", "15", "15", "15"] },
  { threshold: 35, values: ["15", "12", "12", "12", "12", "12", "15", "15"] },
  { threshold: 40, values: ["15", "12", "12", "12", "12", "12", "15", "15"] },
  { threshold: 45, values: ["0F", "0F", "0F", "0F", "0F", "0F", "0F", "12"] },
  { threshold: 50, values: ["0F", "0F", "0F", "0F", "0F", "0F", "0F", "12"] },
  { threshold: 55, values: ["0F", "0C", "0C", "0C", "0C", "0C", "0F", "0F"] },
  { threshold: 60, values: ["0F", "0C", "0C", "0C", "0C", "0C", "0F", "0F"] },
  { threshold: 65, values: ["09", "09", "09", "09", "09", "09", "09", "0C"] },
  { threshold: 70, values: ["09", "09", "09", "09", "09", "09", "09", "0C"] },
  { threshold: 75, values: ["09", "06", "06", "06", "06", "06", "09", "09"] },
  { threshold: 80, values: ["09", "06", "06", "06", "06", "06", "09", "09"] },
  { threshold: 85, values: ["03", "03", "03", "03", "03", "03", "03", "06"] },
  { threshold: 90, values: ["03", "03", "03", "03", "03", "03", "03", "06"] },
  { threshold: 95, values: ["03", "00", "00", "00", "00", "00", "03", "03"] },
  { threshold: 100, values: ["03", "00", "00", "00", "00", "00", "03", "03"] }
];
export const connectDevice = (
  hardwareData,
  deviceInfo,
  deviceObj,
  disconnectFun,
  side
) => {
  return {
    type: actions.CONNECT_DEVICE,
    hardwareData,
    deviceInfo,
    deviceObj,
    disconnectFun,
    side: side,
  };
};

export const read_only = (value) => {
  return { type: actions.READ_ONLY_CHANGED, value };
};

export const changeRemarks = (value, side) => {
  return { type: actions.CHNAGE_REMARKS, value, side: side };
};

export const setRemarks = (value, side) => {
  return { type: actions.CHNAGE_REMARKS, value, side };
};

export const changeLoadinfMessages = (loading, messages, side) => {
  return { type: actions.CHNAGE_LOADING_VALUES, loading, messages, side: side };
};



// export const disconnectRicDevice = (side) => {
//   return async (dispatch, getState) => {
//     const state = getState();
//     const fitting = side === LISTENING_SIDE.LEFT ? state.ricLeftFitting : state.ricRightFitting;
//     if (fitting.deviceObj?.gatt?.connected) {
//       fitting.deviceObj.gatt.disconnect();
//     }
//     dispatch(callSnackBar("Device Disconnected.", SNACK_BAR_VARIETNS.error));
//     dispatch({
//       type: actions.DISCONNECT_DEVICE,
//       side: side,
//       deviceObj: fitting.deviceObj,
//     });
//   }};
  export const changeDeviceCompatiblity = (value, side) => {
    return { type: actions.CHNAGE_DEVICE_COMPATIBLITY, value, side: side };
  };

  export const readBatteryLevel = (side, deviceObj) => {
    return async (dispatch) => {
      try {
        // Battery Read Command
        const command = "3C 42 41 54 54 45 52 59 3E 0D 0A";
  
        console.log(`Sending Battery Read Command to ${side} device:`, command);
  
        // Send command to device and get response
        const response = await ReadRicDataFromDevice(command, side, deviceObj);
  
        if (!response) {
          console.error(`No response received for ${side} device`);
          return;
        }
  
        console.log(`Received response from ${side} device:`, response);
  
        // Trim and split the response into parts
        const responseParts = response.trim().split(" ");
        
        console.log(`Parsed response parts:`, responseParts);
  
        // Check if the response has exactly 3 parts (6 digits)
        if (responseParts.length !== 3) {
          console.warn(`Invalid battery response from ${side} device:`, response);
          return;
        }
  
        // Extract first HEX value (which represents battery percentage)
        const batteryHex = responseParts[0]; // Byte 0 as battery percentage
  
        console.log(`Extracted Battery HEX value: ${batteryHex}`); // 🔍 Debugging Log
        
        const batteryPercentage = parseInt(batteryHex, 16); // Convert HEX -> Decimal
        
        console.log(`Battery Percentage after conversion: ${batteryPercentage}%`); // 🔍 Debugging Log
        
        if (isNaN(batteryPercentage)) {
          console.error(`Failed to convert battery value from Hex to Decimal: ${batteryHex}`);
          return;
        }
        
        // Final Log to Confirm Execution
        console.log(`🔋 Battery Percentage for ${side}: ${batteryPercentage}%`);
        
        // Dispatch Redux Action
        dispatch({
          type: actions.UPDATE_BATTERY_LEVEL,
          side,
          batteryPercentage,
        });
        
      } catch (error) {
        console.error(`Error reading battery level from ${side} device:`, error);
      }
    };
  };

 
  export const changeRicNoiseReduction = (value, side, deviceObj) => {
    return async (dispatch, getState) => {
      try {
        // Get the current mode from Redux state
        const state = getState();
        const mode = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.ric_mode : state.ricLeftFitting.ric_mode;
  
        // Convert mode to HEX format (01, 02, 03)
        const modeHex = (mode + 1).toString(16).padStart(2, "0").toUpperCase();

        let command = "";
  
        if (value === 0) {
          command = side === LISTENING_SIDE.LEFT ? `87 03 01 ${modeHex} 00` : `87 03 02 ${modeHex} 00`; // off Noise Reduction
        } else if (value === 1) {
          command = side === LISTENING_SIDE.LEFT ? `87 03 01 ${modeHex} 01` : `87 03 02 ${modeHex} 01`; // low-Level Noise Reduction
        } else if (value === 2) {
          command = side === LISTENING_SIDE.LEFT ? `87 03 01 ${modeHex} 02` : `87 03 02 ${modeHex} 02`; // mid-Level Noise Reduction
        }else if (value === 3) {
          command = side === LISTENING_SIDE.LEFT ? `87 03 01 ${modeHex} 03` : `87 03 02 ${modeHex} 03`; // High-Level Noise Reduction
        }
  
        if (command) {
          console.log(`Sending Noise Reduction command: ${command}`);
          await WriteRicDataToDevice(command, side, deviceObj);
  
          dispatch({
            type: actions.CHANGE_RIC_NOISE_REDUCTION,
            value,
            side,
          });
        } else {
          console.warn("Invalid noise reduction value:", value);
        }
      } catch (error) {
        console.error("Error changing noise reduction:", error);
      }
    };
  };
  

  export const changeRicMode = (mode, deviceSide, deviceObj) => {
    return async (dispatch) => {
      try {
        let command = "";
  
        if (deviceSide === LISTENING_SIDE.RIGHT) {
          if (mode === 0) command = "85 03 02 00 01"; // Quiet Mode
          else if (mode === 1) command = "85 03 02 00 02"; // Noise Mode
          else if (mode === 2) command = "85 03 02 00 03"; // Outdoor Mode
        } else if (deviceSide === LISTENING_SIDE.LEFT) {
          if (mode === 0) command = "85 03 01 00 01"; // Quiet Mode
          else if (mode === 1) command = "85 03 01 00 02"; // Noise Mode
          else if (mode === 2) command = "85 03 01 00 03"; // Outdoor Mode
        }
  
        if (!command) {
          console.warn("Invalid mode selection:", mode);
          return;
        }
  
        console.log(`Setting mode ${mode} for ${deviceSide} with HEX command:`, command);
  
        if (deviceObj) {
          await WriteRicDataToDevice(command, deviceSide, deviceObj);
        }
  
        dispatch({
          type: deviceSide === LISTENING_SIDE.RIGHT ? actions.CHANGE_RIC_MODE_RIGHT : actions.CHANGE_RIC_MODE_LEFT,
          mode,
        });
      } catch (error) {
        console.error("Error changing RIC mode:", error);
      }
    };
  };
  export const toggleMute = (isMuted, deviceSide, deviceObj) => {
    return async (dispatch) => {
      try {
        let command = "";
  
        if (deviceSide === LISTENING_SIDE.RIGHT) {
          command = isMuted ? "83 03 02 00 20" : "83 03 02 00 05";
        } else if (deviceSide === LISTENING_SIDE.LEFT) {
          command = isMuted ? "83 03 01 00 20" : "83 03 01 00 05";
        }
  
        if (!command) {
          console.warn("Invalid mute/unmute command");
          return;
        }
  
        console.log(`Sending ${isMuted ? "mute" : "unmute"} command to ${deviceSide} with HEX command:`, command);
  
        if (deviceObj) {
          await WriteRicDataToDevice(command, deviceSide, deviceObj);
        }
  
        dispatch({
          type: isMuted ? actions.MUTE_DEVICE : actions.UNMUTE_DEVICE,
          deviceSide,
        });
      } catch (error) {
        console.error("Error toggling mute/unmute:", error);
      }
    };
  };

  export const readMode = (side, deviceObj) => {
    return async (dispatch) => {
      try {
        const command = side === LISTENING_SIDE.RIGHT ? "84 02 02 00" : "84 02 01 00";
        const response = await ReadRicDataFromDevice(command, side, deviceObj);
  
        if (!response || !response.startsWith("84 03")) {
          console.warn(`Invalid mode response: ${response}`);
          return;
        }
  
        const data = response.split(" ");
        const d1 = parseInt(data[4], 16); 
  
        // Correct mapping
        let mode;
        if (d1 === 1) mode = 0; // Mode 0 (Quiet Mode)
        else if (d1 === 2) mode = 1; // Mode 1 (Noise Mode)
        else if (d1 === 3) mode = 2; // Mode 2 (Outdoor Mode)
        else {
          console.error(`Unexpected mode value: ${d1}`);
          return;
        }
  
        console.log(`Mapped mode for ${side}: ${mode}`);
  
        dispatch({
          type: side === LISTENING_SIDE.RIGHT ? actions.CHANGE_RIC_MODE_RIGHT : actions.CHANGE_RIC_MODE_LEFT,
          mode,
        });
  
      } catch (error) {
        console.error("Error reading mode:", error);
      }
    };
  };
  export const readNoiseReduction = (side, deviceObj) => {
    return async (dispatch, getState) => {
      try {
        const state = getState();
        const mode = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.ric_mode : state.ricLeftFitting.ric_mode;
  
        // Convert mode to HEX format (01, 02, 03)
        const modeHex = (mode + 1).toString(16).padStart(2, "0").toUpperCase();
        const command = side === LISTENING_SIDE.RIGHT ? `86 02 02 ${modeHex}` : `86 02 01 ${modeHex};`
        
        console.log`(Sending Read Command to ${side} device:, command)`;
  
        const response = await ReadRicDataFromDevice(command, side, deviceObj);
  
        if (response && response.startsWith("86 03")) {
          const responseParts = response.split(" ");
          const responseSide = responseParts[2] === "01" ? LISTENING_SIDE.LEFT : LISTENING_SIDE.RIGHT;
          const noiseReductionLevel = parseInt(responseParts[4], 16);
  
          console.log`(Received Noise Reduction Level for ${responseSide}:, noiseReductionLevel)`;
  
          dispatch({
            type: actions.CHANGE_RIC_NOISE_REDUCTION,
            side: responseSide,
            value: noiseReductionLevel,
          });
        } else {
          console.error`(Invalid response from ${side} device:, response)`;
        }
      } catch (error) {
        console.error`(Error reading noise reduction level from ${side} device:, error)`;
      }
    };
  };
  
  export const setEqualizer = (side, eqValues, deviceObj) => {
    return async (dispatch, getState) => {
      const state = getState();
      const mode = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.ric_mode : state.ricRightFitting.ric_mode;
      try {
        // Convert mode to HEX format (01, 02, 03)
        const modeHex = (mode + 1).toString(16).padStart(2, "0").toUpperCase();
  
        // Convert EQ values to HEX format
        const eqHexValues = eqValues?.map(value => {
          let hexValue;
          switch (value) {
            case 0:
              hexValue = "00";
              break;
            case -3:
              hexValue = "03";
              break;
            case -6:
              hexValue = "06";
              break;
            case -9:
              hexValue = "09";
              break;
            case -12:
              hexValue = "0C";
              break;
            case -15:
              hexValue = "0F";
              break;
            case -18:
              hexValue = "12";
              break;
            case -21:
              hexValue = "15";
              break;
            default:
              hexValue = "00"; 
          }
          return hexValue;
        });
  
        const command = side === LISTENING_SIDE.LEFT 
          ? `8B 0A 01 ${modeHex} ${eqHexValues.join(' ')}`
          : `8B 0A 02 ${modeHex} ${eqHexValues.join(' ')}`;
  
        console.log(`Sending EQ command: ${command}`);
        await WriteRicDataToDevice(command, side, deviceObj);
  
        dispatch({
          type: actions.SET_EQUALIZER,
          side,
          eqValues,
        });
      } catch (error) {
        console.error("Error setting equalizer:", error);
      }
    };
  };
   
  export const readEqualizer = (side, deviceObj) => {
    return async (dispatch, getState) => {
      try {
        const state = getState();
        const mode = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.ric_mode : state.ricRightFitting.ric_mode;
  
        // Convert mode to HEX format (01, 02, 03)
        const modeHex = (mode + 1).toString(16).padStart(2, "0").toUpperCase();
        const command = side === LISTENING_SIDE.RIGHT ? `8A 02 02 ${modeHex}` : `8A 02 01 ${modeHex}`;
  
        console.log(`Sending Read EQ Command to ${side} device:`, `${command}`);
  
        const response = await ReadRicDataFromDevice(command, side, deviceObj);
  
        if (response && response.startsWith("8a 0a")) {
          const responseParts = response.split(" ");
          const responseSide = responseParts[2] === "01" ? LISTENING_SIDE.LEFT : LISTENING_SIDE.RIGHT;
          const responseMode = parseInt(responseParts[3], 16);
          console.log(`response part ${side}:`, responseParts);
  
          // Validate the response side and mode
          if (responseSide === side && responseMode === mode + 1) {
            const eqValues = responseParts.slice(4, 12).map(value => {
              switch (value) {
                case "00":
                  return 0;
                case "03":
                  return -3;
                case "06":
                  return -6;
                case "09":
                  return -9;
                case "0c":
                  return -12;
                case "0f":
                  return -15;
                case "12":
                  return -18;
                case "15":
                  return -21;
                default:
                  return 0;
              }
            });
  
            console.log(`Received EQ Values for ${side}:`, eqValues);
  
            dispatch({
              type: actions.SET_EQUALIZER,
              side,
              eqValues,
            });
          } else {
            console.error(`Invalid response side or mode from ${side} device:`, response);
          }
        } else {
          console.error(`Invalid response from ${side} device:`, response);
        }
      } catch (error) {
        console.error(`Error reading EQ values from ${side} device:`, error);
      }
    };
  };

  export const setMPO = (side, mpoValue, deviceObj) => {
    return async (dispatch) => {
      try {
        // Map MPO values to HEX format (same as EQ values)
        let mpoHex;
        switch (mpoValue) {
          case 0:
            mpoHex = "00";
            break;
          case -3:
            mpoHex = "03";
            break;
          case -6:
            mpoHex = "06";
            break;
          case -9:
            mpoHex = "09";
            break;
          case -12:
            mpoHex = "0c";
            break;
          case -15:
            mpoHex = "0f";
            break;
          case -18:
            mpoHex = "12";
            break;
          case -21:
            mpoHex = "15";
            break;
          default:
            mpoHex = "00"; // Default to 0 if no match
        }
  
        const command = side === LISTENING_SIDE.LEFT 
          ? `8F 02 01 00 ${mpoHex}`
          : `8F 02 02 00 ${mpoHex}`;
  
        console.log(`Sending MPO command: ${command}`);
        await WriteRicDataToDevice(command, side, deviceObj);
  
        dispatch({
          type: actions.SET_MPO,
          side,
          mpoValue,
        });
      } catch (error) {
        console.error("Error setting MPO:", error);
      }
    };
  };
  

  export const readMPO = (side, deviceObj) => {
    return async (dispatch) => {
      try {
        const command = side === LISTENING_SIDE.LEFT 
          ? `8E 02 01 00`
          : `8E 02 02 00`;
  
        console.log(`Sending Read MPO Command to ${side} device:`, command);
        const response = await ReadRicDataFromDevice(command, side, deviceObj);
  
        if (response && response.startsWith("8e 03")) {
          const responseParts = response.split(" ");
          const mpoHexValue = responseParts[4]; // Extract the MPO HEX value
  
          // Map the HEX value back to the corresponding dB value
          let mpoValue;
          switch (mpoHexValue) {
            case "00":
              mpoValue = 0;
              break;
            case "03":
              mpoValue = -3;
              break;
            case "06":
              mpoValue = -6;
              break;
            case "09":
              mpoValue = -9;
              break;
            case "0c":
              mpoValue = -12;
              break;
            case "0f":
              mpoValue = -15;
              break;
            case "12":
              mpoValue = -18;
              break;
            case "15":
              mpoValue = -21;
              break;
            default:
              mpoValue = 0; // Default to 0 if no match
          }
  
          console.log(`Received MPO Value for ${side}:`, mpoValue);
  
          dispatch({
            type: actions.SET_MPO,
            side,
            mpoValue,
          });
        } else {
          console.error(`Invalid response from ${side} device:`, response);
        }
      } catch (error) {
        console.error(`Error reading MPO values from ${side} device:`, error);
      }
    };
  };

  export const changeFeedbackReduction = (value, side, deviceObj) => {
    return async (dispatch, getState) => {
      const state = getState();
      const mode = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.ric_mode : state.ricLeftFitting.ric_mode;
      const modeHex = (mode + 1).toString(16).padStart(2, "0").toUpperCase();
      let command = "";
      let eqCommand = "";
  
      if (value === 0) {
        command = side === LISTENING_SIDE.LEFT ? `89 03 01 ${modeHex} 00` : `89 03 02 ${modeHex} 00`;
        eqCommand = side === LISTENING_SIDE.LEFT ? `8B 0A 01 00 0F 00 00 00 00 00 00 0F` : `8B 0A 02 00 0F 00 00 00 00 00 00 0F`;
      } else if (value === 1) {
        command = side === LISTENING_SIDE.LEFT ? `89 03 01 ${modeHex} 01` : `89 03 02 ${modeHex} 01`;
        eqCommand = side === LISTENING_SIDE.LEFT ? `8B 0A 01 00 0F 00 00 00 00 00 00 0F` : `8B 0A 02 00 0F 00 00 00 00 00 00 0F`;
      } else if (value === 2) {
        command = side === LISTENING_SIDE.LEFT ? `89 03 01 ${modeHex} 01` : `89 03 02 ${modeHex} 01`;
        eqCommand = side === LISTENING_SIDE.LEFT ? `8B 0A 01 00 0F 00 00 00 03 06 06 1E` : `8B 0A 02 00 0F 00 00 00 03 06 06 1E`;
      }
  
      if (command && eqCommand) {
        await WriteRicDataToDevice(command, side, deviceObj);
        await WriteRicDataToDevice(eqCommand, side, deviceObj);
        dispatch({
          type: actions.CHANGE_FEEDBACK_CANCELLATION,
          value,
          side,
        });
      } else {
        console.warn("Invalid feedback reduction value:", value);
      }
    };
  };

  export const readFeedbackReduction = (side, deviceObj) => {
    return async (dispatch, getState) => {
      try {
        const state = getState();
        const mode = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.ric_mode : state.ricLeftFitting.ric_mode;
  
        // Convert mode to HEX format (01, 02, 03)
        const modeHex = (mode + 1).toString(16).padStart(2, "0").toUpperCase();
        const feedbackCommand = side === LISTENING_SIDE.RIGHT ? `88 02 02 ${modeHex}` : `88 02 01 ${modeHex}`;
        const eqCommand = side === LISTENING_SIDE.RIGHT ? `8A 02 02 00` : `8A 02 01 00`;
  
        console.log(`Sending Read Feedback Command to ${side} device:`, feedbackCommand);
        const feedbackResponse = await ReadRicDataFromDevice(feedbackCommand, side, deviceObj);
  
        console.log(`Sending Read master EQ Command to ${side} device:`, eqCommand);
        const eqResponse = await ReadRicDataFromDevice(eqCommand, side, deviceObj);
  
        if (feedbackResponse && feedbackResponse.startsWith("88 03")) {
          const feedbackParts = feedbackResponse.split(" ");
          const feedbackSide = feedbackParts[2] === "01" ? LISTENING_SIDE.LEFT : LISTENING_SIDE.RIGHT;
          const feedbackLevel = parseInt(feedbackParts[4], 16);
  
          console.log(`Received Feedback Level for ${feedbackSide}:`, feedbackLevel);
  
          if (eqResponse && eqResponse.startsWith("8a 0a")) {
            const eqParts = eqResponse.split(" ");
            const eqSide = eqParts[2] === "01" ? LISTENING_SIDE.LEFT : LISTENING_SIDE.RIGHT;
            const eqMode = eqParts[3];
            const eqLastValue = eqParts[11];
  
            console.log(`Received master EQ Values for ${eqSide}:`, eqParts);
  
            let feedbackMode;
            if (feedbackLevel === 0 && eqMode === "00" && eqLastValue === "0f") {
              feedbackMode = 0;
            } else if (eqMode === "00" && eqLastValue === "0f") {
              feedbackMode = 1;
            } else if (eqMode === "00" && eqLastValue === "1e") {
              feedbackMode = 2;
            } else {
              feedbackMode = "unknown";
            }
  
            dispatch({
              type: actions.CHANGE_FEEDBACK_CANCELLATION,
              side: feedbackSide,
              value: feedbackMode,
              feedbackMode,
            });
          } else {
            console.error(`Invalid master EQ response from ${side} device:`, eqResponse);
          }
        } else {
          console.error(`Invalid feedback response from ${side} device:`, feedbackResponse);
        }
      } catch (error) {
        console.error(`Error reading feedback reduction from ${side} device:`, error);
      }
    };
  };
  export const changeSelfSpeechReduction = (value, side, deviceObj) => {
    return async (dispatch, getState) => {
      const state = getState();
      const eqValues = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.eqValues : state.ricLeftFitting.eqValues;
      const currentSelfSpeechReduction = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.self_speech_reduction : state.ricLeftFitting.self_speech_reduction;
      const stepChange = (value - currentSelfSpeechReduction) * 3;
      const newEqValues = [...eqValues];
      newEqValues[0] = Math.max(-21, Math.min(0, newEqValues[0] - stepChange));
      newEqValues[1] = Math.max(-21, Math.min(0, newEqValues[1] - stepChange));
  
      dispatch(setEqualizer(side, newEqValues, deviceObj));
      dispatch({
        type: actions.CHANGE_SELF_SPEECH_REDUCTION,
        value,
        side,
      });
    };
  };
  
  export const changeSharpnessReduction = (value, side, deviceObj) => {
    return async (dispatch, getState) => {
      const state = getState();
      const eqValues = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.eqValues : state.ricLeftFitting.eqValues;
      const currentSharpness = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.ric_sharpness_reduction : state.ricLeftFitting.ric_sharpness_reduction;
      const stepChange = (value - currentSharpness) * 3;
      const newEqValues = [...eqValues];
      newEqValues[5] = Math.max(-21, Math.min(0, newEqValues[5] - stepChange));
      newEqValues[6] = Math.max(-21, Math.min(0, newEqValues[6] - stepChange));
      newEqValues[7] = Math.max(-21, Math.min(0, newEqValues[7] - stepChange));
  
      dispatch(setEqualizer(side, newEqValues, deviceObj));
      dispatch({
        type: actions.CHANGE_RIC_SHARPNESS,
        value,
        side,
      });
    };
  };
  
  export const changeRicMasterGain = (value, side, deviceObj) => {
    return async (dispatch, getState) => {
      const state = getState();
      const eqValues = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.eqValues : state.ricLeftFitting.eqValues;
      const currentMasterGain = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.ric_master_gain : state.ricLeftFitting.ric_master_gain;
      const stepChange = (value - currentMasterGain) * 3;
      const newEqValues = eqValues.map(eqValue => Math.max(-21, Math.min(0, eqValue + stepChange)));
  
      await dispatch(setEqualizer(side, newEqValues, deviceObj));
      dispatch({
        type: actions.CHANGE_RIC_MASTER_GAIN,
        value,
        side,
      });
    };
  };

export const disconnectRicDevice = (side) => {
  return async (dispatch, getState) => {
    const state = getState();
    const fitting = side === LISTENING_SIDE.LEFT ? state.ricLeftFitting : state.ricRightFitting;
    if (fitting.deviceObj?.gatt?.connected) {
      fitting.deviceObj.gatt.disconnect();
    }
    dispatch(callSnackBar("Device Disconnected.", SNACK_BAR_VARIETNS.error));
    dispatch({
      type: actions.DISCONNECT_DEVICE,
      side: side,
      deviceObj: fitting.deviceObj,
    });
  };
};

// export const updateModeValues = (side, data, modeIndex) => {
//   return (dispatch) => {
//     dispatch({
//       type: actions.UPDATE_MODE_VALUES,
//       side,
//       data,
//       modeIndex,
//     });
//   };
// };

export const changeProfileId = (value, side) => {
  return { type: actions.CHANGE_PROFILE_ID, value, side };
};

export const setRicFittingData = (data, read_only, side) => {
  return (dispatch, getState) => {

    dispatch({
      type: actions.SET_RIC_FITTING_DATA,
      payload: data,
      read_only,
      side: side,
      updating: true
      
    });

    console.log("🔄 RIC fitting data updated in reducer for", side);

    const state = getState();
    const deviceObj = side === LISTENING_SIDE.LEFT ? state.ricLeftFitting.deviceObj : state.ricRightFitting.deviceObj;
    const currentMode = side === LISTENING_SIDE.LEFT ? state.ricLeftFitting.ric_mode : state.ricRightFitting.ric_mode;
console.log("📌 Current Mode:", currentMode);

    // Find the correct mode data (default to mode 0 if not found)
    const selectedModeData = data.mode_data.find((mode) => mode.mode_type === currentMode) || data.mode_data[0];

    console.log("📌 Selected Mode Data:", selectedModeData);
    console.log("data.mode_data", data.mode_data);

    if (deviceObj) {
      dispatch(changeRicMode(selectedModeData.mode_type, side, deviceObj));
      dispatch(changeProfileId(data._id, side));
      dispatch(changeRicNoiseReduction(selectedModeData.noise_reduction, side, deviceObj));
      dispatch(changeFeedbackReduction(selectedModeData.feedback_cancellation, side, deviceObj));

      console.log("✅ Data successfully written to", side, "device.");
    } else {
      console.warn("⚠️ Device object is missing. Cannot write data to", side, "device.");
    }
  };
};





export const applyForRemoteFitting = (side) => {
  return {
    type: actions.REMOTE_FITTING,
    remote: true,
    deviceInfo: { name: "Remote", id: "remote" },
    disconnectFun: () => {},
    side: side,
  };
};

export const updateDeviceColor = (side, color) => {
  return {
    type: actions.UPDATE_DEVICE_COLOR,
    side,
    color,
  };
};


export const ricAutoFitDevice = (id, side, deviceObj, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {
    dispatch(
      callApiAction(
        async () => await getPatientByIdApi({ id, no_profile: true }),
        async (response) => {
          if (response) {
            const isLeft = side === LISTENING_SIDE.LEFT;
            const lf_rf_values = isLeft
              ? { f2: response.lf2, f3: response.lf3, f4: response.lf4, f5: response.lf5, f6: response.lf6, f8: response.lf8 }
              : { f2: response.rf2, f3: response.rf3, f4: response.rf4, f5: response.rf5, f6: response.rf6, f8: response.rf8 };

            // Extract the HEX values from AUTO_FIT_DATA
            const eqHexValues = [
              AUTO_FIT_DATA.find(item => item.threshold === lf_rf_values.f2)?.values[0] || "00",  // 250 Hz
              AUTO_FIT_DATA.find(item => item.threshold === lf_rf_values.f3)?.values[1] || "00",  // 500 Hz
              AUTO_FIT_DATA.find(item => item.threshold === lf_rf_values.f4)?.values[2] || "00",  // 1000 Hz
              AUTO_FIT_DATA.find(item => item.threshold === lf_rf_values.f5)?.values[3] || "00",  // 2000 Hz
              AUTO_FIT_DATA.find(item => item.threshold === lf_rf_values.f5)?.values[4] || "00",  // 3000 Hz
              AUTO_FIT_DATA.find(item => item.threshold === lf_rf_values.f6)?.values[5] || "00",  // 4000 Hz
              AUTO_FIT_DATA.find(item => item.threshold === lf_rf_values.f6)?.values[6] || "00",  // 6000 Hz
              AUTO_FIT_DATA.find(item => item.threshold === lf_rf_values.f8)?.values[7] || "00"   // 8000 Hz
            ];

            console.log("Extracted HEX EQ Values:", eqHexValues);

            // Convert HEX to Decimal
            const eqValues = eqHexValues.map(hex => parseInt(hex, 16) * -1 || 0);
            console.log("Converted Decimal EQ Values:", eqValues);

            // Call setEqualizer with computed decimal eqValues
            dispatch(setEqualizer(side, eqValues, deviceObj));
            onSuccess();
          } else {
            onError("No Audiogram found");
          }
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};

export const setVolumeLevel = (side, value, deviceObj) => {
  return async (dispatch) => {
    try {
      console.log(`🚀 Dispatching setVolumeLevel | Side: ${side} | Original Value: ${value}`);

      if (value === undefined || value < 0 || value > 20) {
        console.warn("🚨 Invalid volume level received:", value);
        return;
      }

      // 🔥 Reverse the volume scale (00 → 20, 01 → 19, ..., 20 → 00)
      const reversedValue = 20 - value;

      // Construct the command with the reversed value
      const command =
        side === LISTENING_SIDE.LEFT
          ? `83 03 01 00 ${reversedValue.toString().padStart(2, "0")}`
          : `83 03 02 00 ${reversedValue.toString().padStart(2, "0")}`;

      console.log(`📡 Sending Corrected Volume Command: ${command}`);

      await WriteRicDataToDevice(command, side, deviceObj);

      dispatch({
        type: actions.SET_VOLUME_LEVEL,
        side,
        value, // Keep the actual slider value in Redux
      });
    } catch (error) {
      console.error("❌ Error setting volume level:", error);
    }
  };
};



export const readVolumeLevel = (side, deviceObj) => {
  return async (dispatch) => {
    try {
      const command = side === LISTENING_SIDE.LEFT 
        ? `82 02 01 00`
        : `82 02 02 00`;

      console.log(`Sending Read Volume Command to ${side} device:`, command);
      const response = await ReadRicDataFromDevice(command, side, deviceObj);

      if (response && response.startsWith("82 03")) {
        const responseParts = response.split(" ");
        const volumeHex = responseParts[4];
        const volumeLevel = parseInt(volumeHex, 16);

        console.log(`Received Volume Level for ${side}:`, volumeLevel);

        dispatch({
          type: actions.SET_VOLUME_LEVEL,
          side,
          volumeLevel,
        });
      } else {
        console.error(`Invalid response from ${side} device:`, response);
      }
    } catch (error) {
      console.error(`Error reading volume level from ${side} device:`, error);
    }
  };
};