
  
import {
  Box,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { Battery0Bar, BatteryChargingFull } from "@mui/icons-material";
import { DEVICE_COLORS, DEVICES, LISTENING_SIDE } from "../../../utils/patient.constants";
import RightEar from "../../../assets/images/Left (1).png";
import LeftEar from "../../../assets/images/Left (2).png";
import METALIC_LEFT_RIC_OPTIMA from "../../../assets/images/metalic_left_ric.svg";
import METALIC_RIGHT_RIC_OPTIMA from "../../../assets/images/metalic_right_ric.svg";
import BLACK_LEFT_RIC_OPTIMA from "../../../assets/images/black_left_ric.svg";
import BLACK_RIGHT_RIC_OPTIMA from "../../../assets/images/black_right_ric.svg";
import BEIGE_LEFT_RIC_OPTIMA from "../../../assets/images/beige_left_ric.svg";
import BEIGE_RIGHT_RIC_OPTIMA from "../../../assets/images/beige_right_ric.svg";
import { Chart } from "chart.js";
import { readBatteryLevel } from "../../../store/actions/ricFittingActions";


const BorderSquareChip = styled(Typography)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: "1px solid " + theme.palette.success.main,
  color: theme.palette.success.main,
  padding: theme.spacing(2),
  background: theme.palette.success.light,
}));

const predefinedValues = {
  "250Hz": {
    0: { "50dB": 80, "65dB": 90, "80dB": 99 },
    "-3": { "50dB": 76, "65dB": 87, "80dB": 94 },
    "-6": { "50dB": 73, "65dB": 84, "80dB": 92 },
    "-9": { "50dB": 69, "65dB": 80, "80dB": 88 },
    "-12": { "50dB": 67, "65dB": 77, "80dB": 85 },
    "-15": { "50dB": 63, "65dB": 74, "80dB": 82 },
    "-18": { "50dB": 61, "65dB": 72, "80dB": 80 },
    "-21": { "50dB": 58, "65dB": 69, "80dB": 77 },
  },
  "500Hz": {
    0: { "50dB": 87, "65dB": 97, "80dB": 105 },
    "-3": { "50dB": 82, "65dB": 93, "80dB": 101 },
    "-6": { "50dB": 79, "65dB": 90, "80dB": 97 },
    "-9": { "50dB": 76, "65dB": 85, "80dB": 93 },
    "-12": { "50dB": 72, "65dB": 83, "80dB": 90 },
    "-15": { "50dB": 68, "65dB": 78, "80dB": 86 },
    "-18": { "50dB": 65, "65dB": 75, "80dB": 83 },
    "-21": { "50dB": 61, "65dB": 71, "80dB": 79 },
  },
  "1000Hz": {
    0: { "50dB": 86, "65dB": 95, "80dB": 103 },
    "-3": { "50dB": 82, "65dB": 91, "80dB": 99 },
    "-6": { "50dB": 79, "65dB": 88, "80dB": 96 },
    "-9": { "50dB": 75, "65dB": 84, "80dB": 91 },
    "-12": { "50dB": 72, "65dB": 81, "80dB": 89 },
    "-15": { "50dB": 68, "65dB": 77, "80dB": 85 },
    "-18": { "50dB": 65, "65dB": 75, "80dB": 82 },
    "-21": { "50dB": 60, "65dB": 70, "80dB": 78 },
  },
  "2000Hz": {
    0: { "50dB": 84, "65dB": 95, "80dB": 103 },
    "-3": { "50dB": 80, "65dB": 91, "80dB": 99 },
    "-6": { "50dB": 77, "65dB": 88, "80dB": 96 },
    "-9": { "50dB": 73, "65dB": 85, "80dB": 92 },
    "-12": { "50dB": 70, "65dB": 81, "80dB": 89 },
    "-15": { "50dB": 66, "65dB": 78, "80dB": 85 },
    "-18": { "50dB": 63, "65dB": 75, "80dB": 82 },
    "-21": { "50dB": 59, "65dB": 71, "80dB": 78 },
  },
  "3000Hz": {
    0: { "50dB": 89, "65dB": 100, "80dB": 107 },
    "-3": { "50dB": 85, "65dB": 96, "80dB": 103 },
    "-6": { "50dB": 82, "65dB": 93, "80dB": 100 },
    "-9": { "50dB": 78, "65dB": 89, "80dB": 96 },
    "-12": { "50dB": 75, "65dB": 86, "80dB": 98 },
    "-15": { "50dB": 71, "65dB": 82, "80dB": 89 },
    "-18": { "50dB": 68, "65dB": 79, "80dB": 86 },
    "-21": { "50dB": 64, "65dB": 75, "80dB": 82 },
  },
  "4000Hz": {
    0: { "50dB": 79, "65dB": 92, "80dB": 96 },
    "-3": { "50dB": 76, "65dB": 88, "80dB": 95 },
    "-6": { "50dB": 74, "65dB": 85, "80dB": 93 },
    "-9": { "50dB": 70, "65dB": 82, "80dB": 89 },
    "-12": { "50dB": 68, "65dB": 80, "80dB": 87 },
    "-15": { "50dB": 69, "65dB": 76, "80dB": 83 },
    "-18": { "50dB": 71, "65dB": 74, "80dB": 80 },
    "-21": { "50dB": 68, "65dB": 71, "80dB": 78 },
  },
  "6000Hz": {
    0: { "50dB": 75, "65dB": 88, "80dB": 90 },
    "-3": { "50dB": 72, "65dB": 85, "80dB": 89 },
    "-6": { "50dB": 69, "65dB": 82, "80dB": 89 },
    "-9": { "50dB": 65, "65dB": 78, "80dB": 85 },
    "-12": { "50dB": 62, "65dB": 75, "80dB": 83 },
    "-15": { "50dB": 58, "65dB": 71, "80dB": 79 },
    "-18": { "50dB": 55, "65dB": 69, "80dB": 76 },
    "-21": { "50dB": 52, "65dB": 64, "80dB": 72 },
  },
  "8000Hz": {
    0: { "50dB": 27, "65dB": 40, "80dB": 43 },
    "-3": { "50dB": 27, "65dB": 40, "80dB": 43 },
    "-6": { "50dB": 27, "65dB": 40, "80dB": 43 },
    "-9": { "50dB": 27, "65dB": 40, "80dB": 43 },
    "-12": { "50dB": 27, "65dB": 40, "80dB": 43 },
    "-15": { "50dB": 27, "65dB": 40, "80dB": 43 },
    "-18": { "50dB": 27, "65dB": 40, "80dB": 43 },
    "-21": { "50dB": 27, "65dB": 40, "80dB": 43 },
  },
};

const createData = (eqValues, dbLevel) => {
  if (!eqValues || eqValues.length === 0) {
    return [];
  }

  const frequencies = ["250Hz", "500Hz", "1000Hz", "2000Hz", "3000Hz", "4000Hz", "6000Hz", "8000Hz"];
  return frequencies.map((freq, index) => {
    const eqValue = eqValues[index];
    const predefined = predefinedValues[freq][eqValue];
    return predefined[dbLevel];
  });
};

const ChartComponent = ({ edgeVal, fitting }) => {
  const labels = useMemo(() => {
    if (fitting.device_type == DEVICES.RIC_OPTIMA)
      return ["250", "500", "1k", "2k", "3k", "4k", "6k", "8k"];
    else return ["250", "500", "1k", "2k", "3k", "4k", "6k", "8k"];
  }, [fitting.device_type]);

  const titleInsideChartPlugin = {
    id: "titleInsideChart",
    beforeDraw: (chart) => {
      const { ctx, chartArea } = chart;
      const { width, top } = chartArea;
  
      ctx.save();
      ctx.font = "bold 12px Arial";
      ctx.fillStyle = "#333";
      ctx.textAlign = "center";
      ctx.fillText("2cc Output (dB SPL)", width / 2, top + 20); // ✅ Title inside the chart
      ctx.restore();
    },
  };

  const dataFields = useMemo(
    () => ({
      labels,
      datasets: fitting.connected
      ? [
          {
            label: "Pink Area Upper Bound",
            data: [
              140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140,
              140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140, 140,
              140, 140, 140, 140, 140, 140, 140,
            ],
            borderColor: "rgba(0, 0, 0, 0)",
            backgroundColor:
              fitting.device_side == LISTENING_SIDE.RIGHT
                ? "rgba(238, 210, 210, 0.5 )"
                : "rgba(229, 238, 244, 0.5)",
            // opacity:0.2,
            pointRadius: 0,
            fill: "+1",
          },
          {
            label: "Pink Area Lower Bound",
            data: [
  106, 106, 104, 104, 109, 103, 104, 100, 
  106, 106, 104, 104, 109, 103, 104, 100, 
  106, 106, 104, 104, 109, 103, 104, 100, 
  106, 106, 104, 104, 109, 103, 104, 100,
  106, 106
],
            borderColor:
              fitting.device_side == LISTENING_SIDE.RIGHT
                ? "#C24747"
                : "#1265A1",
            backgroundColor: "rgba(200, 200, 200, 0)",
            opacity: 0.2,
            pointRadius: 0,
            fill: "origin",
          },
            {
              label: "50dB",
              data: createData(fitting.eqValues, "50dB"),
              pointRadius: 0,
              borderColor: "#4A4A4A",
            },
            {
              label: "65dB",
              data: createData(fitting.eqValues, "65dB"),
              pointRadius: 0,
              borderColor: "#969696",
            },
            {
              label: "80dB",
              data: createData(fitting.eqValues, "80dB"),
              pointRadius: 0,
              borderColor: "#CCCCCC",
            },
          ]
        : [],
    }),
    [fitting.eqValues, fitting.device_type, fitting.connected]
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 10,
            color: "#666666",
          },
        },
      },
      y: {
        min: 20,
        max: 140,
        reverse: false,
        ticks: {
          callback: function (value) {
            return value;
          },
          stepSize: 20,
          autoSkip: false,
          font: {
            size: 10,
          },
        },
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          label: function (tooltipItem) {
            if (
              tooltipItem.dataset.label === "Pink Area Upper Bound" ||
              tooltipItem.dataset.label === "Pink Area Lower Bound"
            ) {
              return null; // ✅ Hide these labels
            }
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        }
      },
      datalabels: {
        display: false,
      },
     
    },
    elements: {
      line: {
        borderWidth: 1,
        tension: 0.4,
      },
    },
  };
  return (
    <Box
      sx={{
        minHeight: "35vh",
        height: "40vh",
      }}
    >
      <Line options={options} data={dataFields}  plugins={[titleInsideChartPlugin]} />
    </Box>
  );
};

const RicFittingChartArea = ({ edgeVal, fitting, mutedHandle }) => {
  const dispatch = useDispatch();
  const { ricLeftFitting, ricRightFitting } = useSelector((state) => state);
  useEffect(() => {
  dispatch(readBatteryLevel(LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj));;
  }, []);

  const LEFT_EAR_IMAGES = {
  [DEVICE_COLORS.METALIC]: METALIC_LEFT_RIC_OPTIMA,
  [DEVICE_COLORS.BLACK]: BLACK_LEFT_RIC_OPTIMA,
  [DEVICE_COLORS.BEIGE]: BEIGE_LEFT_RIC_OPTIMA,
};

const RIGHT_EAR_IMAGES = {
  [DEVICE_COLORS.METALIC]: METALIC_RIGHT_RIC_OPTIMA,
  [DEVICE_COLORS.BLACK]: BLACK_RIGHT_RIC_OPTIMA,
  [DEVICE_COLORS.BEIGE]: BEIGE_RIGHT_RIC_OPTIMA,
};

const getLeftEarImage = () => {
  return LEFT_EAR_IMAGES[ricLeftFitting?.device_color] || METALIC_LEFT_RIC_OPTIMA;
};

const getRightEarImage = () => {
  return RIGHT_EAR_IMAGES[ricRightFitting?.device_color] || METALIC_RIGHT_RIC_OPTIMA;
};

  const batteryLevel =
  fitting.device_side === LISTENING_SIDE.RIGHT
    ? ricRightFitting?.battery_level
    : ricLeftFitting?.battery_level;

  return (
    <Box p={1}>
      
      <Grid md={12}>
        {fitting.connected ? (
          <>
          <Grid display={"flex"} justifyContent="space-between" alignItems="center">

            <Box sx={{display:'flex', justifyContent:'flex-start'}}>
            <Box>
              <img
                src={
                  fitting.device_side === LISTENING_SIDE.RIGHT
                    ? getRightEarImage()
                    :  getLeftEarImage()
                }
                style={{ height: "4vh", marginLeft:'1vw' }}
              />
            </Box>
            <Box mt={1} ml={1}>
              <Typography
                variant="caption"
                sx={{
                  color: "#1A1A1A",
                  fontFamily: "League spartan",
                  fontWeight: "bold",
                  fontStyle: "normal",
                }}
              >
                {fitting?.device_type === DEVICES.BTE_OPTIMA
                  ? "BTE OPTIMA"
                  : fitting?.device_type === DEVICES.BTE_PRIME
                  ? "BTE PRIME"
                  : fitting?.device_type === DEVICES.RIC_OPTIMA
                  ? "RIC OPTIMA"
                  : ""}
              </Typography>
            </Box>
              {!(fitting.read_only || fitting.remote) &&  (
              <Box ml={0} display={"flex"}>
                <Typography
                  variant="caption"
                  sx={{
                    color: "#999",
                    fontFamily: "League spartan",
                    fontWeight: "bold",
                    fontStyle: "normal",
                  }}
                >
                  -
                </Typography>
              </Box>
            )}
              {!(fitting.read_only || fitting.remote) && (
              <Box mt={1} display={"flex"}>
                <Typography
                  variant="caption"
                  sx={{
                    color: "#39AC8A",
                    fontFamily: "League spartan",
                    fontWeight: "400",
                    fontStyle: "normal",
                  }}
                >
                  • Connected
                </Typography>
              </Box>
            )}
           
           
          </Box>
            {!(fitting.read_only || fitting.remote) && (   <Box display="flex" alignItems="center" mr={3}>
  <Box
    sx={{
      width: "25px",
      height: "12px",
      border: "2px solid #2D3B67",
      borderRadius: "3px",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "2px",
    }}
  >
    {/* Battery fill */}
    <Box
      sx={{
        width: `${Math.max(0, Math.min(100, batteryLevel))}%`,
        height: "100%",
        backgroundColor:
          batteryLevel > 69
            ? "green"
            : batteryLevel > 24
            ? "yellow"
            : "red",
        borderRadius: "2px",
        transition: "width 0.3s ease",
      }}
    />
  </Box>

  {/* Battery Cap */}
  <Box
    sx={{
      width: "4px",
      height: "6px",
      backgroundColor: "#2D3B67",
      borderRadius: "1px",
      marginLeft: "2px",
    }}
  />

  {/* Battery Percentage */}
  <Typography
    variant="caption"
    sx={{
      color: "#2D3B67",
      fontFamily: "League Spartan",
      fontWeight: "400",
      fontStyle: "normal",
      marginLeft: "6px",
    }}
  >
    {batteryLevel} %
  </Typography>
</Box> )}

          </Grid>
        
          </>
        ) : (
          <Typography mt={5}></Typography>
        )}
      </Grid>
      <Box>
        <ChartComponent fitting={fitting} edgeVal={edgeVal} />
      </Box>
    </Box>
  );
};

export default RicFittingChartArea;