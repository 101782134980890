import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import LeftImg from "../../assets/images/Left (1).png";
import RightImg from "../../assets/images/Left (2).png";
import { changeRemarks } from "../../store/actions/fittingAction";
import { DEVICES, LISTENING_SIDE } from "../../utils/patient.constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const CompleteSessionController = ({
  id,
  onCompleteSession,
  onCancel,
  isBackButton,
}) => {
  const { fitting, fittingLeft } = useSelector((state) => state);
  const navigate = useNavigate();
  const params = useParams();

  const dispatch = useDispatch();
  return (
    <CustomDialog
      id="complete"
      //   loading={loading}
      onSubmit={(e) => {
        onCompleteSession(e, fitting, fittingLeft);
      }}
      title={"Complete Session"}
      onClose={
        isBackButton
          ? () => {
              navigate(`/patient-details/${params.id}`);
            }
          : onCancel
      }
      closeText={isBackButton ? "No" : "Cancel"}
      confirmText={isBackButton ? "Yes" : "Save"}
    >
      <Box mt={3}>
        {isBackButton ? (
          <Typography color={"#4D4D4D"}>
            Do you want to save the device configuration?
          </Typography>
        ) : (
          <>
            <Typography color={"#4D4D4D"}>
              The device configuration will be saved as per current settings.
            </Typography>
            <Typography color={"#4D4D4D"}>
              Would you like to complete the session?
            </Typography>
          </>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {fitting?.connected && (
              <Box
                m={7}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img height={"100px"} src={RightImg} />
                <Typography variant="h6" sx={{ color: "#C24747" }}>
                  {fitting.device_type === DEVICES.RIC_OPTIMA
                    ? "RIC OPTIMA"
                    : fitting.device_type === DEVICES.BTE_OPTIMA
                    ? "BTE OPTIMA"
                    : "BTE PRIME"}
                </Typography>
                <Typography sx={{ color: "#808080" }}>
                  02567(Serial Number)
                </Typography>
              </Box>
            )}
            {fittingLeft?.connected && (
              <Box
                m={7}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img height={"100px"} src={LeftImg} />
                <Typography variant="h6" sx={{ color: "#1265A1" }}>
                  {fittingLeft.device_type === DEVICES.RIC_OPTIMA
                    ? "RIC OPTIMA"
                    : fittingLeft.device_type === DEVICES.BTE_OPTIMA
                    ? "BTE OPTIMA"
                    : "BTE PRIME"}
                </Typography>
                <Typography sx={{ color: "#808080" }}>
                  02567(Serial Number)
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Typography variant="h4" fontWeight={500} color="#808080">
            Remarks
          </Typography>
          <TextField
            multiline
            sx={{ color: "#808080" }}
            maxRows={2}
            variant="standard"
            fullWidth
            value={fitting.remarks}
            onChange={(e) =>
              dispatch(changeRemarks(e.target.value, LISTENING_SIDE.BOTH))
            }
          />
        </Box>
      </Box>
    </CustomDialog>
  );
};

export default CompleteSessionController;
