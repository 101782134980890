import { WDRC, actions } from "../../utils/constants";
import { accessToken, refreshToken, unMapValues } from "../../utils/helper";
import { GAIN_POINTS } from "../../utils/j10.helper";
import {
  DEVICES,
  LISTENING_SIDE,
  MANAGE_PROGRAM_LIST,
} from "../../utils/patient.constants";

const defaultData = [
  0, 0, 0, 28, 194, 10, 0, 50, 0, 3, 72, 78, 85, 66, 84, 69, 95, 82, 0, 0, 5, 6,
  8, 8, 8, 8, 7, 5, 52, 52, 52, 50, 50, 50, 52, 55, 22, 28, 30, 32, 30, 30, 28,
  26, 70, 70, 70, 70, 70, 70, 70, 70, 20, 20, 20, 20, 20, 20, 20, 20, 103, 105,
  110, 110, 110, 105, 103, 100, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -5, -5, -10,
  -7, 0, 0, -10, -20, 0, 0, 63, 255, 30, 0, 0, 0, 0, 0, 0, 0,
];

const btePrimedefaultData = [
  0, 0, 0, 28, 194, 10, 0, 50, 0, 3, 66, 84, 69, 32, 80, 114, 105, 109, 101, 32,
  5, 6, 7, 7, 37, 7, 7, 6, 55, 55, 48, 48, 48, 48, 48, 48, 22, 28, 30, 32, 30,
  30, 28, 26, 70, 70, 70, 70, 70, 70, 70, 70, 20, 20, 20, 20, 20, 20, 20, 20,
  103, 105, 110, 110, 110, 105, 103, 100, 0, 0, 0, 0, 36, 70, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 82, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1, 0, 0, 0, 2, 1, 0, 0, 0, 0, 0, 0,
];

const modeDafaultArray = [
  {
    data: defaultData,
    loudness_compression: 0,
    master_gain: 0,
    noise_reduction: 0,
    self_speech_reduction: 0,
    sharpness: 0,
    mode_type: MANAGE_PROGRAM_LIST.COMMON,
    wind_noise_reduction: 0,
  },
  null,
  null,
  null,
];

const btePrimeModeDafaultArray = [
  {
    data: btePrimedefaultData,
    loudness_compression: 0,
    master_gain: 0,
    noise_reduction: 0,
    self_speech_reduction: 0,
    sharpness: 0,
    mode_type: MANAGE_PROGRAM_LIST.COMMON,
    wind_noise_reduction: 0,
  },
  null,
  null,
  null,
];

const insituData = [
  0, 5, 0, 28, 199, 10, 0, 50, 0, 3, 72, 78, 85, 66, 84, 69, 95, 76, 0, 82, 5,
  5, 6, 6, 6, 6, 6, 6, 50, 50, 43, 43, 43, 43, 43, 43, 18, 18, 38, 46, 46, 46,
  46, 46, 70, 70, 70, 70, 70, 70, 70, 70, 20, 20, 20, 20, 20, 20, 20, 20, 103,
  105, 110, 110, 110, 105, 103, 100, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 63, 255, 30, 0, 20, 20, 20, 20, 20, 20,
];
const RightEarData = [
  0, 0, 0, 28, 194, 10, 0, 50, 0, 3, 72, 78, 85, 66, 84, 69, 95, 82, 0, 0, 5, 6,
  8, 8, 8, 8, 7, 5, 52, 52, 52, 50, 50, 50, 52, 55, 22, 28, 30, 32, 30, 30, 28,
  26, 70, 70, 70, 70, 70, 70, 70, 70, 20, 20, 20, 20, 20, 20, 20, 20, 103, 105,
  110, 110, 110, 105, 103, 100, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 5, 10, 7,
  0, 0, 10, 20, 0, 0, 63, 255, 30, 0, 0, 0, 0, 0, 0, 0,
];
const defaultGlassData = [
  0, 0, 0, 28, 194, 10, 0, 50, 0, 3, 74, 49, 48, 45, 50, 48, 50, 51, 45, 82, 5,
  5, 6, 6, 6, 6, 6, 6, 55, 55, 48, 48, 48, 48, 48, 48, 22, 28, 30, 32, 30, 30,
  28, 26, 70, 70, 70, 70, 70, 70, 70, 70, 20, 20, 20, 20, 20, 20, 20, 20, 103,
  105, 110, 110, 110, 105, 103, 100, 0, 0, 0, 0, 78, 30, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 63, 255, 0, 0, 0, 0, 0, 0, 0, 0,
];

// const inSitudB = [

//   undefined,
//   undefined,
//   undefined,
//   undefined,
//   undefined,
//   undefined,
//   undefined,
// ];
const initialState = {
  profile_id: null,
  connected: false,
  enabled: undefined,

  loadingMessage: "",
  loading: undefined,
  initial_data: [],
  hardwareData: [],
  data: defaultData,
  inSitudB: [],
  // inSitudBLeft: false,
  // inSitudBRight: false,
  FeedbackCancellation: false,
  WhisleBlock: false,
  WindNoiseBlock: false,
  inSituCount: 0,
  deviceInfo: {
    name: "",
    id: "",
  },
  deviceObj: {},
  writeFun: undefined,
  disconnectFun: undefined,
  device_type: DEVICES.BTE_OPTIMA,
  device_side: LISTENING_SIDE.RIGHT,
  mode_type: MANAGE_PROGRAM_LIST.COMMON,
  mode_data: [...modeDafaultArray],

  master_gain: 0,
  noise_reduction: 0,
  loudness_compression: 0,
  self_speech_reduction: 0,
  sharpness: 0,
  wind_noise_reduction: 0,
  remote: false,
  remarks: "",
  read_only: false,
  listening_side: LISTENING_SIDE.RIGHT,
};
const fittingReducer = (state = initialState, action) => {
  if (action.side == LISTENING_SIDE.LEFT) {
    return { ...state };
  }

  switch (action.type) {
    case actions.CHNAGE_DEVICE_SIDE_SELECTION:
      return {
        ...state,
        listening_side: action.value,
      };

    case actions.READ_ONLY_CHANGED:
      return {
        ...state,
        read_only: action.value,
      };

    case actions.CHANGE_DEVICE_SELECTION:
      let tempData = defaultData;
      let modeData = modeDafaultArray;
      if (action.value == DEVICES.BTE_PRIME) {
        tempData = btePrimedefaultData;
        modeData = btePrimeModeDafaultArray;
      }
      return {
        ...state,
        device_type: action.value,
        data: tempData,
        mode_data: modeData,
      };

    case actions.CONNECT_DEVICE:
      return {
        ...state,
        hardwareData: action.hardwareData,
        deviceInfo: action.deviceInfo,
        deviceObj: action.deviceObj,
        connected: true,
        read_only: false,
        disconnectFun: action.disconnectFun,
      };
    case actions.REMOTE_FITTING:
      return {
        ...state,
        remote: true,
        deviceInfo: action.deviceInfo,
        connected: true,
        read_only: false,
        disconnectFun: action.disconnectFun,
      };
    case actions.CHANGE_PROFILE_ID:
      return { ...state, profile_id: action.value };

    case actions.CHNAGE_LOADING_VALUES:
      return {
        ...state,
        loading: action.loading,
        loadingMessage: action.messages,
      };
    case actions.CHNAGE_DEVICE_COMPATIBLITY:
      return { ...state, enabled: action.value };
    case actions.CHANGE_WRITE_FUN:
      return { ...state, writeFun: action.value };

    case actions.DISCONNECT_DEVICE:
      return {
        ...initialState,
        enabled: state.enabled,
        data: state.data,
        remarks: state.remarks,
        device_type: state.device_type,
        device_side: state.device_side,
        read_only: false,
        connected: false,
        remote: false,
      };

    case actions.RESET_DEVICE:
      state.FeedbackCancellation = false;
      state.WhisleBlock = false;
      state.WindNoiseBlock = false;

      let prev = state.data[0];
      state.data =
        state.device_type === DEVICES.GLASSES
          ? defaultGlassData
          : state.device_type == DEVICES.BTE_OPTIMA
          ? defaultData
          : btePrimedefaultData;

      const temp = [
        ...(state.device_type === DEVICES.GLASSES
          ? defaultGlassData
          : state.device_type == DEVICES.BTE_OPTIMA
          ? defaultData
          : btePrimedefaultData),
      ];

      temp[0] = prev;

      return {
        ...state,
        data: temp,
        master_gain: 0,
        noise_reduction: 0,
        loudness_compression: 0,
        self_speech_reduction: 0,
        sharpness: 0,
        wind_noise_reduction: 0,
      };

    case actions.RESET_FITTING_REDUCER:
      const newData =
        state.device_type === DEVICES.GLASSES
          ? defaultGlassData
          : state.device_type === DEVICES.BTE_OPTIMA
          ? defaultData
          : btePrimedefaultData;

      let tempModeData = modeDafaultArray;
      if (state.device_type == DEVICES.BTE_PRIME) {
        tempModeData = btePrimeModeDafaultArray;
      }

      return {
        ...state,
        data: newData,
        master_gain: 0,
        noise_reduction: 0,
        loudness_compression: 0,
        self_speech_reduction: 0,
        sharpness: 0,
        wind_noise_reduction: 0,
        mode_data: [...tempModeData],
      };

    case actions.CHANGE_BYTE_ARRAY_VAL:
      return {
        ...state,
        hardwareData: action.val?.map((item) => Number(item)),
        data: action.val?.map((item) => Number(item)),
        inSitudB: action.val?.map((item) => Number(item)),
        master_gain: 0,
        noise_reduction: 0,
        loudness_compression: 0,
        self_speech_reduction: 0,
        sharpness: 0,
        wind_noise_reduction: 0,
      };
    case actions.CHANGE_BYTE_VAL: {
      console.log("this is index", action.byteIndex, "this is val", action.val);
      const temp = [...state.data];
      temp[action.byteIndex] = Math.round(action.val);
      return { ...state, data: temp };
    }

    case actions.CHANGE_MULTIPLE_BYTE_VAL: {
      const temp = [...state.data];
      for (let i = 0; i < action.byteIndex.length; i++)
        temp[action.byteIndex[i]] = action.val[i];
      return { ...state, data: temp };
    }

    case actions.SET_MODE_DATA: {
      const temp_mode_data = [...state.mode_data];
      temp_mode_data[action.data.data[0]] = action.data;
      return { ...state, mode_data: temp_mode_data };
    }

    case actions.CHECKED_VAL: {
      const temp = action.arr_res;
      if (temp[1] == 10) {
        state.FeedbackCancellation = true;
      } else {
        state.FeedbackCancellation = false;
      }

      if (temp[3] == 28) {
        state.WhisleBlock = true;
      } else {
        state.WhisleBlock = false;
      }

      return { ...state };
    }

    case actions.CHANGE_MODE: {
      console.log("this is reducer data", state);
      let tempdata = state.data;
      let data_object = action.data;
      let master_gain = state.master_gain;
      let noise_reduction = state.noise_reduction;
      let loudness_compression = state.loudness_compression;
      let self_speech_reduction = state.self_speech_reduction;
      let sharpness = state.sharpness;
      let wind_noise_reduction = state.wind_noise_reduction;
      let mode_type = state.mode_type;
      if (action.data) {
        tempdata = [...data_object.data];
        master_gain = data_object.master_gain;
        noise_reduction = data_object.noise_reduction;
        loudness_compression = data_object.loudness_compression;
        self_speech_reduction = data_object.self_speech_reduction;
        sharpness = data_object.sharpness;
        wind_noise_reduction = data_object.wind_noise_reduction;
        mode_type = data_object.mode_type;
      }

      return {
        ...state,
        data: tempdata,
        master_gain: master_gain,
        noise_reduction: noise_reduction,
        loudness_compression: loudness_compression,
        self_speech_reduction: self_speech_reduction,
        sharpness: sharpness,
        wind_noise_reduction: wind_noise_reduction,
        mode_type: mode_type,
      };
    }

    case actions.READ_FROM_DEVICE: {
      const temp = action.data ?? [...state.hardwareData];
      if (temp[1] == 10) {
        state.FeedbackCancellation = true;
      } else {
        state.FeedbackCancellation = false;
      }

      if (temp[3] == 28) {
        state.WhisleBlock = true;
      } else {
        state.WhisleBlock = false;
      }
      const master_gain = unMapValues(temp[GAIN_POINTS.MASTER_GAIN]);
      const noise_reduction = unMapValues(temp[GAIN_POINTS.NOISE_REDUCTION]);
      const loudness_compression = unMapValues(
        temp[GAIN_POINTS.LOUDNESS_COMPRESSION]
      );
      const self_speech_reduction = unMapValues(
        temp[GAIN_POINTS.SELF_SPEECH_REDUCTION]
      );
      const sharpness = unMapValues(temp[GAIN_POINTS.SHARPNESS]);
      const wind_noise_reduction = unMapValues(
        temp[GAIN_POINTS.WIND_NOISEREDUCTION]
      );

      action.onSuccess({
        ...state,
        data: temp,
        master_gain,
        noise_reduction,
        loudness_compression,
        self_speech_reduction,
        sharpness,
        wind_noise_reduction,
      });

      return {
        ...state,
        data: temp,
        master_gain,
        noise_reduction,
        loudness_compression,
        self_speech_reduction,
        sharpness,
        wind_noise_reduction,
      };
    }
    case actions.APPLY_FEEDBACKCANCELATION:
      const val = action.value;
      const data = [...state.data];
      console.log("before", data);
      if (action.WhisleBlock) {
        state.WhisleBlock = val;
        if (val) {
          data[3] = 28;
        } else {
          const inititalData = state.initial_data;
          data[3] = 24;
        }
      } else {
        state.FeedbackCancellation = val;
        if (val) {
          data[1] = 10;
          data[4] = 200;
        } else {
          data[1] = 0;
          data[4] = 194;
        }
      }

      console.log("after", data);
      return { ...state, data };

    case actions.SET_AUTO_FIT_DATA: {
      const temp = [...state.data];

      const linearGainsArr = action.value;

      const linearGains = [36, 37, 38, 39, 40, 41, 42, 43];

      linearGains.forEach((item, index) => {
        temp[item] = Math.round(linearGainsArr[index]);
      });
      return {
        ...state,
        data: temp,
        master_gain: 0,
        noise_reduction: 0,
        loudness_compression: 0,
        self_speech_reduction: 0,
        sharpness: 0,
        wind_noise_reduction: 0,
      };
    }

    case actions.APPLY_WINNOISEBLOCK:
      const value = action.val;
      const result = [...state.data];
      state.WindNoiseBlock = value;
      if (value) {
        result[20] = result[20] - 1;
        result[21] = result[21] - 1;
        result[22] = result[22] - 1;
        result[23] = result[23] - 1;
        result[24] = result[24] - 1;
        result[25] = result[25] - 1;
        result[26] = result[26] - 1;
        result[27] = result[27] - 1;
      } else {
        const inititalData = state.initial_data;

        result[20] =
          inititalData[20] ?? state.device_type == DEVICES.BTE_OPTIMA
            ? defaultData[20]
            : btePrimedefaultData[20];
        result[21] =
          inititalData[21] ?? state.device_type == DEVICES.BTE_OPTIMA
            ? defaultData[21]
            : btePrimedefaultData[20];
        result[22] =
          inititalData[22] ?? state.device_type == DEVICES.BTE_OPTIMA
            ? defaultData[22]
            : btePrimedefaultData[20];
        result[23] =
          inititalData[23] ?? state.device_type == DEVICES.BTE_OPTIMA
            ? defaultData[23]
            : btePrimedefaultData[20];
        result[24] =
          inititalData[24] ?? state.device_type == DEVICES.BTE_OPTIMA
            ? defaultData[24]
            : btePrimedefaultData[20];
        result[25] =
          inititalData[25] ?? state.device_type == DEVICES.BTE_OPTIMA
            ? defaultData[25]
            : btePrimedefaultData[20];
        result[26] =
          inititalData[26] ?? state.device_type == DEVICES.BTE_OPTIMA
            ? defaultData[26]
            : btePrimedefaultData[20];
        result[27] =
          inititalData[27] ?? state.device_type == DEVICES.BTE_OPTIMA
            ? defaultData[27]
            : btePrimedefaultData[20];
      }

      return { ...state, data: result };

    case actions.CHANGE_MASTER_GAIN: {
      let value = action.val;
      if (value < -10) {
        value = -10;
      }

      if (value > 10) {
        value = 10;
      }

      const oldVal = state.master_gain;
      const temp = [...state.data];

      //identifying that is it increment or decrement
      const multiplyVal = value - oldVal;

      const CHANGABLE_VAL = 2;
      //changing linear gain
      const arr = [36, 37, 38, 39, 40, 41, 42, 43];
      arr.forEach((item) => {
        const changedVal = temp[item] + multiplyVal * CHANGABLE_VAL;

        if (changedVal <= WDRC.MIN_LINEAR_GAIN)
          temp[item] = WDRC.MIN_LINEAR_GAIN;
        else if (changedVal >= WDRC.MAX_LINEAR_GAIN)
          temp[item] = WDRC.MAX_LINEAR_GAIN;
        else temp[item] = changedVal;
      });

      return { ...state, data: temp, master_gain: value };
    }

    case actions.CHANGE_COMPRESSION: {
      let value = action.val;
      if (value < 0) {
        value = 0;
      }

      if (value > 5) {
        value = 5;
      }

      const oldVal = state.loudness_compression;
      const temp = [...state.data];

      //identifying that is it increment or decrement
      const multiplyVal = parseFloat(value - oldVal);

      //here 1 will increase in array packet but attually it is consider as 0.1 in view
      const CHANGABLE_VAL = 1;

      const arr = [52, 53, 54, 55, 56, 57, 58, 59];
      arr.forEach((item) => {
        const changedVal = temp[item] + multiplyVal * CHANGABLE_VAL;

        if (changedVal <= WDRC.MIN_COMPRESSION_RATIO)
          temp[item] = WDRC.MIN_COMPRESSION_RATIO;
        else if (changedVal >= WDRC.MAX_COMPRESSION_RATIO)
          temp[item] = WDRC.MAX_COMPRESSION_RATIO;
        else temp[item] = changedVal;
      });

      return { ...state, data: temp, loudness_compression: value };
    }

    case actions.CHANGE_WIND_NOISE_REDUCTION: {
      let value = action.val;
      if (value < 0) {
        value = 0;
      }

      if (value > 5) {
        value = 5;
      }
      const oldVal = state.wind_noise_reduction;
      const temp = [...state.data];

      //identifying that is it increment or decrement
      const multiplyVal = parseFloat(value - oldVal);

      //here 1 will increase in array packet but attually it is consider as 0.1 in view
      const CHANGABLE_VAL = 1;

      //expansion ration value change
      const arr = [20, 21, 22, 23, 24, 25, 26, 27];
      arr.forEach((item) => {
        const changedVal = temp[item] - multiplyVal * CHANGABLE_VAL;

        if (changedVal <= WDRC.MIN_COMPRESSION_RATIO)
          temp[item] = WDRC.MIN_COMPRESSION_RATIO;
        else if (changedVal >= WDRC.MAX_COMPRESSION_RATIO)
          temp[item] = WDRC.MAX_COMPRESSION_RATIO;
        else temp[item] = changedVal;
      });

      return { ...state, data: temp, wind_noise_reduction: value };
    }

    case actions.CHANGE_NOISE_REDUCTION: {
      let value = action.val;
      if (value < 0) {
        value = 0;
      }

      if (value > 5) {
        value = 5;
      }
      const oldVal = state.noise_reduction;
      const temp = [...state.data];

      //identifying that is it increment or decrement
      const multiplyVal = value - oldVal;

      const CHANGABLE_VAL = 2;
      //expansion knee value change
      const arr = [28, 29, 30, 31, 32, 33, 34, 35];
      arr.forEach((item) => {
        const changedVal = temp[item] + multiplyVal * CHANGABLE_VAL;

        if (changedVal <= WDRC.MIN_EXPANSION_KNEE)
          temp[item] = WDRC.MIN_EXPANSION_KNEE;
        else if (changedVal >= WDRC.MAX_EXPANSION_KNEE)
          temp[item] = WDRC.MAX_EXPANSION_KNEE;
        else temp[item] = changedVal;
      });

      return { ...state, data: temp, noise_reduction: value };
    }

    case actions.CHANGE_SHARPNESS: {
      let value = action.val;
      if (value < 0) {
        value = 0;
      }

      if (value > 5) {
        value = 5;
      }
      const oldVal = state.sharpness;
      const temp = [...state.data];

      //identifying that is it increment or decrement
      const multiplyVal = value - oldVal;

      const CHANGABLE_VAL = 2;
      //expansion Linear Gain > 2000Hz
      const arr1 = [41, 42, 43];
      arr1.forEach((item) => {
        const changedVal = temp[item] + multiplyVal * CHANGABLE_VAL;
        if (changedVal <= WDRC.MIN_LINEAR_GAIN)
          temp[item] = WDRC.MIN_LINEAR_GAIN;
        else if (changedVal >= WDRC.MAX_LINEAR_GAIN)
          temp[item] = WDRC.MAX_LINEAR_GAIN;
        else temp[item] = changedVal;
      });

      //here 1 will increase in array packet but attually it is consider as 0.1 in view
      const COMPRESSION_VAL = 1;

      const arr2 = [57, 58, 59];
      arr2.forEach((item) => {
        const changedVal = temp[item] + multiplyVal * COMPRESSION_VAL;
        if (changedVal <= WDRC.MIN_COMPRESSION_RATIO)
          temp[item] = WDRC.MIN_COMPRESSION_RATIO;
        else if (changedVal >= WDRC.MAX_COMPRESSION_RATIO)
          temp[item] = WDRC.MAX_COMPRESSION_RATIO;
        else temp[item] = changedVal;
      });

      return { ...state, data: temp, sharpness: value };
    }

    case actions.CHANGE_SPEECH_REDUCTION: {
      let value = action.val;
      if (value < 0) {
        value = 0;
      }

      if (value > 5) {
        value = 5;
      }
      const oldVal = state.self_speech_reduction;
      const temp = [...state.data];

      //identifying that is it increment or decrement
      const multiplyVal = value - oldVal;

      const CHANGABLE_VAL = 2;
      //expansion Linear Gain > 2000Hz
      const arr1 = [36, 37, 38];
      arr1.forEach((item) => {
        const changedVal = temp[item] - multiplyVal * CHANGABLE_VAL;
        if (changedVal <= WDRC.MIN_LINEAR_GAIN)
          temp[item] = WDRC.MIN_LINEAR_GAIN;
        else if (changedVal >= WDRC.MAX_LINEAR_GAIN)
          temp[item] = WDRC.MAX_LINEAR_GAIN;
        else temp[item] = changedVal;
      });

      //here 1 will increase in array packet but attually it is consider as 0.1 in view
      const COMPRESSION_VAL = 1;

      const arr2 = [52, 53, 54];
      arr2.forEach((item) => {
        const changedVal = temp[item] + multiplyVal * COMPRESSION_VAL;
        if (changedVal <= WDRC.MIN_COMPRESSION_RATIO)
          temp[item] = WDRC.MIN_COMPRESSION_RATIO;
        else if (changedVal >= WDRC.MAX_COMPRESSION_RATIO)
          temp[item] = WDRC.MAX_COMPRESSION_RATIO;
        else temp[item] = changedVal;
      });

      return { ...state, data: temp, self_speech_reduction: value };
    }

    case actions.CHANGE_FITTING_DATA: {
      const { fitting_data, ...responseData } = action.data;
      const finalData = { ...state, ...responseData, remarks: "" };
      console.log("this is final data", finalData);

      if (action.read_only) {
        finalData["read_only"] = true;
        finalData["connected"] = true;
      }
      finalData["data"] = fitting_data;

      return finalData;
    }

    case actions.CHANGE_MARK_VALUE: {
      const index = action.index;
      const value = action.value;

      state.inSitudB[index] = value;
    }

    // case actions.INSITU_LEFT: {
    //   state.inSitudBLeft=true;
    // }

    // case actions.INSITU_RIGHT: {
    //   state.inSitudBRight=true;
    // }
    //-->
    case actions.MUTED_ACTION: {
      if (state.data[3] == 0) {
        if (state.WhisleBlock) {
          state.data[3] = 28;
        } else {
          state.data[3] = 24;
        }
      } else {
        if (state.data[3] == 28) {
          state.WhisleBlock = true;
        } else {
          state.WhisleBlock = false;
        }
        state.data[3] = 0;
      }
      console.log("this is muted event");
      return { ...state };
    }

    case actions.INSITU_COUNT: {
      let count = state.inSituCount + 1;
      state.inSituCount = count;
      console.log("action", state.inSituCount);
    }

    case actions.CHNAGE_REMARKS: {
      console.log("this is right remarks", action.value);
      return { ...state, remarks: action.value };
    }
    default:
      return { ...state };
  }
};
export default fittingReducer;
