import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  Select,
  Slider,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DEVICES, LISTENING_SIDE } from "../../../utils/patient.constants";
import {
  changeByteValue,
  changeDeviceSideSelection,
} from "../../../store/actions/fittingAction";
import { ratios } from "../../../utils/ratios";
import { roundValueForCRAndER } from "../../../utils/helper";
import RicFittingChartArea from "./RicFittingChartArea";
import {
  readEqualizer,
  readMPO,
  setEqualizer,
  setMPO,
} from "../../../store/actions/ricFittingActions";
import { useParams } from "react-router-dom";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RicFineTunningController = ({
  setData8L,
  data8L,
  setData8R,
  data8R,
  mutedHandle,
  setMPOL32,
  setMPOR32,
  mpoR32,
  mpoL32,
  setFreShapR32,
  freShapR32,
  setFreShapL32,
  freShapL32,
  setShouldUpdate,
}) => {
  const params = useParams()
  const [edgeVal, setEdgeVal] = useState(50);
  const { ricLeftFitting } = useSelector((state) => state);
  const { ricRightFitting } = useSelector((state) => state);

  const [value, setValue] = useState(0);
  const [activeSide, setActiveSide] = useState(
    ricRightFitting?.connected ? LISTENING_SIDE.RIGHT : LISTENING_SIDE.LEFT
  );
  const [link, setLink] = useState(false);

  const [eqValuesLeft, setEqValuesLeft] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [eqValuesRight, setEqValuesRight] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  
  const [mpoValueLeft, setMpoValueLeft] = useState(0);
  const [mpoValueRight, setMpoValueRight] = useState(0);

  const eqValues =
    activeSide === LISTENING_SIDE.LEFT ? eqValuesLeft : eqValuesRight;

  const handleSliderChange = (index, value, stepChange = 0) => {
    const minValue = -21;
    const maxValue = 0;

    if (activeSide === LISTENING_SIDE.LEFT) {
      const newEqValues = [...eqValuesLeft];
      newEqValues[index] = Math.max(minValue, Math.min(maxValue, value));
      setEqValuesLeft(newEqValues);

      const deviceObj = ricLeftFitting.deviceObj;
      dispatch(setEqualizer(activeSide, newEqValues, deviceObj));

      if (link) {
        const syncedEqValues = [...eqValuesRight];
        syncedEqValues[index] = Math.max(
          minValue,
          Math.min(maxValue, syncedEqValues[index] + stepChange)
        );
        setEqValuesRight(syncedEqValues);
        dispatch(
          setEqualizer(
            LISTENING_SIDE.RIGHT,
            syncedEqValues,
            ricRightFitting.deviceObj
          )
        );
      }
    } else if (activeSide === LISTENING_SIDE.RIGHT) {
      const newEqValues = [...eqValuesRight];
      newEqValues[index] = Math.max(minValue, Math.min(maxValue, value));
      setEqValuesRight(newEqValues);

      const deviceObj = ricRightFitting.deviceObj;
      dispatch(setEqualizer(activeSide, newEqValues, deviceObj));

      if (link) {
        const syncedEqValues = [...eqValuesLeft];
        syncedEqValues[index] = Math.max(
          minValue,
          Math.min(maxValue, syncedEqValues[index] + stepChange)
        );
        setEqValuesLeft(syncedEqValues);
        dispatch(
          setEqualizer(
            LISTENING_SIDE.LEFT,
            syncedEqValues,
            ricLeftFitting.deviceObj
          )
        );
      }
    }
  };

  const [selectAllLeft, setSelectAllLeft] = useState(false);
  const [selectAllRight, setSelectAllRight] = useState(false);
  
  // const handleSelectAll = (side) => {
  //   if (side === LISTENING_SIDE.LEFT) {
  //     const newSelectAllLeft = !selectAllLeft;
  //     setSelectAllLeft(newSelectAllLeft);
  //     if (link && newSelectAllLeft) {
  //       setSelectAllRight(true); // Check the right side if link is on and left is selected
  //     }
  //   } else {
  //     const newSelectAllRight = !selectAllRight;
  //     setSelectAllRight(newSelectAllRight);
  //     if (link && newSelectAllRight) {
  //       setSelectAllLeft(true); // Check the left side if link is on and right is selected
  //     }
  //   }
  // };
  const handleSelectAll = (side) => {
    if (side === LISTENING_SIDE.LEFT) {
      const newSelectAllLeft = !selectAllLeft;
      setSelectAllLeft(newSelectAllLeft);
      if (link && !newSelectAllLeft) {
        setSelectAllRight(false); // Uncheck the right side if the left is unchecked
      }
    } else {
      const newSelectAllRight = !selectAllRight;
      setSelectAllRight(newSelectAllRight);
      if (link && !newSelectAllRight) {
        setSelectAllLeft(false); // Uncheck the left side if the right is unchecked
      }
    }
  };
  
  useEffect(() => {
    if (!link) {
      setSelectAllLeft(false);
      setSelectAllRight(false);
    }
  }, [link]);

  useEffect(() => {
    if (link) {
      setSliderButton(1); // Default to Left MPO slider selected when link is on
    }
  }, [link]);

  useEffect(() => {
    if (link && (selectAllLeft || selectAllRight)) {
      setSelectAllLeft(true);
      setSelectAllRight(true); // Ensure both sides' sliders are selected when link is on
    }
  }, [link, selectAllLeft, selectAllRight]);

  useEffect(() => {
    if (link && !selectAllLeft && !selectAllRight) {
      // Keep the current slider active when the link is clicked
      setSliderButton(sliderButton);
    }
  }, [link]);

  const handleArrowClick = (direction) => {
    const stepChange = direction === "up" ? 3 : -3;
    if ((activeSide === LISTENING_SIDE.LEFT && selectAllLeft) || (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)) {
      const newEqValues = eqValues.map((value) =>
        Math.max(-21, Math.min(0, value + stepChange))
      );
      if (activeSide === LISTENING_SIDE.LEFT) {
        setEqValuesLeft(newEqValues);
        dispatch(setEqualizer(LISTENING_SIDE.LEFT, newEqValues, ricLeftFitting.deviceObj));
        if (link) {
          const newEqValuesRight = eqValuesRight.map((value, index) =>
            Math.max(-21, Math.min(0, value + stepChange))
          );
          setEqValuesRight(newEqValuesRight);
          dispatch(setEqualizer(LISTENING_SIDE.RIGHT, newEqValuesRight, ricRightFitting.deviceObj));
        }
      } else {
        setEqValuesRight(newEqValues);
        dispatch(setEqualizer(LISTENING_SIDE.RIGHT, newEqValues, ricRightFitting.deviceObj));
        if (link) {
          const newEqValuesLeft = eqValuesLeft.map((value, index) =>
            Math.max(-21, Math.min(0, value + stepChange))
          );
          setEqValuesLeft(newEqValuesLeft);
          dispatch(setEqualizer(LISTENING_SIDE.LEFT, newEqValuesLeft, ricLeftFitting.deviceObj));
        }
      }
    } else {
      const currentEqValues =
        activeSide === LISTENING_SIDE.LEFT ? eqValuesLeft : eqValuesRight;
      const newValue = currentEqValues[sliderButton] + stepChange;
      handleSliderChange(sliderButton, newValue, stepChange);
    }
  };

  const [sliderButton, setSliderButton] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    setEqValuesLeft(ricLeftFitting.eqValues);
  }, [ricLeftFitting.eqValues]);

  useEffect(() => {
    setMpoValueRight(ricRightFitting.mpoValue);
  }, [ricRightFitting.mpoValue]);
  
  useEffect(() => {
    setMpoValueLeft(ricLeftFitting.mpoValue);
  }, [ricLeftFitting.mpoValue]);
  
  // useEffect(() => {
  //   dispatch(readMPO(LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj));
  //   dispatch(readMPO(LISTENING_SIDE.RIGHT, ricRightFitting.deviceObj));
  // }, [dispatch, ricLeftFitting.deviceObj, ricRightFitting.deviceObj]);
  
  useEffect(() => {
    setEqValuesRight(ricRightFitting.eqValues);
  }, [ricRightFitting.eqValues]);

  useEffect(() => {
    return () => {
      setShouldUpdate(true);
    };
  }, []);




  useEffect(() => {
    if (
      !(
        ricLeftFitting.remote || 
        ricRightFitting.remote || 
        ricLeftFitting.updating || 
        ricRightFitting.updating
      )) {
    dispatch(readEqualizer(LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj));
    dispatch(readEqualizer(LISTENING_SIDE.RIGHT, ricRightFitting.deviceObj));
    dispatch(readMPO(LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj));
    dispatch(readMPO(LISTENING_SIDE.RIGHT, ricRightFitting.deviceObj));
    }
  }, [ricRightFitting.ric_mode, ricLeftFitting.ric_mode]);

 
 
  const handleMpoSliderChange = (index, value, stepChange = 0) => {
    const minValue = -21;
    const maxValue = 0;
  
    if (index === 0) {
      const newMpoValueLeft = Math.max(minValue, Math.min(maxValue, value));
      setMpoValueLeft(newMpoValueLeft);
  
      dispatch(setMPO(LISTENING_SIDE.LEFT, newMpoValueLeft, ricLeftFitting.deviceObj));
  
      if (link) {
        const syncedMpoValueRight = Math.max(
          minValue,
          Math.min(maxValue, mpoValueRight + stepChange)
        );
        setMpoValueRight(syncedMpoValueRight);
        dispatch(
          setMPO(LISTENING_SIDE.RIGHT, syncedMpoValueRight, ricRightFitting.deviceObj)
        );
      }
    } else if (index === 1) {
      const newMpoValueRight = Math.max(minValue, Math.min(maxValue, value));
      setMpoValueRight(newMpoValueRight);
  
      dispatch(setMPO(LISTENING_SIDE.RIGHT, newMpoValueRight, ricRightFitting.deviceObj));
  
      if (link) {
        const syncedMpoValueLeft = Math.max(
          minValue,
          Math.min(maxValue, mpoValueLeft + stepChange)
        );
        setMpoValueLeft(syncedMpoValueLeft);
        dispatch(
          setMPO(LISTENING_SIDE.LEFT, syncedMpoValueLeft, ricLeftFitting.deviceObj)
        );
      }
    }
  };
  
  // const handleMpoArrowClick = (direction) => {
  //   const stepChange = direction === "up" ? 3 : -3;
  //   const currentMpoValue = sliderButton === 0 ? mpoValueLeft : mpoValueRight;
  //   const newValue = currentMpoValue + stepChange;
  //   handleMpoSliderChange(sliderButton, newValue, stepChange);
  // };
  const handleMpoArrowClick = (direction) => {
    const stepChange = direction === "up" ? 3 : -3;
    if (sliderButton === 0) {
      // Update left MPO slider
      const newValue = mpoValueLeft + stepChange;
      handleMpoSliderChange(0, newValue, stepChange);
    } else if (sliderButton === 1) {
      // Update right MPO slider
      const newValue = mpoValueRight + stepChange;
      handleMpoSliderChange(1, newValue, stepChange);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box>
        <Box maxHeight={"60vh"}>
          <Grid xs={12} md={12} container>
            <Grid md={5.7} xs={5.7}>
              <RicFittingChartArea
                mutedHandle={mutedHandle}
                fitting={ricRightFitting}
                edgeVal={eqValuesRight}
              />
            </Grid>
            <Grid md={0.6} xs={0.6}>
              <Box
                sx={{
                  marginTop: "20vh",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#4A4A4A",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        50dB
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#969696",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        65dB
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#CCCCCC",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        80dB
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={5.7} md={5.7}>
              <RicFittingChartArea
                mutedHandle={mutedHandle}
                fitting={ricLeftFitting}
                edgeVal={eqValuesLeft}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Grid xs={12} display={"flex"} width={"60vw"}>

     {((activeSide == LISTENING_SIDE.LEFT &&
      ricLeftFitting.device_type == DEVICES.RIC_OPTIMA) ||
      (activeSide == LISTENING_SIDE.RIGHT &&
        ricRightFitting.device_type == DEVICES.RIC_OPTIMA)) && (
          value === 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-start", marginLeft: "3vw" }}>
          <Tooltip title="Select All" arrow placement="top">
          <Checkbox
  checked={activeSide === LISTENING_SIDE.LEFT ? selectAllLeft : selectAllRight}
  onChange={() => handleSelectAll(activeSide)}
  sx={{
    transform: "scale(1.5)",
    color: activeSide === LISTENING_SIDE.LEFT ? "#1265A1" : "#C24747",
    "&.Mui-checked": {
      color: activeSide === LISTENING_SIDE.LEFT ? "#1265A1" : "#C24747",
    },
  }}
/>
          </Tooltip>
        </Box>
)
)}
        <Grid
          xs={5}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"row"}
        >
          
          <Box>
 <Tabs value={value} onChange={handleChange} sx={{ height: "1vh" }}>
  <Tab
    value={0}
    sx={{ minHeight: "5px" }}
    label={<Typography variant="h6">Frequency shaping</Typography>}
    {...a11yProps(0)}
  />
  <Tab
    value={1}
    label={<Typography variant="h6">MPO</Typography>}
    {...a11yProps(1)}
  />
</Tabs>
          </Box>
        </Grid>

        {/* LINK Button */}
        {value === 0 && ricRightFitting?.connected &&
          ricLeftFitting?.connected &&
          ricRightFitting?.device_type == ricLeftFitting?.device_type && (
            <Grid
              xs={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {!link ? (
                <Button
                  sx={{ border: "1px solid #DDDDDD", borderRadius: "8px" }}
            
                  onClick={() => {
                    setLink(true);
                    if (selectAllLeft) {
                      setSelectAllRight(true); // Sync the right side with the left
                    } else if (selectAllRight) {
                      setSelectAllLeft(true); // Sync the left side with the right
                    }
                  }}
                
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="24"
                    viewBox="0 0 33 24"
                    fill="none"
                  >
                    <path
                      d="M11.5 17H9.5C6.73858 17 4.5 14.7614 4.5 12C4.5 9.23858 6.73858 7 9.5 7H11.5M21.5 17H23.5C26.2614 17 28.5 14.7614 28.5 12C28.5 9.23858 26.2614 7 23.5 7H21.5"
                      stroke="#CCCCCC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setLink(false);
                  }}
                  sx={{
                    border: "1px solid #2D3B67",
                    borderRadius: "8px",
                    backgroundColor: "#EDF0F7",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="24"
                    viewBox="0 0 32 24"
                    fill="none"
                  >
                    <path
                      d="M13 17H11C8.23858 17 6 14.7614 6 12C6 9.23858 8.23858 7 11 7H13M19 17H21C23.7614 17 26 14.7614 26 12C26 9.23858 23.7614 7 21 7H19M11 12L21 12"
                      stroke="#2D3B67"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              )}
            </Grid>
          )}
      </Grid>

      <Box
        // ml={5}
        mt={1}
        sx={{
          height: "36.5vh",
          scrollbarWidth: "none",
          width: "86vw",
          // overflow:"auto"
        }}
      >
        <Grid
          container
          xs={12}
          md={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          {value === 0 && (
          <Box
            onClick={() => {
              setActiveSide(LISTENING_SIDE.RIGHT);
            }}
            sx={{
              visibility:
                activeSide == LISTENING_SIDE.RIGHT ||
                !ricRightFitting?.connected
                  ? "hidden"
                  : "",
              cursor: "pointer",
              backgroundColor: "#C24747",
              height: "13vh",
              width: "2vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Button  sx={{ height: "13vh", width: "1vw" }} > */}
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 17.5L1.5 9.5L9.5 1.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {/* </Button> */}
          </Box>
          )}
          {/* {((activeSide == LISTENING_SIDE.LEFT &&
            ricLeftFitting.device_type == DEVICES.RIC_OPTIMA) ||
            (activeSide == LISTENING_SIDE.RIGHT &&
              ricRightFitting.device_type == DEVICES.RIC_OPTIMA)) && (
             
            <>
              {eqValues.map((value, index) => (
                <Grid item xs={1.35} md={1.35} key={index}>
                  <Box
                    onClick={() => {
                      !(
                        ricRightFitting.read_only || ricLeftFitting.read_only
                      ) && setSliderButton(index);
                    }}
                    sx={{
                      cursor: "pointer",
                      border: "1px solid #DDDDDD",
                      height: "5vh",
                      width: "10.2vw",
                      backgroundColor:
                        sliderButton === index
                          ? activeSide === LISTENING_SIDE.LEFT
                            ? "rgba(246, 251, 254, 1)"
                            : "rgba(252, 247, 247, 1)"
                          : "",
                      borderTop:
                        sliderButton === index
                          ? activeSide === LISTENING_SIDE.LEFT
                            ? "8px solid rgba(18, 101, 161, 1)"
                            : "8px solid #C24747"
                          : "8px solid #DDDDDD",
                    }}
                  >
                    <Typography
                      sx={{ display: "flex", justifyContent: "center", alignContent: "center"}}
                    >
                      {value}
                    </Typography>
                  </Box>
                  <Grid
                    sx={{
                      backgroundColor:
                        sliderButton === index
                          ? activeSide === LISTENING_SIDE.LEFT
                            ? "rgba(246, 251, 254, 1)"
                            : "rgba(252, 247, 247, 1)"
                          : "",
                      border: "1px solid #DDDDDD",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "10.2vw",
                      height: "25vh",
                      scrollbarWidth: "none",
                    }}
                  >
                    <Slider
                      disabled={
                        ricRightFitting.read_only ||
                        ricLeftFitting.read_only ||
                        link
                      }
                      sx={{
                        height: "20vh",
                        color:
                          activeSide === LISTENING_SIDE.LEFT
                            ? "#1265A1"
                            : "#C24747",
                        "&.Mui-disabled": {
                          color:
                            activeSide === LISTENING_SIDE.LEFT
                              ? "#1265A1"
                              : "#C24747",
                          opacity: 1, // Keep the opacity same as enabled state
                        },
                      }}
                      orientation="vertical"
                      valueLabelDisplay="auto"
                      min={-21}
                      max={0}
                      step={3}
                      value={value}
                      onChange={(e, newValue) =>
                        handleSliderChange(index, newValue)
                      }
                    />
                  </Grid>
                  <Typography>
                    {["250", "500", "1k", "2k", "3k", "4k", "6k", "8k"][index]}
                  </Typography>
                </Grid>
              ))}
            </>
          )} */}



{((activeSide == LISTENING_SIDE.LEFT && ricLeftFitting.device_type == DEVICES.RIC_OPTIMA) ||
  (activeSide == LISTENING_SIDE.RIGHT && ricRightFitting.device_type == DEVICES.RIC_OPTIMA)) && (
  <>
    {/* {value === 0 ? (
      // Multiple Sliders for Frequency Shaping
      eqValues.map((sliderValue, index) => (
        <Grid item xs={1.35} md={1.35} key={index}>
          <Box
            onClick={() => {
              if (!((activeSide === LISTENING_SIDE.LEFT && selectAllLeft) || (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)) && !(ricRightFitting.read_only || ricLeftFitting.read_only)) {
                setSliderButton(index);
              }
            }}
            sx={{
              cursor: "pointer",
              border: "1px solid #DDDDDD",
              height: "5vh",
              width: "10.2vw",
              backgroundColor:
                ((activeSide === LISTENING_SIDE.LEFT && selectAllLeft) || (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)) || sliderButton === index
                  ? activeSide === LISTENING_SIDE.LEFT
                    ? "rgba(246, 251, 254, 1)"
                    : "rgba(252, 247, 247, 1)"
                  : "",
              borderTop:
                ((activeSide === LISTENING_SIDE.LEFT && selectAllLeft) || (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)) || sliderButton === index
                  ? activeSide === LISTENING_SIDE.LEFT
                    ? "8px solid rgba(18, 101, 161, 1)"
                    : "8px solid #C24747"
                  : "8px solid #DDDDDD",
            }}
          >
            <Typography sx={{ textAlign: "center" }}>{sliderValue}</Typography>
          </Box>
          <Grid
            sx={{
              backgroundColor:
                ((activeSide === LISTENING_SIDE.LEFT && selectAllLeft) || (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)) || sliderButton === index
                  ? activeSide === LISTENING_SIDE.LEFT
                    ? "rgba(246, 251, 254, 1)"
                    : "rgba(252, 247, 247, 1)"
                  : "",
              border: "1px solid #DDDDDD",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "10.2vw",
              height: "25vh",
              scrollbarWidth: "none",
            }}
          >
            <Slider
              disabled={
                ricRightFitting.read_only ||
                ricLeftFitting.read_only ||
                link ||
                (activeSide === LISTENING_SIDE.LEFT && selectAllLeft) ||
                (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)
              }
              sx={{
                height: "20vh",
                color:
                  activeSide === LISTENING_SIDE.LEFT
                    ? "#1265A1"
                    : "#C24747",
                "&.Mui-disabled": {
                  color:
                    activeSide === LISTENING_SIDE.LEFT
                      ? "#1265A1"
                      : "#C24747",
                  opacity: 1,
                },
              }}
              orientation="vertical"
              valueLabelDisplay="auto"
              min={-21}
              max={0}
              step={3}
              value={sliderValue}
              onChange={(e, newValue) =>
                handleSliderChange(index, newValue)
              }
            />
          </Grid>
          <Typography>
            {["250", "500", "1k", "2k", "3k", "4k", "6k", "8k"][index]}
          </Typography>
        </Grid>
      ))
    ) : (
      // Single Slider for MPO
      <Grid item xs={1.35} md={1.35}>
        <Box
          sx={{
            cursor: "pointer",
            border: "1px solid #DDDDDD",
            height: "5vh",
            width: "10.2vw",
            backgroundColor:
              activeSide === LISTENING_SIDE.LEFT
                ? "rgba(246, 251, 254, 1)"
                : "rgba(252, 247, 247, 1)",
            borderTop:
              activeSide === LISTENING_SIDE.LEFT
                ? "8px solid rgba(18, 101, 161, 1)"
                : "8px solid #C24747",
          }}
        >
<Typography sx={{ textAlign: "center" }}>
  {activeSide === LISTENING_SIDE.LEFT ? mpoValueLeft : mpoValueRight}
</Typography>
        </Box>
        <Grid
          sx={{
            backgroundColor:
              activeSide === LISTENING_SIDE.LEFT
                ? "rgba(246, 251, 254, 1)"
                : "rgba(252, 247, 247, 1)",
            border: "1px solid #DDDDDD",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "10.2vw",
            height: "25vh",
            scrollbarWidth: "none",
          }}
        >
          <Slider
            disabled={
              ricRightFitting.read_only ||
              ricLeftFitting.read_only ||
              link
            }
            sx={{
              height: "20vh",
              color:
                activeSide === LISTENING_SIDE.LEFT
                  ? "#1265A1"
                  : "#C24747",
              "&.Mui-disabled": {
                color:
                  activeSide === LISTENING_SIDE.LEFT
                    ? "#1265A1"
                    : "#C24747",
                opacity: 1,
              },
            }}
            orientation="vertical"
            valueLabelDisplay="auto"
            min={-21}
            max={0}
            step={3}
            value={activeSide === LISTENING_SIDE.LEFT ? mpoValueLeft : mpoValueRight}
            onChange={(e, newValue) => handleMpoSliderChange(newValue)}
        />
      </Grid>
    </Grid>
  )} */}

{/* {value === 0 ? (
  // Multiple Sliders for Frequency Shaping
  eqValues.map((sliderValue, index) => (
    <Grid item xs={1.35} md={1.35} key={index}>
      <Box
        onClick={() => {
          if (!((activeSide === LISTENING_SIDE.LEFT && selectAllLeft) || (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)) && !(ricRightFitting.read_only || ricLeftFitting.read_only)) {
            setSliderButton(index);
          }
        }}
        sx={{
          cursor: "pointer",
          border: "1px solid #DDDDDD",
          height: "5vh",
          width: "10.2vw",
          backgroundColor:
            ((activeSide === LISTENING_SIDE.LEFT && selectAllLeft) || (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)) || sliderButton === index
              ? activeSide === LISTENING_SIDE.LEFT
                ? "rgba(246, 251, 254, 1)"
                : "rgba(252, 247, 247, 1)"
              : "",
          borderTop:
            ((activeSide === LISTENING_SIDE.LEFT && selectAllLeft) || (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)) || sliderButton === index
              ? activeSide === LISTENING_SIDE.LEFT
                ? "8px solid rgba(18, 101, 161, 1)"
                : "8px solid #C24747"
              : "8px solid #DDDDDD",
        }}
      >
        <Typography sx={{ textAlign: "center" }}>{sliderValue}</Typography>
      </Box>
      <Grid
        sx={{
          backgroundColor:
            ((activeSide === LISTENING_SIDE.LEFT && selectAllLeft) || (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)) || sliderButton === index
              ? activeSide === LISTENING_SIDE.LEFT
                ? "rgba(246, 251, 254, 1)"
                : "rgba(252, 247, 247, 1)"
              : "",
          border: "1px solid #DDDDDD",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "10.2vw",
          height: "25vh",
          scrollbarWidth: "none",
        }}
      >
        <Slider
          disabled={
            ricRightFitting.read_only ||
            ricLeftFitting.read_only ||
            link ||
            (activeSide === LISTENING_SIDE.LEFT && selectAllLeft) ||
            (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)
          }
          sx={{
            height: "20vh",
            color:
              activeSide === LISTENING_SIDE.LEFT
                ? "#1265A1"
                : "#C24747",
            "&.Mui-disabled": {
              color:
                activeSide === LISTENING_SIDE.LEFT
                  ? "#1265A1"
                  : "#C24747",
              opacity: 1,
            },
          }}
          orientation="vertical"
          valueLabelDisplay="auto"
          min={-21}
          max={0}
          step={3}
          value={sliderValue}
          onChange={(e, newValue) =>
            handleSliderChange(index, newValue)
          }
        />
      </Grid>
      <Typography>
        {["250", "500", "1k", "2k", "3k", "4k", "6k", "8k"][index]}
      </Typography>
    </Grid>
  ))
) : (
  // Single MPO Sliders for Both Ears Side by Side
  <Grid container spacing={2} justifyContent="center">
    <Grid item xs={1.35} md={1.35}>
      <Box
        onClick={() => {
          if (!ricLeftFitting.read_only && !link) {
            setSliderButton(0); // Set the active slider button for MPO
          }
        }}
        sx={{
          cursor: "pointer",
          border: "1px solid #DDDDDD",
          height: "5vh",
          width: "10.2vw",
          backgroundColor: "rgba(246, 251, 254, 1)",
          borderTop: "8px solid rgba(18, 101, 161, 1)",
          marginRight: "4vw",
        }}
      >
        <Typography sx={{ textAlign: "center" }}>{mpoValueLeft}</Typography>
      </Box>
      <Grid
        sx={{
          backgroundColor: "rgba(246, 251, 254, 1)",
          border: "1px solid #DDDDDD",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "10.2vw",
          height: "25vh",
          scrollbarWidth: "none",
       
        }}
      >
        <Slider
          disabled={ricLeftFitting.read_only || link}
          sx={{
            height: "20vh",
            color: "#1265A1",
            "&.Mui-disabled": {
              color: "#1265A1",
              opacity: 1,
            },
          }}
          orientation="vertical"
          valueLabelDisplay="auto"
          min={-21}
          max={0}
          step={3}
          value={mpoValueLeft}
          onChange={(e, newValue) => handleMpoSliderChange(0, newValue)}
        />
      </Grid>
    </Grid>
    <Grid item xs={1.35} md={1.35}>
      <Box
        onClick={() => {
          if (!ricRightFitting.read_only && !link) {
            setSliderButton(1); // Set the active slider button for MPO
          }
        }}
        sx={{
          cursor: "pointer",
          border: "1px solid #DDDDDD",
          height: "5vh",
          width: "10.2vw",
          backgroundColor: "rgba(252, 247, 247, 1)",
          borderTop: "8px solid #C24747",
        }}
      >
        <Typography sx={{ textAlign: "center" }}>{mpoValueRight}</Typography>
      </Box>
      <Grid
        sx={{
          backgroundColor: "rgba(252, 247, 247, 1)",
          border: "1px solid #DDDDDD",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "10.2vw",
          height: "25vh",
          scrollbarWidth: "none",
        }}
      >
        <Slider
          disabled={ricRightFitting.read_only || link}
          sx={{
            height: "20vh",
            color: "#C24747",
            "&.Mui-disabled": {
              color: "#C24747",
              opacity: 1,
            },
          }}
          orientation="vertical"
          valueLabelDisplay="auto"
          min={-21}
          max={0}
          step={3}
          value={mpoValueRight}
          onChange={(e, newValue) => handleMpoSliderChange(1, newValue)}
        />
      </Grid>
    </Grid>
  </Grid>
)} */}
{value === 0 ? (
  // Multiple Sliders for Frequency Shaping
  eqValues.map((sliderValue, index) => (
    <Grid item xs={1.35} md={1.35} key={index}>
      <Box
        onClick={() => {
          if (!((activeSide === LISTENING_SIDE.LEFT && selectAllLeft) || (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)) && !(ricRightFitting.read_only || ricLeftFitting.read_only)) {
            setSliderButton(index);
          }
        }}
        sx={{
          cursor: "pointer",
          border: "1px solid #DDDDDD",
          height: "5vh",
          width: "10.2vw",
          backgroundColor:
            ((activeSide === LISTENING_SIDE.LEFT && selectAllLeft) || (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)) || sliderButton === index
              ? activeSide === LISTENING_SIDE.LEFT
                ? "rgba(246, 251, 254, 1)"
                : "rgba(252, 247, 247, 1)"
              : "",
          borderTop:
            ((activeSide === LISTENING_SIDE.LEFT && selectAllLeft) || (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)) || sliderButton === index
              ? activeSide === LISTENING_SIDE.LEFT
                ? "8px solid rgba(18, 101, 161, 1)"
                : "8px solid #C24747"
              : "8px solid #DDDDDD",
        }}
      >
        <Typography sx={{ textAlign: "center" }}>{sliderValue}</Typography>
      </Box>
      <Grid
        sx={{
          backgroundColor:
            ((activeSide === LISTENING_SIDE.LEFT && selectAllLeft) || (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)) || sliderButton === index
              ? activeSide === LISTENING_SIDE.LEFT
                ? "rgba(246, 251, 254, 1)"
                : "rgba(252, 247, 247, 1)"
              : "",
          border: "1px solid #DDDDDD",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "10.2vw",
          height: "25vh",
          scrollbarWidth: "none",
        }}
      >
        <Slider
          disabled={
            ricRightFitting.read_only ||
            ricLeftFitting.read_only ||
            link ||
            (activeSide === LISTENING_SIDE.LEFT && selectAllLeft) ||
            (activeSide === LISTENING_SIDE.RIGHT && selectAllRight)
          }
          sx={{
            height: "20vh",
            color:
              activeSide === LISTENING_SIDE.LEFT
                ? "#1265A1"
                : "#C24747",
            "&.Mui-disabled": {
              color:
                activeSide === LISTENING_SIDE.LEFT
                  ? "#1265A1"
                  : "#C24747",
              opacity: 1,
            },
          }}
          orientation="vertical"
          valueLabelDisplay="auto"
          min={-21}
          max={0}
          step={3}
          value={sliderValue}
          onChange={(e, newValue) =>
            handleSliderChange(index, newValue)
          }
        />
      </Grid>
      <Typography>
        {["250", "500", "1k", "2k", "3k", "4k", "6k", "8k"][index]}
      </Typography>
    </Grid>
  ))
) : (
  // Single MPO Sliders for Both Ears Side by Side
  <Grid container spacing={2} justifyContent={ricRightFitting.connected && ricLeftFitting.connected ? "space-between" : "center"} marginLeft={ricRightFitting.connected && ricLeftFitting.connected ? "15vw" : "0"} marginRight={ricRightFitting.connected && ricLeftFitting.connected ? "20vw" : "2vw"}>
    {ricRightFitting.connected && (
      <Grid item xs={1.35} md={1.35}>
       <Box
  onClick={() => {
    if (!ricRightFitting.read_only && !link) {
      setSliderButton(1); // Set Right MPO Slider when clicked
    }
  }}
  sx={{
    cursor: "pointer",
    border: "1px solid #DDDDDD",
    height: "5vh",
    width: "10.2vw",
    backgroundColor: link || sliderButton === 1 ? "rgba(252, 247, 247, 1)" : "",  // Right side is selected by default
    borderTop: link || sliderButton === 1 ? "8px solid #C24747" : "8px solid #DDDDDD",
  }}
>
  <Typography sx={{ textAlign: "center" }}>{mpoValueRight}</Typography>
</Box>


<Grid
  sx={{
    backgroundColor: link || sliderButton === 1 ? "rgba(252, 247, 247, 1)" : "",
    border: "1px solid #DDDDDD",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "10.2vw",
    height: "25vh",
    scrollbarWidth: "none",
  }}
>
  <Slider
    disabled={ricRightFitting.read_only || link}
    sx={{
      height: "20vh",
      color: "#C24747",
      "&.Mui-disabled": {
        color: "#C24747",
        opacity: 1, 
      },
    }}
    orientation="vertical"
    valueLabelDisplay="auto"
    min={-21}
    max={0}
    step={3}
    value={mpoValueRight}
    onChange={(e, newValue) => handleMpoSliderChange(1, newValue)}
  />
</Grid>

      </Grid>
    )}
     {value === 1 && ricRightFitting?.connected &&
          ricLeftFitting?.connected &&
          ricRightFitting?.device_type == ricLeftFitting?.device_type && (
            <Grid
              xs={1}
              ml={"5vw"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {!link ? (
                <Button
                  sx={{ border: "1px solid #DDDDDD", borderRadius: "8px" }}
                  onClick={() => {
                    setLink(true);
                    setSelectAllLeft(true);
                    setSelectAllRight(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="24"
                    viewBox="0 0 33 24"
                    fill="none"
                  >
                    <path
                      d="M11.5 17H9.5C6.73858 17 4.5 14.7614 4.5 12C4.5 9.23858 6.73858 7 9.5 7H11.5M21.5 17H23.5C26.2614 17 28.5 14.7614 28.5 12C28.5 9.23858 26.2614 7 23.5 7H21.5"
                      stroke="#CCCCCC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              ) : (
                <Button
                onClick={() => {
                  setLink(false);
                 
                  }}
                  sx={{
                    border: "1px solid #2D3B67",
                    borderRadius: "8px",
                    backgroundColor: "#EDF0F7",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="24"
                    viewBox="0 0 32 24"
                    fill="none"
                  >
                    <path
                      d="M13 17H11C8.23858 17 6 14.7614 6 12C6 9.23858 8.23858 7 11 7H13M19 17H21C23.7614 17 26 14.7614 26 12C26 9.23858 23.7614 7 21 7H19M11 12L21 12"
                      stroke="#2D3B67"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              )}
            </Grid>
          )}
    {ricLeftFitting.connected && (
      <Grid item xs={1.35} md={1.35}>
<Box
  onClick={() => {
    if (!ricLeftFitting.read_only && !link) {
      setSliderButton(0); // Set Left MPO Slider when clicked
    }
  }}
  sx={{
    cursor: "pointer",
    border: "1px solid #DDDDDD",
    height: "5vh",
    width: "10.2vw",
    backgroundColor: link || sliderButton === 1 ? "rgba(246, 251, 254, 1)" : "",
    borderTop: link || sliderButton === 0 ? "8px solid rgba(18, 101, 161, 1)" : "8px solid #DDDDDD",
  }}
>
  <Typography sx={{ textAlign: "center" }}>{mpoValueLeft}</Typography>
</Box>


<Grid
  sx={{
    backgroundColor: link || sliderButton === 0 ? "rgba(246, 251, 254, 1)" : "",
    border: "1px solid #DDDDDD",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "10.2vw",
    height: "25vh",
    scrollbarWidth: "none",
  }}
>
  <Slider
    disabled={ricLeftFitting.read_only || link}
    sx={{
      height: "20vh",
      color: "#1265A1",
      "&.Mui-disabled": {
        color: "#1265A1",
        opacity: 1, // Keep opacity same as enabled state
      },
    }}
    orientation="vertical"
    valueLabelDisplay="auto"
    min={-21}
    max={0}
    step={3}
    value={mpoValueLeft}
    onChange={(e, newValue) => handleMpoSliderChange(0, newValue)}
  />
</Grid>

      </Grid>
    )}
  </Grid>
)}

</>
)}
    {value === 0 && (
          <Box
            ml={2}
            onClick={() => {
              setActiveSide(LISTENING_SIDE.LEFT);
            }}
            sx={{
              visibility:
                activeSide == LISTENING_SIDE.RIGHT
                  ? ricLeftFitting?.connected
                    ? ""
                    : "hidden"
                  : "hidden",
              cursor: "pointer",
              backgroundColor: "#1265A1",
              height: "13vh",
              width: "2vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Button  sx={{ height: "13vh", width: "1vw" }}> */}
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 17.5L9.5 9.5L1.5 1.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {/* </Button> */}
          </Box>
    )}
        </Grid>

        <Box mt={1} gap={4} sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={ricRightFitting.read_only || ricLeftFitting.read_only}
            sx={{ width: "100px", height: "5vh" }}
            variant="outlined"
            onClick={() => (value === 1 ? handleMpoArrowClick("up") : handleArrowClick("up"))}
            >
            <KeyboardArrowUpIcon fontSize="large" />
          </Button>
          <Button
            disabled={ricRightFitting.read_only || ricLeftFitting.read_only}
            sx={{ width: "100px", height: "5vh" }}
            variant="outlined"
            onClick={() => (value === 1 ? handleMpoArrowClick("down") : handleArrowClick("down"))}
            >
            <KeyboardArrowDownIcon fontSize="large" />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default RicFineTunningController;


