import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
  useTheme,
  Divider,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import IncreasDecreaseButton from "../../../components/button/IncreaseDecreaseButton";
import BothSideSlider from "../../../components/button/BothSiderSlider";
import FittingChartArea from ".././FittingChartArea";
import { openModal } from "../../../store/actions/modalAction";
import AdvanceFittingController from ".././AdvanceFittingController";
import {
  applyFeedbackCancellation,
  applyWinNoiseBlock,
  autofitDevice,
  changeByteValue,
  changeCompression,
  changeDeviceArray,
  changeDeviceSideSelection,
  changeMasterGain,
  changeMultipleByteValue,
  changeNoiseReduction,
  changeProfileId,
  changeRemarks,
  changeSharpness,
  changeSpeechRedcution,
  changeWindNoiseReduction,
  disconnectDevice,
  readDataFromDevice,
  resetDevice,
} from "../../../store/actions/fittingAction";
import { memo, useEffect, useRef, useState } from "react";
import SubmitButton from "../../../components/button/SubmitButton";
import { callApiAction } from "../../../store/actions/commonAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import {
  addPatientProfileApi,
  updatePatientProfileField,
} from "../../../apis/patientprofile.api";
import { useNavigate, useParams } from "react-router-dom";
import {
  SNACK_BAR_VARIETNS,
  USER_ROLES,
  actions,
} from "../../../utils/constants";
import {
  changeOriginalValueFromMediun,
  findMediun,
  mapValues,
} from "../../../utils/helper";
import { GAIN_POINTS } from "../../../utils/j10.helper";
import { DEVICES, LISTENING_SIDE } from "../../../utils/patient.constants";

import { Slider } from "@mui/material";
import RicFittingChartArea from "./RicFittingChartArea";
import WriteRicDataToDevice from "./WriteRicDataToDevice";
import {
  changeFeedbackReduction,
  changeRicFeedbackCancellation,
  changeRicMasterGain,
  changeRicMode,
  changeRicNoiseReduction,
  changeSelfSpeechReduction,
  changeSharpnessReduction,
  readEqualizer,
  readFeedbackReduction,
  readNoiseReduction,
  ricAutoFitDevice,
  setEqualizer,
  setVolumeLevel,
} from "../../../store/actions/ricFittingActions";
import ricRightFittingReducer from "../../../store/reducers/ricRightFittingReducer";
import RicBothSideSlider from "../../../components/button/RicBothSideSlider";

const StyledButton = styled(ButtonBase)(({ theme, active }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flex: 1,
  color: active ? "unset" : theme.palette.light.main,
  border: "1px solid " + theme.palette.primary.main,

  borderLeft: 0,
  borderRight: 0,
  borderBottom: active ? 0 : 1,

  background: active ? theme.palette.light.main : theme.palette.primary.main,
}));
const ActionInputButtons = memo(({ title, ...rest }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{ width: "100%", borderLeft: "1px solid" + theme.palette.grey[300] }}
      pl={3}
    >
      <Typography
        variant="body1"
        lineHeight="100%"
        color="dark.main"
        fontWeight={500}
        mb={2}
      >
        {title}
      </Typography>
      <IncreasDecreaseButton {...rest} />
    </Box>
  );
});
const ActionInputSliders = ({ title, ...rest }) => {
  const theme = useTheme();
  return (
    <Box sx={{ width: "6vw" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h6"
          display={"flex"}
          justifyContent={"center"}
          color="dark.main"
          fontWeight={500}
        >
          {title}
        </Typography>
        <Typography>{rest.value}</Typography>
      </Box>
      <Box
        mt={2}
        sx={{
          display: "flex",
          alignItems: "space-between",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            sx={{ display: "flex", justifyContent: "center", height: "28vh" }}
            ml={4}
          >
            <RicBothSideSlider {...rest} />
          </Box>
          <Box
            sx={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" color="grey">
              {rest.max}
            </Typography>
            <Typography variant="h6" color="grey">
              {rest.min}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const marks = [
  { value: 0, label: "Off" },
  { value: 1, label: "Low" },
  { value: 2, label: "Medium" },
  { value: 3, label: "High" },
  { value: 4, label: "Very High" },
  { value: 5, label: "Max" },
];

const CustomSlider = styled(Slider)(({ side }) => ({
  backgroundColor: "white",
  height: 0.1,
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    boxShadow: "none",
    backgroundColor: side
      ? "rgba(238, 210, 210, 0.5 )"
      : "rgba(229, 238, 244, 0.5)",
    border: side ? "4px solid  #C24747" : "4px solid #1265A1",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    left: "calc(-50% + 4px)",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#DDDDDD",
    height: 10,
    width: 10,
    borderRadius: 20,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "white",
    },
  },
  "& .MuiSlider-markLabel": {
    color: "#808080",
    fontSize: "1vw",
    fontFamily: "League spartan",
  },
  "& .MuiSlider-markLabelActive": {
    color: "#2D3B67",
    fontFamily: "League spartan",
  },
}));

const RicBasicFittingAreaController = ({ mutedHandle }) => {
  const params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { user, fitting, ricLeftFitting, ricRightFitting } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  console.log("ricccc", ricRightFitting);
  const [autoFitLeftloading, setAutoFitLeftloading] = useState(false);
  const [autoFitRightloading, setAutoFitRightloading] = useState(false);

  console.log("master gain slider value:", ricRightFitting.ric_master_gain);

  const [saveLoadin, setSaveLoading] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [fetchingData, setFetchingData] = useState(false); // New state variable

  useEffect(() => {
    if (
      !(
        ricLeftFitting.remote ||
        ricRightFitting.remote ||
        ricLeftFitting.updating ||
        ricRightFitting.updating
      )
    ) {
      const fetchNoiseReductionValues = async () => {
        setFetchingData(true); // Set fetchingData to true before starting the fetch
        await dispatch(
          readNoiseReduction(LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj)
        );

        await dispatch(
          readFeedbackReduction(LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj)
        );
        await dispatch(
          readFeedbackReduction(LISTENING_SIDE.RIGHT, ricRightFitting.deviceObj)
        );
        await dispatch(
          readNoiseReduction(LISTENING_SIDE.RIGHT, ricRightFitting.deviceObj)
        );
        await dispatch(
          readEqualizer(LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj)
        );
        await dispatch(
          readEqualizer(LISTENING_SIDE.RIGHT, ricRightFitting.deviceObj)
        );

        setFetchingData(false); // Set fetchingData to false after completing the fetch
      };

      fetchNoiseReductionValues();
    }
  }, [ricRightFitting.ric_mode, ricLeftFitting.ric_mode]);

  ///for setting last amount
  // const [edgeVal, setEdgeVal] = useState(50);
  const [Link, setLink] = useState(false);

  const [Selectitem, setSelectitem] = useState(4);
  const handleChange = (event) => {
    if (event.target.value === 1 || event.target.value === 4) {
      setSelectitem(event.target.value);
    }
  };
  const handleVolumeChange = (side, value) => {
    if (value === undefined || value < 0 || value > 20) {
      console.error(`Invalid volume level: ${value}`);
      return;
    }

    console.log(
      `handleVolumeChange called with side: ${side}, value: ${value}`
    );
    const deviceObj =
      side === LISTENING_SIDE.LEFT
        ? ricLeftFitting.deviceObj
        : ricRightFitting.deviceObj;
    dispatch(setVolumeLevel(side, value, deviceObj));
  };
  const previousRightVolume = useRef(ricRightFitting.volumeLevel ?? 10);
  const previousLeftVolume = useRef(ricLeftFitting.volumeLevel ?? 10);

  return (
    <Box>
      <Box maxHeight={"60vh"}>
        <Grid xs={12} md={12} container>
          <Grid md={5.7} xs={5.7}>
            <RicFittingChartArea
              mutedHandle={mutedHandle}
              fitting={ricRightFitting}
              edgeVal={ricRightFitting?.eqValues}
            />
          </Grid>
          <Grid md={0.6} xs={0.6}>
            <Box
              sx={{
                marginTop: "20vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Select value={10} sx={{ height: "5vh", width: "5vw" }}>
                    <MenuItem value={10}>
                      <img src={MenuItemgraphImage} alt="SPEAKERIMAGE" />
                    </MenuItem>
                  </Select>
                </Box> */}
              <Box
                mt={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box mr={1}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        borderRadius: "86px",
                        width: "1.5vw",
                        height: "4px",
                        background: "#4A4A4A",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: "League spartan",
                        color: "#808080",
                        fontStyle: "normal",
                        fontWeight: "400px",
                      }}
                    >
                      50dB
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box mr={1}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        borderRadius: "86px",
                        width: "1.5vw",
                        height: "4px",
                        background: "#969696",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: "League spartan",
                        color: "#808080",
                        fontStyle: "normal",
                        fontWeight: "400px",
                      }}
                    >
                      65dB
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box mr={1}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        borderRadius: "86px",
                        width: "1.5vw",
                        height: "4px",
                        background: "#CCCCCC",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: "League spartan",
                        color: "#808080",
                        fontStyle: "normal",
                        fontWeight: "400px",
                      }}
                    >
                      80dB
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid xs={5.7} md={5.7}>
            <RicFittingChartArea
              mutedHandle={mutedHandle}
              fitting={ricLeftFitting}
              edgeVal={ricLeftFitting?.eqValues}
            />
          </Grid>
        </Grid>
        <Grid container md={12} display={"flex"} justifyContent={"center"}>
          {/* <Grid mt={1}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "League spartan",
                color: "#808080",
              }}
            >
              Fitting Formula
            </Typography>
          </Grid>
          <Grid ml={1}>
            <Select
              sx={{
                height: "5vh",
                width: "9vw",
                fontSize: "16px",
                fontFamily: "League spartan",
                color: "#4D4D4D",
              }}
              disabled={ricRightFitting.read_only || ricLeftFitting.read_only}
              value={Selectitem}
              onChange={handleChange}
            >
              <MenuItem
                sx={{
                  fontSize: "16px",
                  fontFamily: "League spartan",
                  color: "#4D4D4D",
                }}
                value={1}
              >
                DSL v5
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "16px",
                  fontFamily: "League spartan",
                  color: "#4D4D4D",
                }}
                value={2}
              >
                NAL
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "16px",
                  fontFamily: "League spartan",
                  color: "#4D4D4D",
                }}
                value={3}
              >
                1/3 Gain
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "16px",
                  fontFamily: "League spartan",
                  color: "#4D4D4D",
                }}
                value={4}
              >
                Fit 6
              </MenuItem>
            </Select>
          </Grid> */}

          {/* </FormControl> */}
        </Grid>
      </Box>
      <Box
        ml={6}
        mt={2}
        sx={{ height: "40vh", scrollbarWidth: "none" }}
        overflow={"auto"}
      >
        <Grid container xs={12} md={12}>
          <Grid
            xs={5.4}
            md={5.4}
            sx={{ height: "38vh", scrollbarWidth: "none" }}
          >
            {" "}
            {ricRightFitting.connected && (
              <Grid container xs={12} md={12}>
                <Grid xs={3} md={3}>
                  {!ricRightFitting.read_only && (
                    <SubmitButton
                      loading={autoFitRightloading}
                      onClick={() => {
                        setAutoFitRightloading(true);

                        // ✅ Reset Values Before AutoFit
                        dispatch(
                          changeSelfSpeechReduction(
                            0,
                            LISTENING_SIDE.RIGHT,
                            ricRightFitting.deviceObj
                          )
                        );
                        dispatch(
                          changeSharpnessReduction(
                            0,
                            LISTENING_SIDE.RIGHT,
                            ricRightFitting.deviceObj
                          )
                        );

                        // ✅ If Link is Active, Apply to Left Side Too
                        if (Link) {
                          setAutoFitLeftloading(true);
                          dispatch(
                            changeSelfSpeechReduction(
                              0,
                              LISTENING_SIDE.LEFT,
                              ricLeftFitting.deviceObj
                            )
                          );
                          dispatch(
                            changeSharpnessReduction(
                              0,
                              LISTENING_SIDE.LEFT,
                              ricLeftFitting.deviceObj
                            )
                          );

                          dispatch(
                            ricAutoFitDevice(
                              params.id,
                              LISTENING_SIDE.LEFT,
                              ricLeftFitting.deviceObj,
                              () => {
                                setAutoFitLeftloading(false);
                                dispatch(
                                  callSnackBar(
                                    "Auto Fit applied successfully!",
                                    SNACK_BAR_VARIETNS.suceess
                                  )
                                );
                              },
                              (err) => {
                                setAutoFitLeftloading(false);
                                dispatch(
                                  callSnackBar(
                                    err || "Auto Fit failed",
                                    SNACK_BAR_VARIETNS.error
                                  )
                                );
                              }
                            )
                          );
                        }

                        // ✅ Run AutoFit for Right Side
                        dispatch(
                          ricAutoFitDevice(
                            params.id,
                            LISTENING_SIDE.RIGHT,
                            ricRightFitting.deviceObj,
                            () => {
                              setAutoFitRightloading(false);
                              dispatch(
                                callSnackBar(
                                  "Auto Fit applied successfully!",
                                  SNACK_BAR_VARIETNS.suceess
                                )
                              );
                            },
                            (err) => {
                              setAutoFitRightloading(false);
                              dispatch(
                                callSnackBar(
                                  err || "Auto Fit failed",
                                  SNACK_BAR_VARIETNS.error
                                )
                              );
                            }
                          )
                        );
                      }}
                      variant="outlined"
                      sx={{
                        fontSize: "18px",
                        fontFamily: "League spartan",
                        fontWeight: 500,
                        padding: "8px, 4px, 8px, 4px",
                        height: "6vh",
                        width: "7vw",
                        border: "1px solid #DDDDDD",
                      }}
                      title={<Typography variant="h6">Auto Fit</Typography>}
                    />
                  )}

                  {/* <Typography variant="h6">Auto Fit</Typography> */}
                  {/* ) : (
                  <Button 
                    onClick={() => {
                      dispatch(
                        autofitDevice(
                          params.id,
                          () => {
                            dispatch(
                              callSnackBar(
                                "Device fitted as per patient audiogram.",
                                SNACK_BAR_VARIETNS.suceess
                              )
                            );
                          },
                          (err) => {
                            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                          },
                          fitting
                        )
                      );
                      setAutoFitRight(true);
                    }}
                    variant="outlined"
                    sx={{
                      fontSize: "16px",
                      fontFamily: "League spartan",
                      fontWeight: 500,
                      padding: "8px, 4px, 8px, 4px",
                      height: "6vh",
                      width: "7vw",
                      border: "1px solid #2D3B67",
                      backgroundColor: "#EDF0F7",
                    }}
                  >
                    Auto Fit{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99992 14.6668C11.6818 14.6668 14.6666 11.6821 14.6666 8.00016C14.6666 4.31826 11.6818 1.3335 7.99992 1.3335C4.31802 1.3335 1.33325 4.31826 1.33325 8.00016C1.33325 11.6821 4.31802 14.6668 7.99992 14.6668Z"
                        fill="#39AC8A"
                      />
                      <path
                        d="M5 8L7 10L11 6"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                )} */}
                  {/* <Grid > */}
                  {/* Master Gain */}
                  <Grid
                    container
                    xs={12}
                    md={12}
                    sx={{
                      display: "flex",
                      alignItems: "flex-end", // Align items to the bottom
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end", // Align to the bottom
                        alignItems: "center",
                        width: "7vw",
                        height: "100%", // Ensure it stretches to match the height of the parent
                        border: "1px solid #DDDDDD",
                        borderRadius: theme.shape.borderRadius * 0.5,
                      }}
                      p={2}
                      mt={2}
                    >
                      <ActionInputSliders
                        title="Volume"
                        disabled={
                          ricRightFitting.read_only || ricRightFitting.remote
                        }
                        min={0}
                        max={20}
                        step={1}
                        side={LISTENING_SIDE.RIGHT}
                        value={ricRightFitting.volumeLevel ?? 10} // Default to 10 if undefined
                        onChange={(x) => {
                          const stepChange = x - previousRightVolume.current;
                          previousRightVolume.current = x;
                          dispatch(
                            setVolumeLevel(
                              ricRightFitting.device_side,
                              x,
                              ricRightFitting.deviceObj
                            )
                          );
                          if (Link) {
                            const newLeftValue =
                              (ricLeftFitting.volumeLevel ?? 10) + stepChange;
                            dispatch(
                              setVolumeLevel(
                                ricLeftFitting.device_side,
                                newLeftValue,
                                ricLeftFitting.deviceObj
                              )
                            );
                            previousLeftVolume.current = newLeftValue;
                          }
                        }}
                      />
                    </Box>
                  </Grid>
                  {/* </Grid> */}
                </Grid>

                <Grid md={9} xs={9}>
                  {/* <Grid mr={1} pl={3} sx={{ width: "28vw", border: "1px solid #DDDDDD", borderRadius: "4px",minHeight: "10vh" }}> */}
                  <Box
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Noise Reduction
                      </Typography>
                      <Box sx={{ width: "23vw", minWidth: "18vw" }}>
                        <CustomSlider
                          side={LISTENING_SIDE.RIGHT}
                          disabled={ricRightFitting.read_only || fetchingData}
                          track={false}
                          value={ricRightFitting.ric_noise_reduction}
                          min={0}
                          max={3}
                          step={1}
                          marks={marks}
                          onChange={(event, value) => {
                            const stepChange = value - ricRightFitting.ric_noise_reduction;
                        
                            // Update the right slider
                            dispatch(
                              changeRicNoiseReduction(
                                value,
                                LISTENING_SIDE.RIGHT,
                                ricRightFitting.deviceObj
                              )
                            );
                        
                        //     if (Link) {
                        //       dispatch(
                        //         changeRicNoiseReduction(
                        //           ricLeftFitting.ric_noise_reduction +
                        //             (value -
                        //               ricRightFitting.ric_noise_reduction),
                        //           LISTENING_SIDE.LEFT,
                        //           ricLeftFitting.deviceObj
                        //         )
                        //       );
                        //     }
                        //   }}
                        //   valueLabelDisplay="off"
                        // />
                        if (Link) {
                          // Calculate the new value for the left slider
                          const newLeftValue = Math.max(
                            0,
                            Math.min(
                              3,
                              ricLeftFitting.ric_noise_reduction + stepChange
                            )
                          );
                    
                          // Update the left slider
                          dispatch(
                            changeRicNoiseReduction(
                              newLeftValue,
                              LISTENING_SIDE.LEFT,
                              ricLeftFitting.deviceObj
                            )
                          );
                        }
                      }}
                      valueLabelDisplay="off"
                    />
                      </Box>
                    </Box>
                  </Box>
                  {/* </Grid> */}
                  <Box
                    mt={2}
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Self Speech Reduction
                      </Typography>
                      <Box sx={{ width: "23vw", minWidth: "18vw" }}>
                        <CustomSlider
                          side={LISTENING_SIDE.RIGHT}
                          disabled={ricRightFitting.read_only || fetchingData}
                          track={false}
                          value={ricRightFitting.self_speech_reduction}
                          min={0}
                          max={3}
                          step={1}
                          marks={[
                            { value: 0, label: "Off" },
                            { value: 1, label: "Low" },
                            { value: 2, label: "Medium" },
                            { value: 3, label: "High" },
                          ]}
                          onChange={(e, value) => {
                            const stepChange = value - ricRightFitting.self_speech_reduction;
                        
                            // Update the right slider
                            dispatch(
                              changeSelfSpeechReduction(
                                value,
                                LISTENING_SIDE.RIGHT,
                                ricRightFitting.deviceObj
                              )
                            );
                        
                            if (Link) {
                              // Calculate the new value for the left slider
                              const newLeftValue = Math.max(
                                0,
                                Math.min(
                                  3,
                                  ricLeftFitting.self_speech_reduction + stepChange
                                )
                              );
                        
                              // Update the left slider
                              dispatch(
                                changeSelfSpeechReduction(
                                  newLeftValue,
                                  LISTENING_SIDE.LEFT,
                                  ricLeftFitting.deviceObj
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    mt={2}
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Feedback Cancellation
                      </Typography>
                      <Box sx={{ width: "23vw", minWidth: "20vw" }}>
                        <CustomSlider
                          side={LISTENING_SIDE.RIGHT}
                          disabled={ricRightFitting.read_only || fetchingData}
                          track={false}
                          value={ricRightFitting.feedback_cancellation}
                          min={0}
                          max={2}
                          step={1}
                          marks={[
                            { value: 0, label: "Off" },
                            { value: 1, label: "Mild" },
                            { value: 2, label: "Aggressive" },
                          ]}
                          onChange={(e, value) => {
                            const stepChange = value - ricRightFitting.feedback_cancellation;
                        
                            // Update the right slider
                            dispatch(
                              changeFeedbackReduction(
                                value,
                                LISTENING_SIDE.RIGHT,
                                ricRightFitting.deviceObj
                              )
                            );
                            if (Link) {
                              // Calculate the new value for the left slider
                              const newLeftValue = Math.max(
                                0,
                                Math.min(
                                  2,
                                  ricLeftFitting.feedback_cancellation + stepChange
                                )
                              );
                        
                              // Update the left slider
                              dispatch(
                                changeFeedbackReduction(
                                  newLeftValue,
                                  LISTENING_SIDE.LEFT,
                                  ricLeftFitting.deviceObj
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end", // Align to the bottom
                      alignItems: "center",
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Sharpness Reduction
                      </Typography>
                      <Box sx={{ width: "23vw", minWidth: "18vw" }}>
                        <CustomSlider
                          side={LISTENING_SIDE.RIGHT}
                          disabled={ricRightFitting.read_only}
                          track={false}
                          value={ricRightFitting.ric_sharpness_reduction}
                          min={0}
                          max={3}
                          step={1}
                          marks={[
                            { value: 0, label: "Off" },
                            { value: 1, label: "Low" },
                            { value: 2, label: "Medium" },
                            { value: 3, label: "High" },
                          ]}
                          onChange={(e, value) => {
                            const stepChange = value - ricRightFitting.ric_sharpness_reduction;
                        
                            // Update the right slider
                            dispatch(
                              changeSharpnessReduction(
                                value,
                                LISTENING_SIDE.RIGHT,
                                ricRightFitting.deviceObj
                              )
                            );
                            if (Link) {
                              // Calculate the new value for the left slider
                              const newLeftValue = Math.max(
                                0,
                                Math.min(
                                  3,
                                  ricLeftFitting.ric_sharpness_reduction + stepChange
                                )
                              );
                        
                              // Update the left slider
                              dispatch(
                                changeSharpnessReduction(
                                  newLeftValue,
                                  LISTENING_SIDE.LEFT,
                                  ricLeftFitting.deviceObj
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid
            xs={1}
            md={1}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {ricRightFitting.connected && ricLeftFitting.connected && (
              <Box
                pl={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!Link ? (
                  <button
                    style={{
                      border: "1px solid #DDDDDD",
                      borderRadius: "8px",
                      width: "4vw",
                      backgroundColor: "#FFFFFF",
                      height: "5vh",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setLink(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="24"
                      viewBox="0 0 33 24"
                      fill="none"
                    >
                      <path
                        d="M11.5 17H9.5C6.73858 17 4.5 14.7614 4.5 12C4.5 9.23858 6.73858 7 9.5 7H11.5M21.5 17H23.5C26.2614 17 28.5 14.7614 28.5 12C28.5 9.23858 26.2614 7 23.5 7H21.5"
                        stroke="#CCCCCC"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setLink(false);
                    }}
                    style={{
                      border: "1px solid #2D3B67",
                      borderRadius: "8px",
                      width: "4vw",
                      backgroundColor: "#EDF0F7",
                      height: "5vh",
                      cursor: "pointer",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="24"
                      viewBox="0 0 32 24"
                      fill="none"
                    >
                      <path
                        d="M13 17H11C8.23858 17 6 14.7614 6 12C6 9.23858 8.23858 7 11 7H13M19 17H21C23.7614 17 26 14.7614 26 12C26 9.23858 23.7614 7 21 7H19M11 12L21 12"
                        stroke="#2D3B67"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </Box>
            )}
          </Grid>
          {ricLeftFitting.connected && (
            <Grid
              xs={5.4}
              md={5.4}
              sx={{ height: "100%", scrollbarWidth: "none", display: "flex", alignItems: "flex-end" }}
            >
              <Grid container md={12} xs={12} sx={{ height: "100%" }}>
                <Grid xs={3} md={3}>
                  {!ricLeftFitting.read_only && (
                    <SubmitButton
                      loading={autoFitLeftloading}
                      onClick={() => {
                        setAutoFitLeftloading(true);

                        // ✅ Reset Values Before AutoFit
                        dispatch(
                          changeSelfSpeechReduction(
                            0,
                            LISTENING_SIDE.LEFT,
                            ricLeftFitting.deviceObj
                          )
                        );
                        dispatch(
                          changeSharpnessReduction(
                            0,
                            LISTENING_SIDE.LEFT,
                            ricLeftFitting.deviceObj
                          )
                        );

                        // ✅ If Link is Active, Apply to Right Side Too
                        if (Link) {
                          setAutoFitRightloading(true);
                          dispatch(
                            changeSelfSpeechReduction(
                              0,
                              LISTENING_SIDE.RIGHT,
                              ricRightFitting.deviceObj
                            )
                          );
                          dispatch(
                            changeSharpnessReduction(
                              0,
                              LISTENING_SIDE.RIGHT,
                              ricRightFitting.deviceObj
                            )
                          );

                          dispatch(
                            ricAutoFitDevice(
                              params.id,
                              LISTENING_SIDE.RIGHT,
                              ricRightFitting.deviceObj,
                              () => {
                                setAutoFitRightloading(false);
                                dispatch(
                                  callSnackBar(
                                    "Auto Fit applied successfully!",
                                    SNACK_BAR_VARIETNS.suceess
                                  )
                                );
                              },
                              (err) => {
                                setAutoFitRightloading(false);
                                dispatch(
                                  callSnackBar(
                                    err || "Auto Fit failed",
                                    SNACK_BAR_VARIETNS.error
                                  )
                                );
                              }
                            )
                          );
                        }

                        // ✅ Run AutoFit for Left Side
                        dispatch(
                          ricAutoFitDevice(
                            params.id,
                            LISTENING_SIDE.LEFT,
                            ricLeftFitting.deviceObj,
                            () => {
                              setAutoFitLeftloading(false);
                              dispatch(
                                callSnackBar(
                                  "Auto Fit applied successfully!",
                                  SNACK_BAR_VARIETNS.suceess
                                )
                              );
                            },
                            (err) => {
                              setAutoFitLeftloading(false);
                              dispatch(
                                callSnackBar(
                                  err || "Auto Fit failed",
                                  SNACK_BAR_VARIETNS.error
                                )
                              );
                            }
                          )
                        );
                      }}
                      variant="outlined"
                      sx={{
                        fontSize: "18px",
                        fontFamily: "League spartan",
                        fontWeight: 500,
                        padding: "8px, 4px, 8px, 4px",
                        height: "6vh",
                        width: "7vw",
                        border: "1px solid #DDDDDD",
                      }}
                      title={<Typography variant="h6">Auto Fit</Typography>}
                    />
                  )}

                  <Grid
                    container
                    xs={12}
                    md={12}
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "7vw",

                        height: "100%",
                        minHeight: "100%",
                        border: "1px solid #DDDDDD",
                        borderRadius: theme.shape.borderRadius * 0.5,
                        mt: 2,
                      }}
                      p={2}
                    >
                      <ActionInputSliders
                        title="Volume"
                        disabled={
                          ricLeftFitting.read_only || ricLeftFitting.remote
                        }
                        min={0}
                        max={20}
                        step={1}
                        // side={LISTENING_SIDE.LEFT}
                        value={ricLeftFitting.volumeLevel ?? 10} // Default to 10 if undefined
                        onChange={(x) => {
                          const stepChange = x - previousLeftVolume.current;
                          previousLeftVolume.current = x;
                          dispatch(
                            setVolumeLevel(
                              ricLeftFitting.device_side,
                              x,
                              ricLeftFitting.deviceObj
                            )
                          );
                          if (Link) {
                            const newRightValue =
                              (ricRightFitting.volumeLevel ?? 10) + stepChange;
                            dispatch(
                              setVolumeLevel(
                                ricRightFitting.device_side,
                                newRightValue,
                                ricRightFitting.deviceObj
                              )
                            );
                            previousRightVolume.current = newRightValue;
                          }
                        }}
                      />
                    </Box>
                  </Grid>
                  {/* </Grid> */}
                </Grid>

                <Grid xs={9} md={9}>
                  {/* <Grid mr={1} pl={3} sx={{ width: "28vw", border: "1px solid #DDDDDD", borderRadius: "4px",minHeight: "10vh" }}> */}
                  <Box
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Box sx={{ width: "23vw", minWidth: "18vw" }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "12px",
                            fontFamily: "League spartan",
                            fontWeight: "400",
                          }}
                        >
                          Noise Reduction
                        </Typography>
                        <CustomSlider
                          // side={LISTENING_SIDE.LEFT}
                          disabled={ricLeftFitting.read_only || fetchingData} // Disable slider if fetchingData is true
                          track={false}
                          value={ricLeftFitting.ric_noise_reduction}
                          min={0}
                          max={3}
                          step={1}
                          marks={marks}
                          onChange={(event, value) => {
                            const stepChange = value - ricLeftFitting.ric_noise_reduction;
                        
                            // Update the left slider
                            dispatch(
                              changeRicNoiseReduction(
                                value,
                                LISTENING_SIDE.LEFT,
                                ricLeftFitting.deviceObj
                              )
                            );
                            if (Link) {
                              // Calculate the new value for the right slider
                              const newRightValue = Math.max(
                                0,
                                Math.min(
                                  3,
                                  ricRightFitting.ric_noise_reduction + stepChange
                                )
                              );
                        
                              // Update the right slider
                              dispatch(
                                changeRicNoiseReduction(
                                  newRightValue,
                                  LISTENING_SIDE.RIGHT,
                                  ricRightFitting.deviceObj
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* </Grid> */}
                  <Box
                    mt={2}
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Self Speech Reduction
                      </Typography>
                      <Box sx={{ width: "23vw", minWidth: "18vw" }}>
                        <CustomSlider
                          // side={LISTENING_SIDE.LEFT}
                          disabled={ricLeftFitting.read_only || fetchingData} // Disable slider if fetchingData is true
                          track={false}
                          value={ricLeftFitting.self_speech_reduction}
                          min={0}
                          max={3}
                          step={1}
                          marks={[
                            { value: 0, label: "Off" },
                            { value: 1, label: "Low" },
                            { value: 2, label: "Medium" },
                            { value: 3, label: "High" },
                          ]}
                          onChange={(e, value) => {
                            dispatch(
                              changeSelfSpeechReduction(
                                value,
                                LISTENING_SIDE.LEFT,
                                ricLeftFitting.deviceObj
                              )
                            );
                            if (Link) {
                              dispatch(
                                changeSelfSpeechReduction(
                                  ricRightFitting.self_speech_reduction +
                                    (value -
                                      ricLeftFitting.self_speech_reduction),
                                  LISTENING_SIDE.RIGHT,
                                  ricRightFitting.deviceObj
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    mt={2}
                    sx={{
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Feedback Cancellation
                      </Typography>
                      <Box sx={{ width: "23vw", minWidth: "20vw" }}>
                        <CustomSlider
                          // side={LISTENING_SIDE.LEFT}
                          disabled={ricLeftFitting.read_only || fetchingData} // Disable slider if fetchingData is true
                          track={false}
                          value={ricLeftFitting.feedback_cancellation}
                          min={0}
                          max={2}
                          step={1}
                          marks={[
                            { value: 0, label: "Off" },
                            { value: 1, label: "Mild" },
                            { value: 2, label: "Aggressive" },
                          ]}
                          onChange={(e, value) => {
                            dispatch(
                              changeFeedbackReduction(
                                value,
                                LISTENING_SIDE.LEFT,
                                ricLeftFitting.deviceObj
                              )
                            );
                            if (Link) {
                              dispatch(
                                changeFeedbackReduction(
                                  ricRightFitting.feedback_cancellation +
                                    (value -
                                      ricLeftFitting.feedback_cancellation),
                                  LISTENING_SIDE.RIGHT,
                                  ricRightFitting.deviceObj
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end", // Align to the bottom
                      alignItems: "center",
                      width: "28vw",
                      border: "1px solid #DDDDDD",
                      borderRadius: "4px",
                      minHeight: "10vh",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "12px",
                          fontFamily: "League spartan",
                          fontWeight: "400",
                        }}
                      >
                        Sharpness Reduction
                      </Typography>
                      <Box sx={{ width: "23vw", minWidth: "18vw" }}>
                        <CustomSlider
                          // side={LISTENING_SIDE.LEFT}
                          disabled={ricLeftFitting.read_only || fetchingData} // Disable slider if fetchingData is true
                          track={false}
                          value={ricLeftFitting.ric_sharpness_reduction}
                          min={0}
                          max={3}
                          step={1}
                          marks={[
                            { value: 0, label: "Off" },
                            { value: 1, label: "Low" },
                            { value: 2, label: "Medium" },
                            { value: 3, label: "High" },
                          ]}
                          onChange={(e, value) => {
                            dispatch(
                              changeSharpnessReduction(
                                value,
                                LISTENING_SIDE.LEFT,
                                ricLeftFitting.deviceObj
                              )
                            );
                            if (Link) {
                              dispatch(
                                changeSharpnessReduction(
                                  ricRightFitting.ric_sharpness_reduction +
                                    (value -
                                      ricLeftFitting.ric_sharpness_reduction),
                                  LISTENING_SIDE.RIGHT,
                                  ricRightFitting.deviceObj
                                )
                              );
                            }
                          }}
                          valueLabelDisplay="off"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default RicBasicFittingAreaController;

// IGi=0 for Hi<20dB HL
// IGi=0.6(Hi-20) for 20≤Hi≤60dB HL
// IGi=0.8Hi-23 for Hi > 60dB HL
