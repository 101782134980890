// const ReadRicDataFromDevice = async (command, side, deviceObj) => {
//   try {
//     if (!deviceObj) {
//       console.error("deviceObj is undefined for", side);
//       return null;
//     }

//     const device = deviceObj.device?.device || deviceObj.device || deviceObj;

//     if (!device || !device.gatt) {
//       console.error("No valid Bluetooth device found!");
//       return null;
//     }

//     const SERVICE_UUID = "0000fff0-0000-1000-8000-00805f9b34fb";
//     const CHARACTERISTIC_UUID_WRITE = "0000fff2-0000-1000-8000-00805f9b34fb";
//     const CHARACTERISTIC_UUID_READ = "0000fff1-0000-1000-8000-00805f9b34fb";

//     const service = await device.gatt.getPrimaryService(SERVICE_UUID);
//     const characteristicWrite = await service.getCharacteristic(CHARACTERISTIC_UUID_WRITE);
//     const characteristicRead = await service.getCharacteristic(CHARACTERISTIC_UUID_READ);

//     // Start notifications separately for each device
//     await characteristicRead.startNotifications();
//     console.log(`Notifications started for ${side} device.`);

//     // Convert command to Uint8Array and send it
//     const dataArray = command.split(" ").map(byte => parseInt(byte, 16)).filter(byte => !isNaN(byte));
//     if (dataArray.length === 0) {
//       console.error("Invalid command format:", `${command}`);
//       return null;
//     }
//     const dataBuffer = new Uint8Array(dataArray);
//     await characteristicWrite.writeValue(dataBuffer);
//     console.log(`Command successfully sent to ${side} device:`, `${command}`);

//     // Create a unique event listener for each device
//     return new Promise((resolve, reject) => {
//       const onValueChanged = (event) => {
//         const value = event.target.value;
//         const decodedValue = Array.from(new Uint8Array(value.buffer))
//           .map(byte => byte.toString(16).padStart(2, '0'))
//           .join(' ');

//         console.log(`Received response from ${side} device:`, decodedValue);

//         characteristicRead.removeEventListener('characteristicvaluechanged', onValueChanged); // Remove listener for this request
//         resolve(decodedValue); // Resolve the promise
//       };

//       characteristicRead.addEventListener('characteristicvaluechanged', onValueChanged);

//       // Set a timeout to reject the promise if no response is received within a certain time
//       setTimeout(() => {
//         characteristicRead.removeEventListener('characteristicvaluechanged', onValueChanged);
//         reject(new Error(`Timeout waiting for response from ${side} device`));
//       }, 5000); // 5 seconds timeout
//     });
//   } catch (error) {
//     console.error(`Error reading data from ${side} device:`, error);
//     return null;
//   }
// };

// export default ReadRicDataFromDevice;


// Queue to hold the commands
const commandQueue = [];
let isProcessing = false;

const processQueue = async () => {
  if (isProcessing || commandQueue.length === 0) return;

  isProcessing = true;
  const { command, side, deviceObj, resolve, reject } = commandQueue.shift();

  try {
    const device = deviceObj.device?.device || deviceObj.device || deviceObj;

    if (!device || !device.gatt) {
      throw new Error("No valid Bluetooth device found!");
    }

    const SERVICE_UUID = "0000fff0-0000-1000-8000-00805f9b34fb";
    const CHARACTERISTIC_UUID_WRITE = "0000fff2-0000-1000-8000-00805f9b34fb";
    const CHARACTERISTIC_UUID_READ = "0000fff1-0000-1000-8000-00805f9b34fb";

    const service = await device.gatt.getPrimaryService(SERVICE_UUID);
    const characteristicWrite = await service.getCharacteristic(CHARACTERISTIC_UUID_WRITE);
    const characteristicRead = await service.getCharacteristic(CHARACTERISTIC_UUID_READ);

    await characteristicRead.startNotifications();
    console.log(`Notifications started for ${side} device.`);

    const dataArray = command.split(" ").map(byte => parseInt(byte, 16)).filter(byte => !isNaN(byte));
    if (dataArray.length === 0) {
      throw new Error("Invalid command format");
    }
    const dataBuffer = new Uint8Array(dataArray);
    await characteristicWrite.writeValue(dataBuffer);
    console.log(`Command successfully sent to ${side} device:`, command);

    const onValueChanged = (event) => {
      const value = event.target.value;
      const decodedValue = Array.from(new Uint8Array(value.buffer))
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join(' ');

      console.log(`Received response from ${side} device:`, decodedValue);

      characteristicRead.removeEventListener('characteristicvaluechanged', onValueChanged);
      resolve(decodedValue);
      isProcessing = false;
      processQueue();
    };

    characteristicRead.addEventListener('characteristicvaluechanged', onValueChanged);

    setTimeout(() => {
      characteristicRead.removeEventListener('characteristicvaluechanged', onValueChanged);
      reject(new Error(`Timeout waiting for response from ${side} device`));
      isProcessing = false;
      processQueue();
    }, 5000);
  } catch (error) {
    console.error(`Error reading data from ${side} device:`, error);
    reject(error);
    isProcessing = false;
    processQueue();
  }
};

const ReadRicDataFromDevice = (command, side, deviceObj) => {
  return new Promise((resolve, reject) => {
    commandQueue.push({ command, side, deviceObj, resolve, reject });
    processQueue();
  });
};

export default ReadRicDataFromDevice;