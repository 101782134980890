import { Add, Remove } from "@mui/icons-material";
import {
  Box,
  Slider,
  SliderThumb,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { memo, useEffect, useState } from "react";

const CustomSlider = styled(Slider)(({ theme, side }) => ({
  maxHeight: "28vh",
  height: "35vh",
  minHeight: "23vh",
  minWidth: "2px",
  maxWidth: "10px",
  color: side ? "#C24747" : "#1265A1",
  width: "5px",

  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#F5F4F4" : "#F5F4F4",
    opacity: theme.palette.mode === "dark" ? undefined : 1,
    height: 4
  },
}));
const CustomThumb = memo((props) => {
  const { children, style, attualVal, baseVal, ...other } = props;

  const activeDataIndex = attualVal < baseVal ? 1 : 0;
  return (
    <SliderThumb
      {...other}
      style={{
        ...style,
        display: other["data-index"] == activeDataIndex ? "none" : "block",
        ":before": {
          display: "none",
        },
      }}
    >
      {children}
    </SliderThumb>
  );
});

const RicBothSideSlider = ({
  value,
  baseVal = 0,
  onChange,
  min = 0,
  max = 10,
  step = 1,
  disabled,
  side,
}) => {
  const calculateVal = (arr) => {
    if (arr[0] < baseVal) {
      return arr[0];
    }
    if (arr[1] > baseVal) {
      return arr[1];
    }
    return baseVal;
  };

  const [val, setVal] = useState(
    value < baseVal ? [value, baseVal] : [baseVal, value]
  );

  useEffect(() => {
    setVal(value < baseVal ? [value, baseVal] : [baseVal, value]);
  }, [value]);

  return (
    <Box>
      <Box flexDirection="row">
        <CustomSlider
          orientation="vertical"
          disabled={disabled}
          side={side}
          slots={{
            thumb: (props) => {
              return (
                <CustomThumb
                  {...props}
                  valueArr={val}
                  baseVal={baseVal}
                  attualVal={calculateVal(val)}
                />
              );
            },
          }}
          max={max}
          onChange={(e, newVal) => {
            const temp = [...newVal];

            if (newVal[0] > baseVal) {
              temp[0] = baseVal;
              temp[1] = newVal[0];
            } else if (newVal[0] < baseVal && temp[1] > baseVal) {
              temp[1] = baseVal;
            }

            if (newVal[1] < baseVal) {
              temp[1] = baseVal;
              temp[0] = newVal[1];
            } else if (newVal[1] > baseVal && temp[0] < baseVal) {
              temp[0] = baseVal;
            }

            onChange?.(calculateVal(temp));
            setVal(temp);
          }}
          min={min}
          value={val}
        />
      </Box>
    </Box>
  );
};
export default memo(RicBothSideSlider);
