import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Grid,
  Paper,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  useTheme,
  IconButton,
  Select,
} from "@mui/material";
import { CenteredBox } from "../../../components/layouts/common/boxes";
import LeftImg from "../../../assets/images/Left (1).png";
import RightImg from "../../../assets/images/Left (2).png";

import { useDispatch, useSelector } from "react-redux";
import {
  DEVICES,
  DEVICES_NAME,
  FITTING_BUTTON,
  LISTENING_SIDE,
  MANAGE_PROGRAM_NAME,
} from "../../../utils/patient.constants";
import NoDataComponent from "../../../components/layouts/NoDataComponent";
import {
  CheckCircle,
  Circle,
  Error,
  VolumeOff,
  VolumeUp,
} from "@mui/icons-material";
import CoonectDeviceModule from "../../../components/bluetooth/CoonectDeviceModule";
import {
  applyForRemoteFitting,
  autofitDevice,
  changeByteValue,
  changeDeviceCompatiblity,
  changeDeviceSelection,
  changeDeviceSideSelection,
  changeFittingData,
  changeLoadinfMessages,
  changeMode,
  changeNoiseReduction,
  changeProfileId,
  connectDevice,
  onWriteFunctionChange,
  readDataFromDevice,
  resetDevice,
  resetReducer,
  resetToDefault,
  setModeData,
} from "../../../store/actions/fittingAction";
import SubmitButton from "../../../components/button/SubmitButton";
import BasicFittingAreaController from ".././BasicFittingAreaController";
import AutoFitDeviceButton from ".././AutoFitDeviceButton";
import {
  findMediunsInBulk,
  findObjectKeyByValue,
  mapValues,
} from "../../../utils/helper";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate, useParams } from "react-router-dom";
import {
  addPatientProfileApi,
  getPatientProfileByIdApi,
  updatePatientProfileField,
} from "../../../apis/patientprofile.api";
import { callApiAction } from "../../../store/actions/commonAction";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getPatientByIdApi } from "../../../apis/patient.api";
import { GAIN_POINTS, WDRC_circuit_gain } from "../../../utils/j10.helper";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants";
import ConfigAssistantController from ".././ConfigAssistantController";
import { closeModal, openModal } from "../../../store/actions/modalAction";
import CompleteSessionController from ".././CompleteSessionController";
import SAVEIMAGE from "../../../assets/images/save.svg";
import RESETIMAGE from "../../../assets/images/reset.svg";
import READIMAGE from "../../../assets/images/read.svg";
import ManageProgramController from ".././ManageProgramController";
import { generateValues, generateValues4 } from "../../../utils/Average4Values";
import RicBasicFittingAreaController from "./RicBasicFittingAreaController";
import {
  changeFeedbackReduction,
  changeRicMode,
  disconnectRicDevice,
  readBatteryLevel,
  readEqualizer,
  readNoiseReduction,
  setEqualizer,
  setRicFittingData,
  toggleMute,
} from "../../../store/actions/ricFittingActions";
import RicFineTunningController from "./RicFineTunningController";
import RicManageProgramController from "./RicManageProgramController";
import RicCompleteSessionController from "./RicCompleteSessionController";
import CustomDialog from "../../../components/layouts/common/CustomDialog";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ConnectButton = ({ loading, connected, onClick, disconnect }) => {
  if (!connected) {
    return (
      <SubmitButton
        loading={loading}
        disabled={loading}
        variant="outlined"
        onClick={onClick}
        title={"Connect"}
        style={{ backgroundColor: "white" }}
      />
    );
  }
};
const ConncetionScreen = ({ show, disabled, setValue, data }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { fittingLeft } = useSelector((state) => state);
  const { fitting } = useSelector((state) => state);
  // useEffect(() => {
  //   return () => {
  //     if (fitting.connected && !fitting.read_only) {
  //       dispatch(disconnectRicDevice(fitting.device_side));
  //     } else {
  //       dispatch(resetToDefault(fitting.device_side));
  //     }

  //     if (fittingLeft.connected && !fittingLeft.read_only) {
  //       dispatch(disconnectRicDevice(fittingLeft.device_side));
  //     } else {
  //       dispatch(resetToDefault(fittingLeft.device_side));
  //     }
  //   };
  // }, []);

  useEffect(() => {
    console.log(
      "this is device side right",
      data?.right_fitted_device?.device_type
    );
    console.log(
      "this is device side left",
      data?.left_fitted_device?.device_type
    );
    dispatch(
      changeDeviceSelection(
        data?.right_fitted_device?.device_type,
        LISTENING_SIDE.RIGHT
      )
    );
    dispatch(
      changeDeviceSelection(
        data?.left_fitted_device?.device_type,
        LISTENING_SIDE.LEFT
      )
    );
  }, []);

  return (
    <>
      <Box p={1} sx={{ height: "100vh" }}>
        <Grid
          container
          md={12}
          xs={12}
          sx={{ border: "1px solid #DDDDDD", borderRadius: "10px" }}
        >
          <Grid xs={6} md={6}>
            <Paper
              sx={{
                backgroundColor: "#FFF6F6",
                minHeight: "80vh",
                maxHeight: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box mt={6}>
                  {data?.right_fitted_device?.selected && (
                    <Typography
                      sx={{
                        color: "#C24747",
                        fontFamily: "League spartan",
                        fontSize: "42px",
                        fontWeight: "bold",
                        lineHeight: "40px",
                      }}
                    >
                      {fitting?.device_type === DEVICES.BTE_OPTIMA
                        ? "BTE OPTIMA"
                        : fitting?.device_type === DEVICES.BTE_PRIME
                        ? "BTE PRIME"
                        : fitting?.device_type === DEVICES.RIC_OPTIMA
                        ? "RIC OPTIMA"
                        : ""}
                    </Typography>
                  )}
                </Box>
                <Box mt={6}>
                  <img
                    src={RightImg}
                    style={{
                      opacity: fitting.connected ? "1" : " 0.25",
                      width: "280px",
                      height: "33vh",
                    }}
                  />
                </Box>
                <Box mt={6}>
                  <CoonectDeviceModule
                    onConnectWithDevice={(data, deviceInfo, disconnectFun) => {
                      dispatch(
                        connectDevice(
                          data,
                          deviceInfo,
                          disconnectFun,
                          fitting.device_side
                        )
                      );
                    }}
                    Component={
                      data?.right_fitted_device?.selected
                        ? ConnectButton
                        : () => {
                            return <></>;
                          }
                    }
                    onLoadingChange={(loader, message) => {
                      dispatch(
                        changeLoadinfMessages(
                          loader,
                          message,
                          fitting.device_side
                        )
                      );
                    }}
                    onEnableChange={(val) =>
                      dispatch(
                        changeDeviceCompatiblity(val, fitting.device_side)
                      )
                    }
                    onWriteFunctionEnabled={(fun) =>
                      dispatch(onWriteFunctionChange(fun, fitting.device_side))
                    }
                    onDisconnect={() =>
                      dispatch(disconnectRicDevice(fitting.device_side))
                    }
                    fitting={fitting}
                  />
                </Box>
                {!fitting.connected && (
                  <Typography
                    fontWeight={500}
                    color="gray"
                    variant="h6"
                    mt={2}
                    mb={2}
                  >
                    {fitting.loadingMessage}
                  </Typography>
                )}
                {fitting.connected && (
                  <>
                    <Box mt={3}>
                      <Typography display={"flex"} justifyContent={"center"}>
                        {/* <CheckCircleIcon/> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="49"
                          viewGrid="0 0 48 49"
                          fill="none"
                        >
                          <path
                            d="M24 44.5C35.0457 44.5 44 35.5457 44 24.5C44 13.4543 35.0457 4.5 24 4.5C12.9543 4.5 4 13.4543 4 24.5C4 35.5457 12.9543 44.5 24 44.5Z"
                            fill="#39AC8A"
                          />
                          <path
                            d="M15 24.5L21 30.5L33 18.5"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Typography>
                      <Typography
                        sx={{
                          color: "#39AC8A",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        Connected
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
              {fitting.connected && (
                <Box>
                  <Button
                    onClick={() => {
                      dispatch(disconnectRicDevice(fitting.device_side));
                    }}
                    sx={{
                      textDecoration: "underline",
                      fontSize: "14px",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h6">Disconnect</Typography>
                  </Button>
                </Box>
              )}
            </Paper>
          </Grid>
          <Grid xs={6} md={6}>
            <Paper
              sx={{
                backgroundColor: "#F6FBFE",
                minHeight: "80vh",
                maxHeight: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box mt={6}>
                  {data?.right_fitted_device?.selected && (
                    <Typography
                      sx={{
                        color: "#1265A1",
                        fontFamily: "League spartan",
                        fontSize: "32px",
                        fontWeight: "bold",
                        lineHeight: "40px",
                      }}
                    >
                      {data?.right_fitted_device?.device_type ===
                      DEVICES.BTE_OPTIMA
                        ? "BTE OPTIMA"
                        : data?.right_fitted_device?.device_type ===
                          DEVICES.BTE_PRIME
                        ? "BTE PRIME"
                        : data?.right_fitted_device?.device_type ===
                          DEVICES.RIC_OPTIMA
                        ? "RIC OPTIMA"
                        : ""}
                    </Typography>
                  )}
                </Box>
                <Box mt={6}>
                  <img
                    src={LeftImg}
                    style={{
                      opacity: fittingLeft.connected ? "1" : " 0.25",
                      width: "280px",
                      height: "33vh",
                    }}
                  />
                </Box>
                <Box mt={6}>
                  <CoonectDeviceModule
                    onConnectWithDevice={(data, deviceInfo, disconnectFun) => {
                      dispatch(
                        connectDevice(
                          data,
                          deviceInfo,
                          disconnectFun,
                          fittingLeft.device_side
                        )
                      );
                    }}
                    Component={
                      data?.right_fitted_device?.selected
                        ? ConnectButton
                        : () => {
                            return <></>;
                          }
                    }
                    onLoadingChange={(loader, message) => {
                      dispatch(
                        changeLoadinfMessages(
                          loader,
                          message,
                          fittingLeft.device_side
                        )
                      );
                    }}
                    onEnableChange={(val) =>
                      dispatch(
                        changeDeviceCompatiblity(val, fittingLeft.device_side)
                      )
                    }
                    onWriteFunctionEnabled={(fun) =>
                      dispatch(
                        onWriteFunctionChange(fun, fittingLeft.device_side)
                      )
                    }
                    onDisconnect={() =>
                      dispatch(disconnectRicDevice(fittingLeft.device_side))
                    }
                    fitting={fittingLeft}
                  />
                </Box>
                {!fittingLeft.connected && (
                  <Typography
                    fontWeight={500}
                    color="gray"
                    variant="h6"
                    mt={2}
                    mb={2}
                  >
                    {fittingLeft.loadingMessage}
                  </Typography>
                )}
                {fittingLeft.connected && (
                  <>
                    <Typography
                      sx={{
                        fontFamily: "League spartan",
                        fontSize: "20px",
                        fontWeight: 400,
                      }}
                    >
                      02567(Serial Number)
                    </Typography>

                    <Box mt={3}>
                      <Typography display={"flex"} justifyContent={"center"}>
                        {/* <CheckCircleIcon/> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="48"
                          height="49"
                          viewGrid="0 0 48 49"
                          fill="none"
                        >
                          <path
                            d="M24 44.5C35.0457 44.5 44 35.5457 44 24.5C44 13.4543 35.0457 4.5 24 4.5C12.9543 4.5 4 13.4543 4 24.5C4 35.5457 12.9543 44.5 24 44.5Z"
                            fill="#39AC8A"
                          />
                          <path
                            d="M15 24.5L21 30.5L33 18.5"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Typography>
                      <Typography
                        sx={{
                          color: "#39AC8A",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        Connected
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
              {fittingLeft.connected && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={() => {
                      dispatch(disconnectRicDevice(fittingLeft.device_side));
                    }}
                    sx={{
                      textDecoration: "underline",
                      fontSize: "14px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography variant="h6">Disconnect</Typography>
                  </Button>
                </Box>
              )}
            </Paper>
          </Grid>
          <Box
            p={4}
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              disabled={!(fitting.connected || fittingLeft.connected)}
              variant="contained"
              onClick={() => {
                setValue(1);
              }}
              sx={{
                fontSize: "16px",
                width: "150px",
                height: "40px",
                color: "white",
                fontFamily: "League spartan",
              }}
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

const RicFittingMainController = () => {
  const dispatch = useDispatch();
  const { fitting } = useSelector((state) => state);
  const { fittingLeft } = useSelector((state) => state);
  const { ricLeftFitting } = useSelector((state) => state);
  const { ricRightFitting } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [value, setValue] = useState(1);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const [isRest, setIsReset] = useState(false);

  const [showBackDialog, setShowBackDialog] = useState(false);

  // const handleBackClick = () => {
  //   if (ricLeftFitting.read_only || ricRightFitting.read_only) {
  //     navigate(`/patient-details/${params.id}`);
  //     return; // Exit the function early
  //   }

  //     setselectButton(FITTING_BUTTON.COMPLETE_SESSION);
  //     dispatch(
  //       openModal(
  //         <RicCompleteSessionController
  //           onCompleteSession={onCompleteSession}
  //           id="complete"
  //         />,
  //         "sm",
  //         false,
  //         "complete"
  //       )
  //     );
  //   // dispatch(
  //   //   openModal(
  //   //     <CustomDialog
  //   //       id="back-confirmation"
  //   //       title="In-Person Fitting"
  //   //       confirmText="Yes"
  //   //       closeText="No"
  //   //       onSubmit={handleConfirmBack}
  //   //       onClose={handleCloseBackDialog}
  //   //     >
  //   //       <Box
  //   //         sx={{
  //   //           display: "flex",
  //   //           height: "50vh",
  //   //           alignItems: "center",
  //   //           justifyContent: "center",
  //   //           flexDirection: "column",
  //   //         }}
  //   //       >
  //   //         <Typography color={"black"} variant="h6" fontSize={"30px"} gutterBottom>
  //   //           Are you sure you don't want to complete the session?
  //   //         </Typography>
  //   //       </Box>
  //   //     </CustomDialog>,
  //   //     "sm",
  //   //     false,
  //   //     "back-confirmation"
  //   //   )
  //   // );
  // };
  const handleBackClick = () => {
    if (ricLeftFitting.read_only || ricRightFitting.read_only) {
      navigate(`/patient-details/${params.id}`);
      return; // Exit the function early
    }

    setselectButton(FITTING_BUTTON.COMPLETE_SESSION);
    dispatch(
      openModal(
        <RicCompleteSessionController
          onCompleteSession={onCompleteSession}
          id="complete"
          isBackButton={true}
          onCancel={() => {
            setselectButton(FITTING_BUTTON.BASIC_FITTING);
            setValue(1);
          }}
        />,
        "sm",
        false,
        "complete"
      )
    );
  };

  const handleCloseBackDialog = () => {
    dispatch(closeModal("back-confirmation"));
  };

  const handleConfirmBack = () => {
    setShowBackDialog(false);
    if (ricRightFitting.connected && !ricRightFitting.remote) {
      dispatch(disconnectRicDevice(ricRightFitting.device_side));
    }
    if (ricLeftFitting.connected && !ricLeftFitting.remote) {
      dispatch(disconnectRicDevice(ricLeftFitting.device_side));
    }
    navigate(`/patient-details/${params.id}`);
  };

  useEffect(() => {
    console.log("showBackDialog state:", showBackDialog);
  }, [showBackDialog]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(resetReducer());
    };
  }, []);

  useEffect(() => {
    if (
      !ricRightFitting.connected &&
      !ricRightFitting.read_only &&
      !ricRightFitting.remote &&
      params.fitid == null &&
      !ricLeftFitting.connected &&
      !ricLeftFitting.read_only &&
      !ricLeftFitting.remote &&
      params.fitid == null
    ) {
      navigate(`/patient-details/${params.id}`);
    }
  }, [ricRightFitting, ricLeftFitting]);

  const [data, setData] = useState({});

  // const onAutoFit = (Newfitting, setAutoFitloading, selectAlgo) => {
  //   dispatch(
  //     autofitDevice(
  //       params.id,
  //       () => {
  //         dispatch(
  //           callSnackBar(
  //             "Device fitted as per patient audiogram.",
  //             SNACK_BAR_VARIETNS.suceess
  //           )
  //         );
  //         setAutoFitloading(false);
  //       },
  //       (err) => {
  //         dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
  //       },
  //       Newfitting,
  //       selectAlgo,
  //       () => {
  //         setShouldUpdate(true);
  //       }
  //     )
  //   );
  // };

  // useEffect(() => {
  //   if (shouldUpdate) {
  //     updateData();
  //     setShouldUpdate(false);
  //   }
  // }, [shouldUpdate, fitting, fittingLeft]);

  // const writeDataToDevice = (data, deviceSide) => {
  //   console.log("fetched data is writting into the device", data, deviceSide);
  //   const deviceObj = deviceSide === LISTENING_SIDE.LEFT ? ricLeftFitting.deviceObj : ricRightFitting.deviceObj;
  //   if (deviceObj) {
  //     // Write the data to the device
  //     dispatch(setEqualizer(deviceSide, data.eq_values, deviceObj));
  //     dispatch(changeRicMode(data.mode_type, deviceSide, deviceObj));
  //     dispatch(changeProfileId(data._id, deviceSide));
  //     dispatch(changeNoiseReduction(data.noise_reduction, deviceSide, deviceObj));
  //     dispatch(changeFeedbackReduction(data.feedback_cancellation, deviceSide, deviceObj));
  //     // Add other necessary actions to write data to the device
  //   }
  // };

  const onFineTunning = () => {
    setselectButton(FITTING_BUTTON.FINE_TUNNING);
  };

  const [completeLoading, setCompleteLoading] = useState(false);
  const [saveLoadin, setSaveLoading] = useState(false);
  const [isLeftMuted, setIsLeftMuted] = useState(false);
  const [isRightMuted, setIsRightMuted] = useState(false);
  const handleLeftToggleMute = () => {
    dispatch(
      toggleMute(!isLeftMuted, LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj)
    );
    setIsLeftMuted(!isLeftMuted);
  };
  const handleRightToggleMute = () => {
    dispatch(
      toggleMute(!isRightMuted, LISTENING_SIDE.RIGHT, ricRightFitting.deviceObj)
    );
    setIsRightMuted(!isRightMuted);
  };

  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (complete) {
      navigate(`/patient-details/${params.id}`);
      if (
        (ricRightFitting.connected &&
          !ricRightFitting.read_only &&
          !ricRightFitting.remote) ||
        (ricLeftFitting.connected &&
          !ricLeftFitting.read_only &&
          !ricLeftFitting.remote)
      ) {
        dispatch(disconnectRicDevice(LISTENING_SIDE.LEFT));
        dispatch(disconnectRicDevice(LISTENING_SIDE.RIGHT));
      }
      setComplete(false);
    }
  }, [complete]);

  const fetchById = (id) => {
    setLoading(true);
    console.log(`🔍 Fetching data for ID: ${id}`);

    dispatch(
      callApiAction(
        async () => await getPatientProfileByIdApi({ id }),
        async (response) => {
          console.log("✅ API Response Received:", response);

          if (response && response._id) {
            dispatch(
              changeFittingData(response, params.readonly, response.device_side)
            );
            setShouldUpdate(true);

            if (response.device_type === DEVICES.RIC_OPTIMA) {
              console.log(
                "📌 Device is RIC_OPTIMA, updating reducer and writing data..."
              );
              dispatch(
                setRicFittingData(
                  response,
                  params.readonly,
                  response.device_side
                )
              ); // 🚀 This handles everything now
            }
          } else {
            console.error("❌ Invalid response data:", response);
          }
          if (response.device_side === LISTENING_SIDE.LEFT) {
            if (!fittingLeft.connected && !params.readonly) {
              dispatch(applyForRemoteFitting(LISTENING_SIDE.LEFT));
            }
          } else {
            if (!fitting.connected && !params.readonly) {
              dispatch(applyForRemoteFitting(LISTENING_SIDE.RIGHT));
            }
          }
          setLoading(false);
        },
        (err) => {
          console.error("🚨 Error fetching data:", err);
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (params.fitid) {
      fetchById(params.fitid);
    }

    if (params.fitid1) {
      fetchById(params.fitid1);
    }
  }, [params.fitid, params.fitid1]);

  const [selectButton, setselectButton] = useState(
    FITTING_BUTTON.BASIC_FITTING
  );

  const gatherFittingData = (fitting) => {
    console.log("fitting dataaaa", fitting);

    return {
      patient_id: params.id,
      device_id: fitting.deviceInfo?.id,
      device_name: fitting.deviceInfo?.name,
      device_side: fitting.device_side,
      device_type: fitting.device_type,
      eq_values: fitting.eqValues,
      mode_type: fitting.ric_mode,
      mpo_value: fitting.mpoValue,

      mode_data: Object.values(fitting.tempModes).map((mode) => ({
        eq_values: mode.eqValues,
        sharpness: mode.ric_sharpness_reduction,
        feedback_cancellation: mode.feedbackCancellation,
        self_speech_reduction: mode.selfSpeechReduction,
        master_gain: mode.ricMasterGain,
        noise_reduction: mode.noiseReduction,
        mpo_value: mode.mpoValue,
        // mode_type: mode.mode_type,
      })),
      noise_reduction: fitting.ric_noise_reduction,

      self_speech_reduction: fitting.self_speech_reduction,
      sharpness: fitting.ric_sharpness_reduction,
      feedback_cancellation: fitting.feedback_cancellation,
      master_gain: fitting.ric_master_gain,
      remarks: fitting.remarks,
    };
  };

  const onSaveFittingHelper = async (
    complete,
    callBack = () => {},
    callBack1 = () => {},
    nextStep,
    fittingData
  ) => {
    console.log("Fitting Data:", fittingData);

    try {
      const passData = {
        patient_id: params.id,
        device_id: fittingData.device_id,
        device_name: fittingData.device_name,
        device_side: fittingData.device_side,
        device_type: fittingData.device_type,
        eq_values: fittingData.eq_values,
        mode_data: fittingData.mode_data,
        noise_reduction: fittingData.noise_reduction,
        remarks: fittingData.remarks,
        mpo_value: fittingData.mpo_value,
        self_speech_reduction: fittingData.self_speech_reduction,
        sharpness: fittingData.sharpness,
        mode_type: fittingData.mode_type,
        feedback_cancellation: fittingData.feedback_cancellation,
        master_gain: fittingData.master_gain,
      };

      if (fittingData.profile_id) {
        dispatch(
          callApiAction(
            async () =>
              await updatePatientProfileField({
                id: fittingData.profile_id,
                ...passData,
              }),
            async (response) => {
              if (nextStep) {
                callBack();
                callBack1();
                dispatch(
                  callSnackBar(
                    " Fitting Saved Successfully",
                    SNACK_BAR_VARIETNS.suceess
                  )
                );
              }
              if (complete === true) setCompleteLoading(false);
              else {
                setSaveLoading(false);
              }
            },
            (err) => {
              if (complete === true) setCompleteLoading(false);
              else {
                setSaveLoading(false);
              }
              dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
            }
          )
        );
      } else {
        console.log("2");
        dispatch(
          callApiAction(
            async () => await addPatientProfileApi(passData),
            async (response) => {
              dispatch(changeProfileId(response._id, fittingData.device_side));

              if (nextStep) {
                callBack();
                callBack1();
                // if (complete === true) {
                //   navigate(`/patient-details/${params.id}`);
                // }
                dispatch(
                  callSnackBar(
                    " Fitting Saved Successfully",
                    SNACK_BAR_VARIETNS.suceess
                  )
                );
                // if (complete === true) {
                //   navigate(`/patient-details/${params.id}`);
                // }
              }
              if (complete === true) setCompleteLoading(false);
              else {
                setSaveLoading(false);
              }
            },
            (err) => {
              if (complete === true) setCompleteLoading(false);
              else {
                setSaveLoading(false);
              }
              dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
            }
          )
        );
      }
    } catch (error) {
      console.error("API Error:", error);
      dispatch(callSnackBar("Error saving data", SNACK_BAR_VARIETNS.error));
      callBack1();
    }
  };
  //   const onCompleteSession = async (e, rightFitting, leftFitting) => {
  //     e.preventDefault();
  //     setCompleteLoading(true);

  //     console.log("✅ Latest remarks before saving:", rightFitting.remarks, leftFitting.remarks);

  //     const fittingDataRight = gatherFittingData(rightFitting, rightFitting.remarks);
  //     const fittingDataLeft = gatherFittingData(leftFitting, leftFitting.remarks);

  //     if (rightFitting.connected) {
  //       await onSaveFittingHelper(
  //         true,
  //         () => {
  //           dispatch(disconnectRicDevice(rightFitting.device_side));
  //         },
  //         () => {},
  //         true,
  //         fittingDataRight
  //       );
  //     }

  //     if (leftFitting.connected) {
  //       await onSaveFittingHelper(
  //         true,
  //         () => {
  //           dispatch(disconnectRicDevice(leftFitting.device_side));
  //         },
  //         () => {},
  //         true,
  //         fittingDataLeft
  //       );
  //     }

  //     setCompleteLoading(false);
  //     dispatch(closeModal("complete"));
  //     setComplete(true);
  // };

  const onCompleteSession = async (e, rightFitting, leftFitting) => {
    e.preventDefault();
    setCompleteLoading(true);

    console.log(
      "✅ Latest remarks before saving:",
      rightFitting.remarks,
      leftFitting.remarks
    );

    const fittingDataRight = gatherFittingData(
      rightFitting,
      rightFitting.remarks
    );
    const fittingDataLeft = gatherFittingData(leftFitting, leftFitting.remarks);

    let success = true;

    if (rightFitting.connected) {
      await onSaveFittingHelper(
        true,
        () => {
          dispatch(disconnectRicDevice(rightFitting.device_side));
        },
        () => {},
        true,
        fittingDataRight
      ).catch(() => {
        success = false;
      });
    }

    if (leftFitting.connected) {
      await onSaveFittingHelper(
        true,
        () => {
          dispatch(disconnectRicDevice(leftFitting.device_side));
        },
        () => {},
        true,
        fittingDataLeft
      ).catch(() => {
        success = false;
      });
    }

    setCompleteLoading(false);
    dispatch(closeModal("complete"));

    if (success) {
      setComplete(true);
    }
  };

  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdate(false);
    }
  }, [shouldUpdate]);

  useEffect(() => {
    if (complete) {
      navigate(`/patient-details/${params.id}`);
      if (
        (ricRightFitting.connected &&
          !ricRightFitting.read_only &&
          !ricRightFitting.remote) ||
        (ricLeftFitting.connected &&
          !ricLeftFitting.read_only &&
          !ricLeftFitting.remote)
      ) {
        dispatch(disconnectRicDevice(LISTENING_SIDE.LEFT));
        dispatch(disconnectRicDevice(LISTENING_SIDE.RIGHT));
      }
      setComplete(false);
    }
  }, [complete]);

  // useEffect(() => {
  //   if (complete) {
  //     navigate(`/patient-details/${params.id}`);
  //     if (
  //       (ricRightFitting.updating && !ricRightFitting.read_only && !ricRightFitting.remote) ||
  //       (ricLeftFitting.updating && !ricLeftFitting.read_only && !ricLeftFitting.remote)
  //     ) {
  //       dispatch(disconnectRicDevice(LISTENING_SIDE.LEFT));
  //       dispatch(disconnectRicDevice(LISTENING_SIDE.RIGHT));
  //     }
  //     setComplete(false);
  //   }
  // }, [complete]);

  return (
    <>
      {loading || completeLoading ? (
        <Box sx={{ paddingTop: "50vh" }}>
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", width: "42%" }}>
              <IconButton sx={{ marginRight: "5px" }} onClick={handleBackClick}>
                <ArrowBackIcon sx={{ color: "#2D3B67" }} fontSize="medium" />
              </IconButton>
              <Tabs
                value={value}
                aria-label="basic tabs example"
                sx={{ height: "0px" }}
              >
                <Tab label="Fitting" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <Box
              width={{ xs: "90%", sm: "50%", md: "28%" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Select
                sx={{
                  height: "7.6vh",
                  fontSize: "16px",
                  fontFamily: "League Spartan",
                  color: "#4D4D4D",
                  width: { xs: "80vw", sm: "50vw", md: "13vw" },
                  minWidth: "150px",
                  maxWidth: "100%",
                  backgroundColor: "#EDF0F7",
                  borderRadius: "0px",
                  textAlign: "center",
                }}
                value={ricRightFitting.ric_mode}
                onChange={(event) => {
                  const selectedMode = event.target.value;
                  dispatch(
                    changeRicMode(
                      selectedMode,
                      LISTENING_SIDE.RIGHT,
                      ricRightFitting.deviceObj
                    )
                  );
                  dispatch(
                    changeRicMode(
                      selectedMode,
                      LISTENING_SIDE.LEFT,
                      ricLeftFitting.deviceObj
                    )
                  );
                }}
              >
                {[
                  { value: 0, label: "Mode 1 : Quiet Mode" },
                  { value: 1, label: "Mode 2 : Noise Mode" },
                  { value: 2, label: "Mode 3 : Outdoor Mode" },
                ].map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Typography
                      sx={{
                        color: "#2D3B67",
                        fontWeight: "500",
                        fontFamily: "League Spartan",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box width="30%" display={"flex"} justifyContent="flex-end">
              {!(
                ricRightFitting.read_only ||
                ricLeftFitting.remote ||
                ricLeftFitting.read_only ||
                ricLeftFitting.remote
              ) && (
                <Box mt={2}>
                  <Button
                    onClick={handleRightToggleMute}
                    disabled={!ricRightFitting.connected}
                    sx={{
                      textDecoration: "underline",
                      fontFamily: "League spartan",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#C24747",
                    }}
                  >
                    {isRightMuted ? (
                      <VolumeOff sx={{ transform: "scaleX(-1)" }} />
                    ) : (
                      <VolumeUp sx={{ transform: "scaleX(-1)" }} />
                    )}
                  </Button>
                  <Button
                    onClick={handleLeftToggleMute}
                    disabled={!ricLeftFitting.connected}
                    sx={{
                      textDecoration: "underline",
                      fontFamily: "League spartan",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "#1265A1",
                    }}
                  >
                    {isLeftMuted ? <VolumeOff /> : <VolumeUp />}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          {!loading && ricRightFitting.enabled === false && (
            <NoDataComponent
              message={"Your browser does'nt support this software."}
              Icon={Error}
            />
          )}
          {
            <>
              <CustomTabPanel value={value} index={0} sx={{ height: "0px" }}>
                <ConncetionScreen
                  disabled={params.fitid && params.fitid != ""}
                  show={
                    !ricRightFitting.connected &&
                    ricRightFitting.enabled !== false &&
                    !loading
                  }
                  setValue={setValue}
                  data={data}
                />
              </CustomTabPanel>
            </>
          }
          <CustomTabPanel value={value} index={1} sx={{ height: "0px" }}>
            <Grid container>
              <Grid md={1.7} xs={1.7}>
                <Box
                  p={2}
                  height={"100vh"}
                  sx={{ borderRight: "1px solid #DDDDDD" }}
                >
                  <Box>
                    <Button
                      sx={{
                        fontFamily: "League spartan",
                        width: "13vw",
                        height: "6vh",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          selectButton === FITTING_BUTTON.BASIC_FITTING
                            ? "#EDF0F7"
                            : "white",
                        border:
                          selectButton === FITTING_BUTTON.BASIC_FITTING
                            ? "1px solid #2D3B67"
                            : "1px solid #F2F2F2",
                      }}
                      onClick={() => {
                        setselectButton(FITTING_BUTTON.BASIC_FITTING);
                      }}
                    >
                      <Typography variant="h6">Basic Fitting</Typography>
                    </Button>
                  </Box>
                  <Box mt={2}>
                    <Button
                      sx={{
                        fontFamily: "League spartan",
                        fontSize: "16px",
                        width: "13vw",
                        fontWeight: "large",
                        height: "6vh",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          selectButton === FITTING_BUTTON.FINE_TUNNING
                            ? "#EDF0F7"
                            : "white",
                        border:
                          selectButton === FITTING_BUTTON.FINE_TUNNING
                            ? "1px solid #2D3B67"
                            : "1px solid #F2F2F2",
                      }}
                      onClick={onFineTunning}
                    >
                      <Typography variant="h6">Fine Tunning</Typography>
                    </Button>
                  </Box>

                  <Box mt={2}>
                    <Button
                      sx={{
                        fontFamily: "League spartan",
                        fontSize: "16px",
                        width: "13vw",
                        fontWeight: "large",
                        height: "6vh",
                        display: "flex",
                        justifyContent: "flex-start",
                        backgroundColor:
                          selectButton === FITTING_BUTTON.MANAGE_PROGRAM
                            ? "#EDF0F7"
                            : "white",
                        border:
                          selectButton === FITTING_BUTTON.MANAGE_PROGRAM
                            ? "1px solid #2D3B67"
                            : "1px solid #F2F2F2",
                      }}
                      onClick={() => {
                        setselectButton(FITTING_BUTTON.MANAGE_PROGRAM);
                      }}
                    >
                      <Typography variant="h6"> Manage Program</Typography>
                    </Button>
                  </Box>

                  {!(ricRightFitting.read_only || ricLeftFitting.read_only) && (
                    <Box mt={2}>
                      <Button
                        sx={{
                          fontFamily: "League spartan",
                          fontSize: "16px",
                          width: "13vw",
                          fontWeight: "large",
                          height: "6vh",
                          display: "flex",
                          justifyContent: "flex-start",
                          backgroundColor:
                            selectButton === FITTING_BUTTON.COMPLETE_SESSION
                              ? "#EDF0F7"
                              : "white",
                          border:
                            selectButton === FITTING_BUTTON.COMPLETE_SESSION
                              ? "1px solid #2D3B67"
                              : "1px solid #F2F2F2",
                        }}
                        onClick={() => {
                          setselectButton(FITTING_BUTTON.COMPLETE_SESSION);
                          dispatch(
                            openModal(
                              <RicCompleteSessionController
                                onCompleteSession={onCompleteSession}
                                onCancel={() => {
                                  setselectButton(FITTING_BUTTON.BASIC_FITTING);
                                  setValue(1);
                                }}
                                id="complete"
                              />,
                              "sm",
                              false,
                              "complete"
                            )
                          );
                        }}
                      >
                        <Typography variant="h6">Complete Session</Typography>
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
              {value == 1 && selectButton === FITTING_BUTTON.BASIC_FITTING && (
                <Grid item xs={10}>
                  <RicBasicFittingAreaController

                  // onAutoFit={onAutoFit}
                  />
                </Grid>
              )}
              {value == 1 && selectButton === FITTING_BUTTON.FINE_TUNNING && (
                <Grid item xs={10}>
                  <RicFineTunningController
                    // data8R={data8R}
                    // setData8R={setData8R}
                    // data8L={data8L}
                    // setData8L={setData8L}
                    // setData24R={setData24R}
                    // setData24L={setData24L}
                    // data24L={data24L}
                    // mpoR32={mpoR32}
                    // mpoL32={mpoL32}
                    // setMPOL32={setMPOL32}
                    // setMPOR32={setMPOR32}
                    // data24R={data24R}
                    // setFreShapR32={setFreShapR32}
                    // freShapR32={freShapR32}
                    // setFreShapL32={setFreShapL32}
                    setShouldUpdate={setShouldUpdate}
                    // freShapL32={freShapL32}
                  />
                </Grid>
              )}
              {value == 1 && selectButton === FITTING_BUTTON.MANAGE_PROGRAM && (
                <Grid item xs={10}>
                  <RicManageProgramController />
                </Grid>
              )}
              {/* {value == 1 &&
                selectButton === FITTING_BUTTON.ADVANCE_FITTING && (
                  <Grid item xs={10}>
                    <AdvanceFittingController
                    
                      data8R={data8R}
                      setData8R={setData8R}
                      data8L={data8L}
                      setData8L={setData8L}
                      setData24R={setData24R}
                      setData24L={setData24L}
                      data24L={data24L}
                      data24R={data24R}
                      createDataFor24={createDataFor24}
                      createDataFor24to8={createDataFor24to8}
                     setShouldUpdate={setShouldUpdate}
                    />
                  </Grid>
                )} */}
              {value == 1 &&
                selectButton === FITTING_BUTTON.CONFIG_ASSISTANT && (
                  <Grid item xs={10}>
                    <ConfigAssistantController
                      isRest={isRest}
                      setIsReset={setIsReset}
                    />
                  </Grid>
                )}
            </Grid>
          </CustomTabPanel>
        </>
      )}
    </>
  );
};

export default RicFittingMainController;
