import { actions } from "../../utils/constants";
import { DEVICES, LISTENING_SIDE } from "../../utils/patient.constants";

const initialState = {
  profile_id: null,
  connected: false,
  enabled: undefined,
  updating: false,
  loadingMessage: "",
  loading: undefined,
  hardwareData: [],
  deviceInfo: {
    name: "",
    id: "",
  },
  eqValues: [0, 0, 0, 0, 0, 0, 0, 0],
  ric_sharpness_reduction: 0,
  feedback_cancellation: 0,
  self_speech_reduction: 0,
  ric_master_gain: 0,
  battery_level: null,
  volumeLevel: 15,
  ric_noise_reduction: 0,
  ric_mode: 0,
  mpoValue: 0,
  deviceObj: {},
  writeFun: undefined,
  remarks: "",
  disconnectFun: undefined,
  device_type: DEVICES.RIC_OPTIMA,
  device_side: LISTENING_SIDE.LEFT,
  read_only: false,
  listening_side: LISTENING_SIDE.LEFT,
  device_color: undefined,
  disconnect: false,
  tempModes: {
    0: {
      eqValues: [0, 0, 0, 0, 0, 0, 0, 0],
      mpoValue: 0,
      noiseReduction: 0,
      feedbackCancellation: 0,
      ric_sharpness_reduction: 0,
      selfSpeechReduction: 0,
      ricMasterGain: 0,
      device_type: DEVICES.RIC_OPTIMA,
      device_side: LISTENING_SIDE.LEFT,
      listening_side: LISTENING_SIDE.LEFT,
      mode_type: 0,
    },
    1: {
      eqValues: [0, 0, 0, 0, 0, 0, 0, 0],
      mpoValue: 0,
      noiseReduction: 0,
      feedbackCancellation: 0,
      ric_sharpness_reduction: 0,
      selfSpeechReduction: 0,
      ricMasterGain: 0,
      device_type: DEVICES.RIC_OPTIMA,
      device_side: LISTENING_SIDE.LEFT,
      listening_side: LISTENING_SIDE.LEFT,
      mode_type: 1,
    },
    2: {
      eqValues: [0, 0, 0, 0, 0, 0, 0, 0],
      mpoValue: 0,
      noiseReduction: 0,
      feedbackCancellation: 0,
      ric_sharpness_reduction: 0,
      selfSpeechReduction: 0,
      ricMasterGain: 0,
      device_type: DEVICES.RIC_OPTIMA,
      device_side: LISTENING_SIDE.LEFT,
      listening_side: LISTENING_SIDE.LEFT,
      mode_type: 2,
    },
  },
};
const ricLeftFittingReducer = (state = initialState, action) => {
  if (action.side == LISTENING_SIDE.RIGHT) {
    return { ...state };
  }
  switch (action.type) {
    case actions.CONNECT_DEVICE:
      return {
        ...state,
        hardwareData: action.hardwareData,
        deviceInfo: action.deviceInfo,
        deviceObj: action.deviceObj,
        connected: true,
        read_only: false,
        disconnectFun: action.disconnectFun,
      };
    case actions.REMOTE_FITTING:
      return {
        ...state,
        remote: true,
        deviceInfo: action.deviceInfo,
        connected: true,
        read_only: false,
        disconnectFun: action.disconnectFun,
      };
    case actions.CHANGE_PROFILE_ID:
      return { ...state, profile_id: action.value };
    case actions.CHNAGE_LOADING_VALUES:
      return {
        ...state,
        loading: action.loading,
        loadingMessage: action.messages,
      };
    case actions.CHNAGE_DEVICE_COMPATIBLITY:


      return { ...state,
        tempModes: {
          ...state.tempModes,
          [state.ric_mode]: {
            eqValues: state.eqValues,
            mpoValue: state.mpoValue,
            noiseReduction: state.ric_noise_reduction.left,
            feedbackCancellation: state.feedback_cancellation,
            ric_sharpness_reduction: state.ric_sharpness_reduction,
            selfSpeechReduction: state.self_speech_reduction,
            ricMasterGain: state.ric_master_gain,
            device_type: state.device_type,
            device_side: state.device_side,
            listening_side: state.listening_side,

          },
        },
         enabled: action.value };

    case actions.CHANGE_WRITE_FUN:
      return { ...state, writeFun: action.value };
        
      case actions.DISCONNECT_DEVICE:
      
        return {
          ...state,
          disconnect: true,
          connected: false,
          deviceObj: action.deviceObj,
          remote: false,
          read_only: false,
          ...initialState,
        };

      return state;

        case actions.CHNAGE_REMARKS: {
             console.log("✅ Reducer Updating Remarks:", action.value);
             return { ...state, remarks: action.value };
           }
             
      
      case actions.READ_ONLY_CHANGED:
        return {
          ...state,
          read_only: action.value,
        };
      case actions.UPDATE_DEVICE_COLOR:
  if (action.side === LISTENING_SIDE.LEFT) {
    return {
      ...state,
      device_color: action.color,
    };
  }
  return state;


    case actions.CHANGE_RIC_NOISE_REDUCTION:
      if (action.side === LISTENING_SIDE.LEFT) {
        console.log("Updating LEFT noise reduction in reducer:", action.value);
        return {
          ...state,
          ric_noise_reduction: action.value,

            tempModes: {
              ...state.tempModes,
              [state.ric_mode]: {
                ...state.tempModes[state.ric_mode],
                noiseReduction: action.value,
              },
          },
        };
      }
      return state;

    case actions.CHANGE_RIC_MODE_LEFT:
      console.log("Updating LEFT mode in reducer:", action.mode);
      if (state.updating) { 
        return {
          ...state,
          ric_mode: action.mode,
          eqValues: state.tempModes[action.mode]?.eqValues || [0, 0, 0, 0, 0, 0, 0, 0],
          mpoValue: state.tempModes[action.mode]?.mpoValue || 0,
          ric_noise_reduction: state.tempModes[action.mode]?.noiseReduction || 0,
          feedback_cancellation: state.tempModes[action.mode]?.feedbackCancellation || 0,
          ric_sharpness_reduction: state.tempModes[action.mode]?.ric_sharpness_reduction || 0,
          self_speech_reduction: state.tempModes[action.mode]?.selfSpeechReduction || 0,
          ric_master_gain: state.tempModes[action.mode]?.ricMasterGain || 0,
        };
      }
    else if (state.remote) {
        const selectedTempMode = state.tempModes[action.mode] || {
          eqValues: [0, 0, 0, 0, 0, 0, 0, 0],
          mpoValue: 0,
          noiseReduction: 0,
          feedbackCancellation: 0,
          ric_sharpness_reduction: 0,
          selfSpeechReduction: 0,
          ricMasterGain: 0,
        }; return {
          ...state,
          ric_mode: action.mode,
          eqValues: selectedTempMode.eqValues,
          mpoValue: selectedTempMode.mpoValue,
          ric_noise_reduction: selectedTempMode.noiseReduction,
          feedback_cancellation: selectedTempMode.feedbackCancellation,
          ric_sharpness_reduction: selectedTempMode.ric_sharpness_reduction,
          self_speech_reduction: selectedTempMode.selfSpeechReduction,
          ric_master_gain: selectedTempMode.ricMasterGain,
        } }
        else {
      return {
        ...state,
        tempModes: {
          ...state.tempModes,
          [state.ric_mode]: {
            eqValues: state.eqValues,
            mpoValue: state.mpoValue,
            noiseReduction: state.ric_noise_reduction.left,
            feedbackCancellation: state.feedback_cancellation,
            ric_sharpness_reduction: state.ric_sharpness_reduction,
            selfSpeechReduction: state.self_speech_reduction,
            ricMasterGain: state.ric_master_gain,
            device_type: state.device_type,
            device_side: state.device_side,
            listening_side: state.listening_side,
            remarks: state.remarks
          },
        },
        ric_mode: action.mode, 
      };
    };

    case actions.READ_MODE:
      return {
        ...state,
       
        ric_mode: action.value,
        
      };

      // case actions.UPDATE_MODE_VALUES:
      // return {
      //   ...state,
      //   eqValues: action.data.eqValues,
      //   mpoValue: action.data.mpoValue,
      //   ric_noise_reduction: { left: action.data.noiseReduction },
      //   feedback_cancellation: action.data.feedbackCancellation,
      //   ric_sharpness_reduction: action.data.ric_sharpness_reduction,
      //   self_speech_reduction: action.data.selfSpeechReduction,
      //   ric_master_gain: action.data.ricMasterGain,

      //   // Store all new mode values in tempModes
      //   tempModes: {
      //     ...state.tempModes,
      //     [state.ric_mode]: { ...action.data },
      //   },
      // };


      case actions.UPDATE_BATTERY_LEVEL:
        if (action.side === LISTENING_SIDE.LEFT) {
          return {
            ...state,
            battery_level: action.batteryPercentage, 
          };
        }
        return state;
        
    case actions.SET_EQUALIZER:
      if (action.side === LISTENING_SIDE.LEFT) {
        return {
          ...state,
          eqValues: action.eqValues,
          tempModes: {
          ...state.tempModes,
          [state.ric_mode]: {
            ...state.tempModes[state.ric_mode],
            eqValues: action.eqValues,
          },
        },
        };
      }
      return state;

      case actions.SET_MPO:
        if (action.side === LISTENING_SIDE.LEFT) {
          return {
            ...state,
            mpoValue: action.mpoValue,
            tempModes: {
              ...state.tempModes,
              [state.ric_mode]: {
                ...state.tempModes[state.ric_mode],
                mpoValue: action.mpoValue,
              },
            },
          };
        }
        return state;
      case actions.CHANGE_FEEDBACK_CANCELLATION:
      if (action.side === LISTENING_SIDE.LEFT) {
        return {
          ...state,
          feedback_cancellation: action.value,
          tempModes: {
            ...state.tempModes,
            [state.ric_mode]: {
              ...state.tempModes[state.ric_mode],
              feedbackCancellation: action.value,
            },
          },
        };
      }
      return state;

    case actions.CHANGE_RIC_SHARPNESS:
      return {
        ...state,
        ric_sharpness_reduction: action.value,
        tempModes: {
          ...state.tempModes,
          [state.ric_mode]: {
            ...state.tempModes[state.ric_mode],
            ric_sharpness_reduction: action.value,
          },
        },
      };

    case actions.CHANGE_SELF_SPEECH_REDUCTION:
      return {
        ...state,
        self_speech_reduction: action.value,
        tempModes: {
          ...state.tempModes,
          [state.ric_mode]: {
            ...state.tempModes[state.ric_mode],
            selfSpeechReduction: action.value,
          },
        },
      };

    case actions.CHANGE_RIC_MASTER_GAIN:
      if (action.side === LISTENING_SIDE.LEFT) {
        return {
          ...state,
          ric_master_gain: action.value,
          tempModes: {
            ...state.tempModes,
            [state.ric_mode]: {
              ...state.tempModes[state.ric_mode],
              ricMasterGain: action.value,
            },
          },
        };
      }
      return state;

      // case actions.SET_RIC_FITTING_DATA:
      //   if (action.side === LISTENING_SIDE.LEFT) {
      //     console.log("🔄 Updating Left RIC Reducer with API Data:", action.payload);
      
      //     const selectedModeData = action.payload.mode_data.find((mode) => mode.mode_type === state.ric_mode) || action.payload.mode_data[0];
      
      //     return {
      //       ...state,
            
      //       connected: true,
      //       deviceInfo: {
      //         name: action.payload.device_name,
      //         id: action.payload.device_id,
      //       },
      //       eqValues: selectedModeData.eq_values,
      //       mpoValue: selectedModeData.mpo_value,
      //       ric_noise_reduction: selectedModeData.noise_reduction,
      //       feedback_cancellation: selectedModeData.feedback_cancellation,
      //       ric_sharpness_reduction: selectedModeData.sharpness,
      //       self_speech_reduction: selectedModeData.self_speech_reduction,
      //       ric_master_gain: selectedModeData.master_gain,
      //       remarks: action.payload.remarks,
      //       read_only: action.read_only || false,
      //     };
      //   }
      //   return state;
      

      case actions.SET_RIC_FITTING_DATA:
        if (action.side === LISTENING_SIDE.LEFT) {
          console.log("🔄 Updating Left RIC Reducer with API Data:", action.payload);
      
          const selectedModeData = action.payload.mode_data.find((mode) => mode.mode_type === state.ric_mode) || action.payload.mode_data[0];
      
          // Update tempModes with mode_data
          const updatedTempModes = { ...state.tempModes };
          action.payload.mode_data.forEach((modeData, index) => {
            updatedTempModes[index] = {
              eqValues: modeData.eq_values,
              mpoValue: modeData.mpo_value,
              noiseReduction: modeData.noise_reduction,
              feedbackCancellation: modeData.feedback_cancellation,
              ric_sharpness_reduction: modeData.sharpness,
              selfSpeechReduction: modeData.self_speech_reduction,
              ricMasterGain: modeData.master_gain,
              device_type: state.device_type,
              device_side: state.device_side,
              listening_side: state.listening_side,
            };
          });
      
          return {
            ...state,
            updating: true,
            
            deviceInfo: {
              name: action.payload.device_name,
              id: action.payload.device_id,
            },
            eqValues: selectedModeData.eq_values,
            mpoValue: selectedModeData.mpo_value,
            ric_noise_reduction: selectedModeData.noise_reduction,
            feedback_cancellation: selectedModeData.feedback_cancellation,
            ric_sharpness_reduction: selectedModeData.sharpness,
            self_speech_reduction: selectedModeData.self_speech_reduction,
            ric_master_gain: selectedModeData.master_gain,
            remarks: action.payload.remarks,
            read_only: action.read_only || false,
            connected: action.read_only ? true : state.connected,
            tempModes: updatedTempModes, 
          };
        }
        
        return state;
  
  // case actions.UPDATE_MODE_VALUES:
  //       if (action.side === LISTENING_SIDE.LEFT) {
  //         return {
  //           ...state,
  //           eqValues: action.data.eqValues,
  //           mpoValue: action.data.mpo_value,
  //           ric_noise_reduction: action.data.noise_reduction,
  //           feedback_cancellation: action.data.feedback_cancellation,
  //           ric_sharpness_reduction: action.data.sharpness,
  //           self_speech_reduction: action.data.self_speech_reduction,
  //           ric_master_gain: action.data.master_gain,
  //           tempModes: {
  //             ...state.tempModes,
  //             [action.modeIndex]: { ...action.data },
  //           },
  //         };
  //       }
  //       return state;
  case actions.SET_VOLUME_LEVEL:
          if (action.side === LISTENING_SIDE.LEFT) {
            return {
              ...state,
              volumeLevel: action.value,
            };
          }
          return state;
        
  default:
      return { ...state };
  }
};
export default ricLeftFittingReducer;
