import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SignInController from "../../../pages/signin/SignInController";
import CreateController from "../../../pages/patient/CreateController";
import AudioGram from "../../../pages/patient/AudioGram";
import SelectDeviceui from "../../../pages/patient/SelectDeviceui";
import SubmitButton from "../../button/SubmitButton";
import { Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NewPatientdetails = () => {
  const [value, setValue] = React.useState(0);
  const [isFormValid, setIsFormValid] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [later, setLater] = React.useState(false);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        // overflow:"auto", scrollbarWidth:"none"
      }}
    >
      {value != 4 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: 1,
            borderColor: "divider",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", overflow: "hidden" }}
          >
            <IconButton
              sx={{ marginRight: "5px" }}
              onClick={() => {
                navigate("/patient-details");
              }}
            >
              <ArrowBackIcon sx={{ color: "#2D3B67" }} fontSize="medium" />
            </IconButton>

            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{ height: "0px" }}
            >
              <Tab label="New Patient" {...a11yProps(0)} />
              <Tab
                label="Audiogram"
                {...a11yProps(1)}
                disabled={!(value >= 1)}
              />
              <Tab
                label="Select device"
                {...a11yProps(2)}
                disabled={!(value >= 2)}
              />
            </Tabs>
          </Box>
          <Box sx={{ display: "flex" }}>
            {value == 0 && (
              <Box mr={2} mt={2}>
                <Button
                  onClick={() => {
                    setValue(4);
                  }}
                  sx={{
                    textDecoration: "underline",
                    fontFamily: "League spartan",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  Case History
                </Button>
              </Box>
            )}
            {value == 2 && (
              <Box mr={2} mt={2}>
                <Button
                  onClick={() => {
                    setLater(true);
                  }}
                  sx={{
                    textDecoration: "underline",
                    fontFamily: "League spartan",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  I'll Do It Later
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <CustomTabPanel
        value={value}
        index={value}
        sx={{ height: "0px", overflow: "hidden" }}
      >
        <CreateController value={value} later={later} setValue={setValue} />
      </CustomTabPanel>
    </Box>
  );
};
export default NewPatientdetails;