import { memo, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { getUserByIdApi } from "../../apis/user.api";
import PatientDetailedViewCommonUI from "./PatientDetailedViewCommonUI";
import { getPatientByIdApi } from "../../apis/patient.api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { closeModal } from "../../store/actions/modalAction";
import {
  changeDeviceSelection,
  disconnectDevice,
  read_only,
  resetToDefault,
} from "../../store/actions/fittingAction";
import { DEVICES, LISTENING_SIDE } from "../../utils/patient.constants";
import {
  readBatteryLevel,
  readEqualizer,
  readFeedbackReduction,
  readMPO,
  readNoiseReduction,
  updateDeviceColor,
} from "../../store/actions/ricFittingActions";

const PatientDetailedViewCommonController = ({ id, getFun }) => {
  const { fitting, fittingLeft, ricLeftFitting, ricRightFitting } = useSelector(
    (state) => state
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const title = "Patient details";
  const modalKey = "patient-modal";
  const getByIdApi = getFun ?? getPatientByIdApi;

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({});

  // useEffect(() => {
  //   const disconnectDevices = () => {
  //     if (fitting.connected) {
  //       dispatch(disconnectDevice(fitting.device_side, true));
  //     } else {
  //       dispatch(resetToDefault(fitting.device_side));
  //     }

  //     if (fittingLeft.connected) {
  //       dispatch(disconnectDevice(fittingLeft.device_side, true));
  //     } else {
  //       dispatch(resetToDefault(fittingLeft.device_side));
  //     }

  //     if (ricRightFitting.connected) {
  //       dispatch(disconnectDevice(ricRightFitting.device_side, true));
  //     } else {
  //       dispatch(resetToDefault(ricRightFitting.device_side));
  //     }

  //     if (ricLeftFitting.connected) {
  //       dispatch(disconnectDevice(ricLeftFitting.device_side, true));
  //     } else {
  //       dispatch(resetToDefault(ricLeftFitting.device_side));
  //     }
  //   };

  //   disconnectDevices();
  // }, []);

  // useEffect(() => {
  //   if (fitting.read_only || fittingLeft.read_only || ricRightFitting.read_only || ricLeftFitting.read_only) {
  //     dispatch(read_only(false));
  //     return;
  //   }

  //   const disconnectDevices = () => {
  //     if (fitting.connected) {
  //       dispatch(disconnectDevice(fitting.device_side, true));
  //     } else {
  //       dispatch(resetToDefault(fitting.device_side));
  //     }

  //     if (fittingLeft.connected) {
  //       dispatch(disconnectDevice(fittingLeft.device_side, true));
  //     } else {
  //       dispatch(resetToDefault(fittingLeft.device_side));
  //     }

  //     if (ricRightFitting.connected) {
  //       dispatch(disconnectDevice(ricRightFitting.device_side, true));
  //     } else {
  //       dispatch(resetToDefault(ricRightFitting.device_side));
  //     }

  //     if (ricLeftFitting.connected) {
  //       dispatch(disconnectDevice(ricLeftFitting.device_side, true));
  //     } else {
  //       dispatch(resetToDefault(ricLeftFitting.device_side));
  //     }
  //   };

  //   disconnectDevices();
  // }, []);

  useEffect(() => {
    // Helper function to check if an object is empty
    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    // Check if `read_only` is enabled
    if (
      fitting.read_only ||
      fittingLeft.read_only ||
      ricRightFitting.read_only ||
      ricLeftFitting.read_only
    ) {
      // If `deviceObj` is empty, reset to default
      if (
        isEmptyObject(fitting.deviceObj) &&
        isEmptyObject(fittingLeft.deviceObj)
      ) {
        dispatch(resetToDefault(fitting.device_side));
      } else {
        // Turn off read_only and exit early
        dispatch(read_only(false));
        return;
      }

      if (isEmptyObject(fittingLeft.deviceObj)) {
        dispatch(resetToDefault(fittingLeft.device_side));
      } else {
        // Turn off read_only and exit early
        dispatch(read_only(false));
        return;
      }

      if (isEmptyObject(ricRightFitting.deviceObj)) {
        dispatch(resetToDefault(ricRightFitting.device_side));
      } else {
        // Turn off read_only and exit early
        dispatch(read_only(false));
        return;
      }

      if (isEmptyObject(ricLeftFitting.deviceObj)) {
        dispatch(resetToDefault(ricLeftFitting.device_side));
      } else {
        // Turn off read_only and exit early
        dispatch(read_only(false));
        return;
      }
    }

    // If `read_only` is false, proceed to disconnect devices as usual
    const disconnectDevices = () => {
      if (fitting.connected) {
        dispatch(disconnectDevice(fitting.device_side, true));
      } else {
        dispatch(resetToDefault(fitting.device_side));
      }

      if (fittingLeft.connected) {
        dispatch(disconnectDevice(fittingLeft.device_side, true));
      } else {
        dispatch(resetToDefault(fittingLeft.device_side));
      }

      if (ricRightFitting.connected) {
        dispatch(disconnectDevice(ricRightFitting.device_side, true));
      } else {
        dispatch(resetToDefault(ricRightFitting.device_side));
      }

      if (ricLeftFitting.connected) {
        dispatch(disconnectDevice(ricLeftFitting.device_side, true));
      } else {
        dispatch(resetToDefault(ricLeftFitting.device_side));
      }
    };

    disconnectDevices();
  }, []);

  const [deviceFetch, setDeviceFetch] = useState(false);

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setData({ ...response });
          setDeviceFetch(true);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    console.log("this is reducer", fitting.mode_data, fittingLeft.mode_data);
    console.log("device_type", fitting.device_type);
  }, []);

  useEffect(() => {
    if (deviceFetch) {
      if (data?.right_fitted_device?.device_type) {
        dispatch(
          changeDeviceSelection(
            data?.right_fitted_device?.device_type,
            LISTENING_SIDE.RIGHT
          )
        );
        if (data?.right_fitted_device?.device_type === DEVICES.RIC_OPTIMA) {
          dispatch(
            updateDeviceColor(
              LISTENING_SIDE.RIGHT,
              data?.right_fitted_device?.device_color
            )
          );
        }
      }

      if (data?.left_fitted_device?.device_type) {
        dispatch(
          changeDeviceSelection(
            data?.left_fitted_device?.device_type,
            LISTENING_SIDE.LEFT
          )
        );
        if (data?.left_fitted_device?.device_type === DEVICES.RIC_OPTIMA) {
          dispatch(
            updateDeviceColor(
              LISTENING_SIDE.LEFT,
              data?.left_fitted_device?.device_color
            )
          );
        }
      }

      setDeviceFetch(false);
    }
  }, [deviceFetch]);

  const onEdit = (e, step) => {
    let route = "/patient-details/" + params.id + "/update";

    if (step) {
      route += "/audiogram";
      navigate(route);
    }
  };
  const onNewFitting = () => {
    navigate("/patient-details/" + params.id + "/new-fitting");
  };

  const onRicNewFitting = () => {
    navigate("/patient-details/" + params.id + "/ric-new-fitting");
  };

  useEffect(() => {
    console.log("this is params id", params.id);
    if (params.id) fetchById(params.id);
  }, [params.id]);
  const [fetchingData, setFetchingData] = useState(false); // New state variable
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (
        fitting.device_type === DEVICES.RIC_OPTIMA ||
        fittingLeft.device_type === DEVICES.RIC_OPTIMA
      ) {
        setIsLoading(true);
        setFetchingData(true); 
        try {
          await dispatch(
            readBatteryLevel(LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj)
          );

          await dispatch(
            readEqualizer(LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj)
          );
          await dispatch(
            readEqualizer(LISTENING_SIDE.RIGHT, ricRightFitting.deviceObj)
          );

          await dispatch(
            readBatteryLevel(LISTENING_SIDE.RIGHT, ricRightFitting.deviceObj)
          );
         
          await dispatch(
            readNoiseReduction(LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj)
          );
          await dispatch(
            readNoiseReduction(LISTENING_SIDE.RIGHT, ricRightFitting.deviceObj)
          );
          await dispatch(
            readFeedbackReduction(LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj)
          );
          await dispatch(
            readFeedbackReduction(
              LISTENING_SIDE.RIGHT,
              ricRightFitting.deviceObj
            )
          );
          await dispatch(
            readMPO(LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj)
          );
          await dispatch(
            readMPO(LISTENING_SIDE.RIGHT, ricRightFitting.deviceObj)
          );
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
          setFetchingData(false);
        }
      }
    };

    fetchData();
  }, [
    dispatch,
    ricLeftFitting.deviceObj,
    ricRightFitting.deviceObj,
    fitting.device_type,
    fittingLeft.device_type,
  ]);

  return (
    <PatientDetailedViewCommonUI
      onNewFitting={onNewFitting}
      onRicNewFitting={onRicNewFitting}
      onEdit={onEdit}
      isLoading={isLoading}
      fetchingData={fetchingData}
      modalKey={modalKey}
      title={title}
      loading={loading}
      data={data}
      setData={setData}
    />
  );
};
export default memo(PatientDetailedViewCommonController);
