const TYPE_OF_HEARING_LOSS = {
  CONDUCTIVE: 1,
  SN: 2,
  MIXED: 3,
};
const TYPE_OF_HEARING_LOSS_NAMES = {
  1: "Conductive",
  2: "SN",
  3: "Mixed",
};
const LEVEL_OF_HEARING_LOSS = {
  NORMAL: 1,
  MILD: 2,
  MODERATE: 3,
  MODERATE_SEVERE: 4,
  SEVERE: 5,
  PROFOUND: 6,
};

const DEVICES = {
  BTE_OPTIMA: 1,
  BTE_PRIME: 2,
  BTE_24: 5,
  BTE_32: 4,
  GLASSES: 3,
  RIC_OPTIMA: 6,
  RIC_PRIME: 7,
};

const FITTING_BUTTON = {
  BASIC_FITTING: 1,
  FINE_TUNNING: 2,
  ADVANCE_FITTING: 3,
  CONFIG_ASSISTANT: 4,
  MANAGE_PROGRAM: 5,
  COMPLETE_SESSION: 6,
};

const DEVICES_TUBE = {
  STANDARD: 1,
  THIN: 2,
};

const DEVICES_TUBE_NAME = {
  1: "Standard",
  2: "Thin",
};

const DEVICES_CHANNEL = {
  CHANNEL_8: 8,
  CHANNEL_16: 16,
  CHANNEL_24: 24,
  CHANNEL_32: 32,
  // BTE_16: 3,
  // BTE_24: 5,
  // BTE_32: 4,
  // GLASSES: 2
};
const DOM_TYPE = {
  OPEN: 1,
  CLOSE: 2,
  POWER: 3,
  EAR_MOLD: 4,
  TULIP:5
};

const DOM_TYPE_NAME = {
  1: "Open",
  2: "Close",
  3: "Power",
  4: "Ear Mold",
  5: "Tulip"
};

const DOM_SIZE = {
  SMALL: 1,
  MEDIUM: 5,
  LARGE: 10,
};

const DEVICE_COLORS = {
  METALIC: 1,
  BLACK: 2,
  BEIGE: 3,
};

const DEVICES_NAME = {
  1: "BTE OPTIMA",
  2: "BTE PRIME",
  3: "RIC OPTIMA",
  4: "RIC PRIME"
  // 2:"GLASSES",
  // 3:"BTE PRIME-C16",
  // 4:"BTE PRIME-C32",
  // 5:"BTE PRIME-C24",
};

const LISTENING_SIDE = {
  LEFT: 1,
  RIGHT: 2,
  BOTH: 3,
};
const IMPAIREMENT_TYPE = {
  UNILATERAL: 1,
  BILATERAL: 2,
};

const MANAGE_PROGRAM_LIST = {
  COMMON: 1,
  MUSIC: 2,
  NOISE_CANCELLATION: 3,
  SPEECH_IN_NOISE: 4,
};

const MANAGE_PROGRAM_LIST_Array = [
  { name: "COMMON", value: 1 },
  { name: "MUSIC", value: 2 },
  { name: "NOISE_CANCELLATION", value: 3 },
  { name: "SPEECH_IN_NOISE", value: 4 },
];

const MANAGE_PROGRAM_NAME = {
  1: "Common",
  2: "Music",
  3: "Noise Cancellation",
  4: "Speech in Noise",
};

const MANAGE_PROGRAM = {
  M1: 1,
  M2: 2,
  M3: 3,
  M4: 4,
};

export {
  TYPE_OF_HEARING_LOSS,
  LEVEL_OF_HEARING_LOSS,
  TYPE_OF_HEARING_LOSS_NAMES,
  LISTENING_SIDE,
  DEVICES_NAME,
  FITTING_BUTTON,
  DEVICES,
  IMPAIREMENT_TYPE,
  DEVICES_CHANNEL,
  DOM_TYPE,
  DOM_SIZE,
  DEVICE_COLORS,
  DEVICES_TUBE,
  DOM_TYPE_NAME,
  DEVICES_TUBE_NAME,
  MANAGE_PROGRAM,
  MANAGE_PROGRAM_LIST,
  MANAGE_PROGRAM_NAME,
  MANAGE_PROGRAM_LIST_Array,
};
